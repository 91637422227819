import * as ToastPrimitives from '@radix-ui/react-toast'
import { useEffect, useRef } from 'react'

import Toast from './Toast'
import styles from './Toast.module.scss'
import { useToasts } from '../../hooks/useToast'

const DEFAULT_DURATION = 3000

const Toaster = () => {
  const { toasts } = useToasts()

  const ref = useRef<HTMLDivElement>(null)

  // Change tabIndex in hidden element in toast component
  // to remove accessible problem - ARIA hidden element must not be focusable or contain focusable elements
  // https://github.com/radix-ui/primitives/issues/1750
  const changeTabIndexHiddenElement = () => {
    const children = ref?.current?.parentElement?.children
    children?.item(0)?.setAttribute('tabIndex', '-1')
    children?.item(children.length - 1)?.setAttribute('tabIndex', '-1')
  }

  useEffect(() => {
    // must be in timeout because items must first be mounted to be edited
    setTimeout(() => {
      changeTabIndexHiddenElement()
    }, 100)
  }, [toasts])

  return (
    <ToastPrimitives.Provider>
      {toasts.map(toast => (
        <Toast
          key={toast.id}
          id={toast.id}
          duration={toast.duration || DEFAULT_DURATION}
          variant={toast.variant}
          type={toast.type || 'foreground'}
          title={toast.title}
          action={toast.action}
          description={toast.description}
          withClose={toast.withClose}
          onCloseButtonClick={toast.onCloseButtonClick}
        />
      ))}
      <ToastPrimitives.Viewport asChild>
        <div className={styles.toastViewport} ref={ref} />
      </ToastPrimitives.Viewport>
    </ToastPrimitives.Provider>
  )
}

export default Toaster
