import { useTranslation } from 'react-i18next'

import Table, { type Column } from '@/components/Table/Table'
import { TABLE_CELL_WIDTH } from '@/components/Table/table-cell-width'
import Tooltip from '@/components/Tooltip/Tooltip'
import GradebookButton from '@/components/common/GradebookButton'
import TeachersColumn from '@/components/common/TeachersColumn/TeachersColumn'
import { useScreenResolution } from '@/hooks/useScreenResolution'
import { getColumnWidth } from '@/utils/get-column-width'

import ClassesColumn from './ClassesColumn'
import CourseExpandendRow from './CourseExpandedRow'
import styles from './CoursesList.module.scss'
import type { Group } from '../types'

type CoursesListProps = {
  list: Group[]
  count: number
  totalCount: number
  loading: boolean
  page: number
  pageSize: number
  semesterId?: string
  withSplittedStudentsList?: boolean
  onPageChange: (page: number) => void
  onPageSizeChange: (pageSize: number) => void
}

const CoursesList = (props: CoursesListProps) => {
  const { t } = useTranslation('courses')
  const { isMobile, isExtraLarge } = useScreenResolution()

  const tableColumns: Column<Group>[] = [
    {
      key: 'course-name',
      dataIndex: ['course', 'name'],
      cellDataTestId: 'course-name',
      title: t('header.course'),
      width: isMobile ? TABLE_CELL_WIDTH.LG : undefined
    },
    {
      key: 'group',
      dataIndex: 'name',
      cellDataTestId: 'group-name',
      title: t('header.group'),
      width: getColumnWidth(isExtraLarge, TABLE_CELL_WIDTH.MD)
    },
    {
      key: 'teacher',
      cellDataTestId: 'teacher-name',
      title: t('header.teachers'),
      render: (value: Group) => (
        <TeachersColumn teacher={value.teacher} coTeacher={value.coTeacher} />
      ),
      width: isMobile ? TABLE_CELL_WIDTH.XL : undefined
    },
    {
      key: 'students-number',
      cellDataTestId: 'students-number',
      title: t('header.students-number'),
      dataIndex: 'studentsCount',
      width: getColumnWidth(isExtraLarge, TABLE_CELL_WIDTH.MD)
    },
    {
      key: 'class',
      cellDataTestId: 'class-name',
      title: t('header.class'),
      render: (value: Group) => (
        <ClassesColumn studentClasses={value.studentClasses} />
      ),
      width: getColumnWidth(isExtraLarge, TABLE_CELL_WIDTH.MD)
    },
    {
      key: 'lessons-in-semester',
      dataIndex: 'lessonCount',
      cellDataTestId: 'lessons-in-semester',
      title: (
        <Tooltip
          trigger={
            <span className={styles.column}>
              {t('header.lessons-scheduled')}
            </span>
          }
          text={t('help.lessons-scheduled-tooltip')}
        />
      ),
      width: getColumnWidth(isExtraLarge, TABLE_CELL_WIDTH.MD)
    },

    {
      key: 'gradebook',
      render: (value: Group) => (
        <GradebookButton
          courseId={value.course.id}
          groupId={value.id}
          semesterId={props.semesterId}
        />
      ),
      fixed: 'right',
      width: TABLE_CELL_WIDTH.ACTIONS,
      filled: true
    }
  ]

  return (
    <Table
      id="courses-table"
      data={props.list || []}
      isLoading={props.loading}
      columns={tableColumns}
      count={props.count}
      totalCount={props.totalCount}
      pagination={{
        pageSize: props.pageSize,
        page: props.page
      }}
      onChangePageSize={props.onPageSizeChange}
      onChangePage={props.onPageChange}
      expandedRowRender={record => (
        <CourseExpandendRow
          groupId={record.id}
          semesterId={props.semesterId}
          withSplittedStudentsList={props.withSplittedStudentsList}
        />
      )}
    />
  )
}

export default CoursesList
