import Select, { type SelectOption } from '@/components/Select/Select'
import Tag, { type TagColor } from '@/components/Tag/Tag'
import { type FormFieldType } from '@/types/form-field-type'
import type { LabelAndValue } from '@/types/label-and-value'
import { type TeacherAvailability } from '@/types/lesson'

import styles from './TeacherSelect.module.scss'

type TeacherSelectProps = {
  options: SelectOption<string>[]
  isOptionsLoading: boolean
  teachersList: TeacherAvailability[]
  placeholder: string
  dataTestId?: string
  onClear: () => void
} & FormFieldType<LabelAndValue>

const TeacherSelect = (props: TeacherSelectProps) => (
  <div className={styles.teacherSelect}>
    <Select
      {...props}
      mode="detailed"
      clearable
      loading={props.isOptionsLoading}
      formatOption={option => (
        <TeacherOption option={option} teachersList={props.teachersList} />
      )}
    />
  </div>
)

type TeacherOptionProps = {
  option: SelectOption<string>
  teachersList: TeacherAvailability[]
}

const TeacherOption = (props: TeacherOptionProps) => {
  const teacherOption = props.teachersList?.find(
    teacher => teacher.id === props.option.value
  )

  return (
    <div className={styles.teacherOption}>
      <span>{props.option.label}</span>

      {teacherOption ? (
        <Tag
          label={teacherOption.status}
          color={getColorByStatus(teacherOption.status)}
        />
      ) : null}
    </div>
  )
}

const getColorByStatus = (value?: string): TagColor => {
  switch (value) {
    case 'available':
      return 'green'
    case 'busy':
    case 'unavailable':
      return 'red'
    default:
      return 'gray'
  }
}

export default TeacherSelect
