import { isPast } from 'date-fns'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { toast } from '@/hooks/useToast'
import useCancelLesson from '@/mutations/useCancelLesson'
import type { Lesson } from '@/types/lesson'
import { formatTime } from '@/utils/format-date'
import {
  getLessonFormattedTime,
  getLessonShortedDetails
} from '@/utils/lesson-details-format'

import styles from './CancelLessonModal.module.scss'
import Alert from '../Alert/Alert'
import FormField from '../FormField'
import ModalForm from '../Modal/ModalForm'
import Textarea from '../Textarea/Textarea'

type CancelLessonModalProps = {
  lesson: Lesson
  onSuccess: () => void
  open: boolean
  onOpenChange: (isOpen: boolean) => void
}

const CancelLessonModal = (props: CancelLessonModalProps) => {
  const { t } = useTranslation('common')

  const form = useForm({
    defaultValues: {
      comment: props.lesson.comment
    }
  })

  const { mutate, isPending } = useCancelLesson({
    onSuccess: () => {
      toast({
        variant: 'success',
        title: t('toast.successfully-cancelled-lesson', {
          DATE: getLessonFormattedTime(props.lesson)
        })
      })
      props.onOpenChange(false)
      props.onSuccess()
    }
  })

  useEffect(() => {
    if (props.open) {
      form.setValue('comment', props.lesson.comment)
    }
  }, [form, props.lesson, props.open])

  return (
    <ModalForm
      id="cancel-lesson-modal"
      open={props.open}
      onOpenChange={props.onOpenChange}
      title={t('header.cancel-lesson')}
      description={getLessonShortedDetails(props.lesson)}
      form={form}
      submitText={t('button.confirm')}
      onSubmit={({ comment }) => mutate({ id: props.lesson.id, comment })}
      submitButtonVariant="danger"
      loading={isPending}
      onCancel={() => props.onOpenChange(false)}
      size="sm"
    >
      <div className={styles.description}>
        <p>
          {t('help.do-you-want-to-cancel-lesson', {
            COURSE: props.lesson.course.name,
            TIME: formatTime(props.lesson.startDate)
          })}
        </p>
        <p>{t('help.cancel-lesson-applies-only-to-selected-lesson')}</p>
      </div>
      <FormField
        id="cancel-lesson-comment"
        name="comment"
        label={t('label.comment')}
        control={form.control}
        render={({ inputProps }) => <Textarea {...inputProps} />}
      />
      <CancelLessonAlert lesson={props.lesson} />
    </ModalForm>
  )
}

const CancelLessonAlert = (props: { lesson: Lesson }) => {
  const { t } = useTranslation('common')

  const isPastLesson = props.lesson.endDate
    ? isPast(props.lesson.endDate)
    : false

  if (!isPastLesson && !props.lesson.isCovered) return null

  return (
    <Alert
      variant="warning"
      message={
        <div className={styles.alertContent}>
          {isPastLesson ? (
            <span>{t('help.lesson-has-been-completed')}</span>
          ) : null}
          {props.lesson.isCovered ? (
            <span>{t('help.lesson-has-been-covered')}</span>
          ) : null}
        </div>
      }
    />
  )
}

export default CancelLessonModal
