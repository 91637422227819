import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Alert from '@/components/Alert/Alert'
import Button from '@/components/Button/Button'
import { BaseModal } from '@/components/Modal/Modal'
import Pagination from '@/components/Pagination/Pagination'
import Table from '@/components/Table/Table'
import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'

import styles from './BulkArchiveConfirmModal.module.scss'
import useBulkUnableArchiveUsers from '../queries/useBulkUnableArchiveUsers'

type BulkArchiveConfirmModalProps = {
  open: boolean
  onOpenChange: (open: boolean) => void
  count: number
  isArchiveLoading: boolean
  unableToArchiveUsers: string[]
  onArchive: () => void
}

const BulkArchiveConfirmModal = (props: BulkArchiveConfirmModalProps) => {
  const { t } = useTranslation('users')

  const [page, setPage] = useState(1)

  const isAnyUnableToArchive = props.unableToArchiveUsers.length > 0
  const isMoreThanFiveUsers = props.unableToArchiveUsers.length > 5

  const { data, isLoading: isUsersLoading } = useBulkUnableArchiveUsers({
    enabled: isAnyUnableToArchive,
    page,
    ids: props.unableToArchiveUsers
  })

  const modalSize = isAnyUnableToArchive ? 'sm' : 'xs'

  const topBarCountText = `${page * DEFAULT_PAGE_SIZE - DEFAULT_PAGE_SIZE + 1}-${Math.min(
    page * DEFAULT_PAGE_SIZE,
    props.unableToArchiveUsers.length
  )} of ${props.unableToArchiveUsers.length}`

  const handleOpenChange = (open: boolean) => {
    if (!open) setPage(1)

    props.onOpenChange(open)
  }

  return (
    <BaseModal
      size={modalSize}
      id="bulk-archive-modal"
      open={props.open}
      onOpenChange={handleOpenChange}
      title={t('header.archive-users', {
        count: props.count
      })}
      hideCloseIcon
      contentClassName={styles.modal}
    >
      <div className={styles.content}>
        <p className={styles.description}>
          {t('help.want-to-archive-users', {
            count: props.count
          })}
        </p>
        {isAnyUnableToArchive ? (
          <>
            <Alert
              message={t('help.unable-to-archive-users', {
                count: props.unableToArchiveUsers.length
              })}
              variant="warning"
              description={t('help.following-users-cannot-be-archived')}
            />

            {isMoreThanFiveUsers ? (
              <div className={styles.topBarContainer}>
                <p className={styles.topBar}>{topBarCountText}</p>
              </div>
            ) : null}
            <div className={styles.table}>
              <Table
                id="unable-to-archive-users-table"
                hideNumberColumn
                showHeader={false}
                showTopBar={false}
                isLoading={isUsersLoading}
                columns={[
                  {
                    key: 'name',
                    dataIndex: 'name'
                  }
                ]}
                data={data || []}
              />
            </div>

            {isMoreThanFiveUsers ? (
              <div className={styles.pagination}>
                <Pagination
                  count={props.unableToArchiveUsers.length}
                  id="add-students-table-pagination"
                  page={page}
                  pageSize={DEFAULT_PAGE_SIZE}
                  onPageChange={setPage}
                />
              </div>
            ) : null}
          </>
        ) : null}

        <footer className={styles.footer}>
          <Button variant="tertiary" onClick={() => props.onOpenChange(false)}>
            {t('button.cancel')}
          </Button>
          <Button
            variant="danger"
            onClick={props.onArchive}
            loading={props.isArchiveLoading}
          >
            {t('button.archive')}
          </Button>
        </footer>
      </div>
    </BaseModal>
  )
}

export default BulkArchiveConfirmModal
