import { useInfiniteQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '../api/http.service'
import type { PaginatedResults } from '../types/paginated-results'
import { nextPageParamGetter } from '../utils/next-page-param-getter'
import { parsePaginated } from '../utils/parse-paginated'

const responseSchema = z.object({
  id: z.string(),
  full_name: z.string(),
  email: z.string()
})

type Params = {
  search: string
}

type StudentEmailAndName = {
  id: string
  fullName: string
  email: string
}

const parseResponse = (
  response: unknown
): PaginatedResults<StudentEmailAndName> =>
  parsePaginated({
    data: response,
    schema: responseSchema,
    parse: ({ id, email, full_name }) => ({ id, email, fullName: full_name })
  })

export const useStudentsEmailAndName = (params: Params) =>
  useInfiniteQuery({
    queryKey: ['studentsEmailAndName', params],
    staleTime: 60 * 1000,
    queryFn: ({ pageParam }) =>
      httpService.panel
        .panelStudentsList({
          search: params.search,
          page: pageParam,
          fetchKeys: {
            id: true,
            full_name: true,
            email: true
          },
          extra_search_fields: ['email']
        })
        .then(parseResponse),
    getNextPageParam: nextPageParamGetter,
    initialPageParam: 1
  })
