import { createFileRoute, redirect } from '@tanstack/react-router'

import { timetableFiltersSchema, TimetableView } from '@/modules/timetable'
import { semesterQueryOptions } from '@/queries/useSemesters'
import { getDefaultDateRangeInTimetable } from '@/utils/get-default-date-range-in-timetable'

export const Route = createFileRoute('/_auth/timetable/')({
  component: () => <TimetableView />,
  validateSearch: search => timetableFiltersSchema.parse(search),
  loaderDeps: ({ search }) => ({ search }),
  loader: async ({ context: { authStore, queryClient }, deps: { search } }) => {
    if (!search.dateFrom && !search.dateTo) {
      const semesters = await queryClient.ensureQueryData(
        semesterQueryOptions({})
      )

      if (!semesters.currentSemester) return

      const { start, end } = getDefaultDateRangeInTimetable(
        semesters.currentSemester
      )

      const isFilterSelected =
        search.classId ||
        search.course ||
        search.room ||
        search.student ||
        search.teacher

      const teacher =
        !isFilterSelected && authStore?.user?.isTeacher
          ? {
              value: authStore?.user?.id,
              label: authStore?.user?.fullName
            }
          : undefined

      throw redirect({
        replace: true,
        to: '/timetable',
        search: { dateFrom: start, dateTo: end, teacher }
      })
    }
  }
})
