import { addDays, isWithinInterval } from 'date-fns'

import type { Semester } from '@/types/semester'

export const getSemesterFromWeek = (
  startWeekDate: string | Date,
  semesterList?: Semester[]
) =>
  Array(7)
    .fill(0)
    .map((_, index) => addDays(startWeekDate, index))
    .map(item =>
      semesterList?.find(semesterItem =>
        isWithinInterval(item, {
          start: semesterItem.startDate,
          end: semesterItem.endDate
        })
      )
    )
    .find(semester => !!semester)
