import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import type { LessonDetails } from '@/types/lesson'
import { requiredString } from '@/utils/zod'

const responseSchema = z.object({
  id: requiredString(),
  start_date: z.coerce.date(),
  end_date: z.coerce.date(),
  topic: z.string().optional(),
  notes: z.string().optional(),
  group_order: z.number(),
  group: z.object({
    id: requiredString(),
    name: requiredString()
  }),
  course: z.object({
    id: requiredString(),
    name: requiredString()
  }),
  room: z
    .object({
      id: requiredString(),
      name: requiredString()
    })
    .nullable(),
  teacher: z.object({
    id: requiredString(),
    name: requiredString()
  }),
  teacher_cover: z
    .object({
      id: requiredString(),
      name: requiredString()
    })
    .nullable(),
  co_teacher_1_cover: z
    .object({
      id: requiredString(),
      name: requiredString()
    })
    .nullable(),
  co_teacher_1: z
    .object({
      id: requiredString(),
      name: requiredString()
    })
    .nullable(),
  is_covered: z.boolean(),
  is_cancelled: z.boolean(),
  comment: z.string(),
  co_room: z
    .object({
      id: z.string(),
      name: z.string()
    })
    .nullable(),
  student_classes: z.array(
    z.object({
      id: requiredString(),
      name: requiredString(),
      display_name: requiredString()
    })
  )
})

const parseResponse = (
  response: z.infer<typeof responseSchema>
): LessonDetails => ({
  id: response.id,
  startDate: response.start_date,
  endDate: response.end_date,
  room: response.room,
  topic: response.topic,
  notes: response.notes,
  lessonNumber: response.group_order,
  group: {
    id: response.group.id,
    name: response.group.name
  },
  course: {
    id: response.course.id,
    name: response.course.name
  },
  teacher: {
    id: response.teacher.id,
    name: response.teacher.name
  },
  teacherCover: response.teacher_cover
    ? {
        id: response.teacher_cover.id,
        name: response.teacher_cover.name
      }
    : undefined,
  coTeacher: response.co_teacher_1
    ? {
        id: response.co_teacher_1.id,
        name: response.co_teacher_1.name
      }
    : undefined,
  coTeacherCover: response.co_teacher_1_cover
    ? {
        id: response.co_teacher_1_cover.id,
        name: response.co_teacher_1_cover.name
      }
    : undefined,
  isCovered: response.is_covered,
  isCancelled: response.is_cancelled,
  comment: response.comment,
  coRoom: response.co_room,
  studentClasses: response.student_classes
})

const useLesson = (lessonId: string) =>
  useQuery({
    queryKey: ['panelLessonsRetrieve', lessonId],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelLessonsRetrieve(lessonId, {
        fetchKeys: {
          id: true,
          start_date: true,
          end_date: true,
          room: true,
          teacher: true,
          teacher_cover: true,
          co_teacher_1_cover: true,
          co_teacher_1: true,
          topic: true,
          notes: true,
          group: { id: true, name: true },
          course: { id: true, name: true },
          is_covered: true,
          is_cancelled: true,
          group_order: true,
          comment: true,
          student_classes: true,
          co_room: true
        }
      })
      const parsed = parseResponse(responseSchema.parse(response))

      return parsed
    },
    refetchOnWindowFocus: false,
    enabled: !!lessonId
  })

export default useLesson
