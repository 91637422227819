import { useQuery } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'

const useStudentsAllCount = () =>
  useQuery({
    queryKey: ['useStudentsAllCount'],
    staleTime: 60 * 100,
    queryFn: async () => {
      const students = await httpService.panel.panelStudentsList({
        page_size: 1,
        page: 1,
        fetchKeys: {
          id: true
        }
      })
      return students.count
    }
  })

export default useStudentsAllCount
