import { useTranslation } from 'react-i18next'

import { httpService } from '@/api/http.service'
import { clientErrorsMapper } from '@/constants/invalid-status-error'
import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'
import { toast } from '@/hooks/useToast'

const useArchiveStudentMutation = ({
  onSuccess,
  onError
}: {
  onSuccess: () => void
  onError: () => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: { id: string }) =>
      httpService.panel.panelStudentsArchiveCreate(data.id),
    onSuccess,
    onError,
    clientErrorsMapper: clientErrorsMapper()
  })

const useArchiveStudent = ({
  onSuccess,
  studentName
}: {
  onSuccess: () => void
  studentName: string
}) => {
  const { t } = useTranslation('students')

  return useArchiveStudentMutation({
    onSuccess: () => {
      toast({
        variant: 'success',
        title: t('toast.student-archived', {
          NAME: studentName
        })
      })

      onSuccess()
    },
    onError: () => {
      toast({
        variant: 'error',
        title: t('toast.student-archived-failed', {
          NAME: studentName
        })
      })
    }
  })
}

export default useArchiveStudent
