import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'
import { nextPageParamGetter } from '@/utils/next-page-param-getter'
import { parsePaginatedOptions } from '@/utils/parse-paginated'
import { arrayOfOptions } from '@/utils/zod'

type Params = {
  search: string
}

type StudentFiltersParams = Record<'class' | 'parent' | 'tutor', Params>

export const useStudentFilters = (params?: StudentFiltersParams) => {
  const classesQuery = useInfiniteQuery({
    queryKey: ['panelStudentsOptionsClassesList', params?.class],
    staleTime: 60 * 1000,
    queryFn: ({ pageParam }) =>
      httpService.panel
        .panelStudentsOptionsClassesList({
          ...params?.class,
          page: pageParam
        })
        .then(parsePaginatedOptions),
    getNextPageParam: nextPageParamGetter,
    initialPageParam: 1
  })

  const parentQuery = useInfiniteQuery({
    queryKey: ['panelStudentsOptionsParentsList', params?.parent],
    staleTime: 60 * 1000,
    queryFn: ({ pageParam }) =>
      httpService.panel
        .panelStudentsOptionsParentsList({
          ...params?.parent,
          page: pageParam
        })
        .then(parsePaginatedOptions),
    getNextPageParam: nextPageParamGetter,
    initialPageParam: 1
  })
  const tutorsQuery = useInfiniteQuery({
    queryKey: ['panelStudentsOptionsTutorsList', params?.tutor],
    staleTime: 60 * 1000,
    queryFn: ({ pageParam }) =>
      httpService.panel
        .panelStudentsOptionsTutorsList({
          ...params?.tutor,
          page: pageParam
        })
        .then(parsePaginatedOptions),
    getNextPageParam: nextPageParamGetter,
    initialPageParam: 1
  })

  const academicLevelsQuery = useQuery({
    queryKey: ['panelStudentsOptionsAcademicLevelsList'],
    staleTime: 60 * 1000,
    queryFn: async () => {
      const filtersResponse =
        await httpService.panel.panelStudentsOptionsAcademicLevelsList()
      return arrayOfOptions.parse(filtersResponse.results)
    }
  })

  const statusQuery = useQuery({
    queryKey: ['panelStudentsOptionsStatusesList'],
    staleTime: 60 * 1000,
    queryFn: async () => {
      const filtersResponse =
        await httpService.panel.panelStudentsOptionsStatusesList()
      return arrayOfOptions.parse(filtersResponse.results)
    }
  })

  return {
    classesQuery,
    parentQuery,
    tutorsQuery,
    academicLevelsQuery,
    statusQuery
  }
}
