import { addMonths, addYears, isBefore, setYear, startOfMonth } from 'date-fns'
import { useState } from 'react'
import {
  DayPicker,
  useDayPicker,
  Week,
  type DayPickerProps,
  type MonthCaptionProps,
  type WeekProps,
  type DateRange as RdpDateRange
} from 'react-day-picker'
import { useTranslation } from 'react-i18next'

import ChevronDoubleLeftIcon from '@/assets/icons/chevron-double-left.svg?react'
import ChevronDoubleRightIcon from '@/assets/icons/chevron-double-right.svg?react'
import ChevronSmallLeftIcon from '@/assets/icons/chevron-small-left.svg?react'
import ChevronSmallRightIcon from '@/assets/icons/chevron-small-right.svg?react'
import { formatFullMonthName, getDateFnsLocale } from '@/utils/format-date'

import styles from './Calendar.module.scss'
import CalendarWeek from './CalendarWeek'
import Button from '../Button/Button'
import ButtonIcon from '../ButtonIcon/ButtonIcon'
import Label from '../Label'
import Select from '../Select/Select'

export type DateRange = RdpDateRange

type SingleCalendarProps = {
  mode: 'single'
  value?: Date
  onChange?: (value?: Date) => void
}

type RangeCalendarProps = {
  mode: 'range'
  value?: DateRange
  onChange?: (value?: DateRange) => void
}

type WeekCalendarProps = {
  mode: 'week'
  value?: Date
  onChange?: (value?: Date) => void
}

export type CalendarProps = {
  id: string
  disabledDates?: (date: Date) => boolean
  markedDates?: (date: Date) => boolean
  autoFocus?: boolean
  isFirstSelection?: boolean
  legend?: React.ReactNode
  defaultMonth?: Date
} & (SingleCalendarProps | RangeCalendarProps | WeekCalendarProps)

const Calendar = (props: CalendarProps) => {
  const { t } = useTranslation(['common'])
  const today = new Date()

  const defaultMonth = props.defaultMonth ?? today

  const [month, setMonth] = useState<Date>(
    props.mode === 'single' || props.mode === 'week'
      ? props.value || defaultMonth
      : props.value?.from || defaultMonth
  )
  const dayPickerProps: DayPickerProps = {
    autoFocus: props.autoFocus,
    disabled: props.disabledDates,
    ...(props.mode === 'single' || props.mode === 'week'
      ? {
          numberOfMonths: 1,
          mode: 'single',
          selected: props.value,
          onSelect: props.onChange,
          onDayClick: date => props.onChange?.(date)
        }
      : {
          numberOfMonths: 2,
          mode: 'range',
          selected: props.value,
          onSelect: newDate => {
            const isFromDiferent = newDate?.from !== props.value?.from

            if (props.isFirstSelection) {
              props.onChange?.({
                from: isFromDiferent ? newDate?.from : newDate?.to,
                to: undefined
              })
              return
            }

            const isNewFromDateBeforePreviousFromDate =
              newDate?.from &&
              isFromDiferent &&
              props.value?.from &&
              newDate?.to === props.value?.from &&
              isBefore(newDate.from, props.value?.from)

            if (isNewFromDateBeforePreviousFromDate) {
              props.onChange?.({ from: newDate.from, to: undefined })
            } else {
              props.onChange?.(newDate)
            }
          }
        })
  }

  const RowComponent = (rowProps: WeekProps) =>
    props.mode === 'week' ? (
      <CalendarWeek {...rowProps} />
    ) : (
      <Week {...rowProps} />
    )

  return (
    <div className={styles.wrapper}>
      <DayPicker
        id={props.id}
        {...dayPickerProps}
        showOutsideDays
        ISOWeek
        showWeekNumber={props.mode === 'week'}
        locale={getDateFnsLocale()}
        month={month}
        onMonthChange={setMonth}
        weekStartsOn={1}
        modifiers={{ marked: props.markedDates ?? [] }}
        modifiersClassNames={{ marked: styles.dayMarked }}
        classNames={{
          months: styles.months,
          month: styles.month,
          nav: styles.nav,
          month_grid: styles.table,
          weekdays: styles.headRow,
          weekday: styles.headCell,
          week: styles.row,
          day: styles.cell,
          day_button: styles.day,
          selected: props.mode === 'week' ? styles.day : styles.daySelected,
          disabled: styles.dayDisabled,
          outside: styles.dayOutside,
          today: styles.dayToday,
          range_start: styles.dayRangeStart,
          range_end: styles.dayRangeEnd,
          range_middle: styles.dayRangeMiddle,
          week_number: styles.weekNumber
        }}
        components={{
          Chevron: chevronProps => {
            if (chevronProps.orientation === 'left') {
              return <ChevronSmallLeftIcon />
            }
            return <ChevronSmallRightIcon />
          },
          Week: rowProps => <RowComponent {...rowProps} />,
          MonthCaption: captionProps => <Caption {...captionProps} />,
          Nav: () => <></>
        }}
      />
      {props.legend || props.mode === 'single' ? (
        <div className={styles.footerContainer}>
          {props.legend}
          {props.mode === 'single' ? (
            <Button variant="tertiary" onClick={() => props.onChange?.(today)}>
              {t('button.today')}
            </Button>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}

const NUMBER_OF_YEARS = 200

const year = new Date().getFullYear()

const yearsOptions = Array<number>(NUMBER_OF_YEARS)
  .fill(year)
  .map((_, index) => {
    const yearValue = `${year - NUMBER_OF_YEARS / 2 + index}`
    return {
      value: yearValue,
      label: yearValue
    }
  })

const Caption = (props: MonthCaptionProps) => {
  const { t } = useTranslation(['common'])
  const { goToMonth, months } = useDayPicker()

  const handleOnYearChange = (value?: string) => {
    if (!value) return

    const newDate = setYear(
      startOfMonth(props.calendarMonth.date),
      Number(value)
    )
    goToMonth(addMonths(newDate, props.displayIndex ? -props.displayIndex : 0))
  }

  const handleOnPrevYearChange = () => {
    goToMonth(addYears(startOfMonth(months[0].date), -1))
  }

  const handleOnNextYearChange = () => {
    goToMonth(addYears(startOfMonth(months[0].date), 1))
  }

  const handleOnPrevMonthChange = () => {
    goToMonth(addMonths(startOfMonth(months[0].date), -1))
  }

  const handleOnNextMonthChange = () => {
    goToMonth(addMonths(startOfMonth(months[0].date), 1))
  }

  const isMulti = months.length > 1
  return (
    <div className={styles.navCaption}>
      <div className={styles.navButtons}>
        {!isMulti || props.displayIndex === 0 ? (
          <>
            <ButtonIcon
              size="extra-small"
              variant="tertiary"
              onClick={handleOnPrevYearChange}
              ariaLabel={t('button.previous-year')}
            >
              <ChevronDoubleLeftIcon />
            </ButtonIcon>
            <ButtonIcon
              size="extra-small"
              variant="tertiary"
              onClick={handleOnPrevMonthChange}
              ariaLabel={t('button.previous-month')}
            >
              <ChevronSmallLeftIcon />
            </ButtonIcon>
          </>
        ) : null}
      </div>
      <div className={styles.monthHeader}>
        <span>{formatFullMonthName(props.calendarMonth.date)}</span>
        <Label
          id={`${props.id}-yearselect`}
          label={t('label.year-select')}
          hidden
        >
          <Select
            id={`${props.id}-yearselect`}
            className={styles.yearSelect}
            hideSearch
            borderless
            options={yearsOptions}
            value={`${props.calendarMonth.date.getFullYear()}`}
            onChange={handleOnYearChange}
          />
        </Label>
      </div>
      <div className={styles.navButtons}>
        {!isMulti || props.displayIndex === 1 ? (
          <>
            <ButtonIcon
              size="extra-small"
              variant="tertiary"
              onClick={handleOnNextMonthChange}
              ariaLabel={t('button.next-month')}
            >
              <ChevronSmallRightIcon />
            </ButtonIcon>
            <ButtonIcon
              size="extra-small"
              variant="tertiary"
              onClick={handleOnNextYearChange}
              ariaLabel={t('button.next-year')}
            >
              <ChevronDoubleRightIcon />
            </ButtonIcon>
          </>
        ) : null}
      </div>
    </div>
  )
}

export default Calendar
