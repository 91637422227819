import { getRouteApi } from '@tanstack/react-router'
import { capitalize } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import PlusIcon from '@/assets/icons/add.svg?react'
import EditIcon from '@/assets/icons/edit.svg?react'
import Alert from '@/components/Alert/Alert'
import Button from '@/components/Button/Button'
import DataBlock from '@/components/DataBlock/DataBlock'
import Label from '@/components/Label'
import LinkText from '@/components/LinkText/LinkText'
import AvailabilityBadge from '@/components/common/AvailabilityBadge/AvailabilityBadge'
import SemesterSelect from '@/components/common/SemesterSelect/SemesterSelect'
import { daysOfWeek } from '@/constants/days-of-week'
import { USER_PROFILE } from '@/constants/user-profile'
import { AvailabilityModal } from '@/modules/availabilityManagement'
import useAuthStore from '@/store/useAuthStore'
import { formatDate, getWeekDays } from '@/utils/format-date'

import styles from './UserDetailsView.module.scss'
import useUser from '../queries/useUser'
import {
  type AvailabilitySlot,
  useUserAvailability
} from '../queries/useUserAvailability'
import { useUserAvailabilitySemesters } from '../queries/useUserAvailabilitySemesters'

const routeApi = getRouteApi('/_auth/users/$userId/details')

const UserDetailsView = () => {
  const navigate = routeApi.useNavigate()
  const { userId } = routeApi.useParams()
  const { semesterId, setAvailability } = routeApi.useSearch()
  const { data: user } = useUser(userId)
  const { user: userStore } = useAuthStore()
  const { t } = useTranslation('users')

  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    if (setAvailability) setIsModalOpen(true)
  }, [setAvailability])

  const { data: semesters, isLoading: isSemesterLoading } =
    useUserAvailabilitySemesters({ userId })

  const { data: userAvailability } = useUserAvailability({
    userId,
    semester: semesterId
  })

  const getAvailability = (value?: AvailabilitySlot[]) => {
    if (!value || !value.length) {
      return (
        <span className={styles.availabilitySlot}>
          <AvailabilityBadge variant="unavailable" />
          <span className={styles.availabilityText}>
            {t('text.unavailable')}
          </span>
        </span>
      )
    }

    return (
      <div className={styles.availabilityContainer}>
        {value?.map(item => (
          <span key={item.id} className={styles.availabilitySlot}>
            <AvailabilityBadge
              variant={item.isOptional ? 'optional' : 'available'}
            />
            <span className={styles.availabilityText}>
              {item.startTime} - {item.endTime}
            </span>
          </span>
        ))}
      </div>
    )
  }

  const basicInformationInfo = [
    { label: t('label.gender'), value: capitalize(user?.gender) },
    { label: t('label.birth'), value: formatDate(user?.dateOfBirth) },
    {
      label: t('label.nationality'),
      value: user?.nationalities.map(item => item.label)
    },
    { label: t('label.first-language'), value: user?.firstLanguage?.label },
    { label: t('label.community'), value: user?.community?.label },
    {
      label: t('label.ahv-number'),
      value: user?.ahvNumber
    }
  ]

  const academicInfo = [
    {
      label: t('label.class-tutor'),
      value: user?.tutor?.classes.length ? (
        <>
          {user?.tutor?.classes.map(classItem => (
            <LinkText
              size="medium"
              key={classItem.id}
              to="/students-and-classes/classes/$classId/details"
              className={styles.classItem}
              params={{
                classId: classItem.id
              }}
            >
              {classItem.name}
            </LinkText>
          ))}
        </>
      ) : (
        '—'
      ),
      hidden: !user?.profiles.includes(USER_PROFILE.TUTOR)
    },
    {
      label: t('label.preferred-room'),
      value: user?.teacher?.preferredRooms?.map(room => room.name) || [],
      hidden:
        !user?.profiles.includes(USER_PROFILE.TUTOR) &&
        !user?.profiles.includes(USER_PROFILE.TEACHER)
    }
  ].filter(item => !item.hidden)

  const contactDetailsInfo = [
    { label: t('label.phone-number'), value: user?.phoneNumber },
    { label: t('label.email'), value: user?.email },
    { label: t('label.emergency-contact'), value: user?.emergencyPhoneNumber }
  ]

  const weekdays = getWeekDays()

  const scheduleInfo = daysOfWeek.map((day, index) => ({
    label: weekdays[index],
    value: getAvailability(userAvailability?.[day])
  }))

  const handleChangeSemester = (semester: string) => {
    navigate({
      search: {
        semesterId: semester
      }
    })
  }

  const isArchived = user?.status === 'archived'

  const canEditUser = userStore?.isSuperAdmin && !isArchived

  const canEditAvailability =
    (userStore?.id === userId || userStore?.isSuperAdmin) && !isArchived

  const semester = semesters?.getSemester(semesterId)

  return (
    <>
      {isArchived ? (
        <Alert
          message={t('help.user-archived-at', {
            DATE: formatDate(user?.archivedAt)
          })}
          className={styles.archivedAtAlert}
        />
      ) : null}
      <div className={styles.container}>
        <div className={styles.header}>
          <p className={styles.details}>{t('header.details')}</p>
          {canEditUser ? (
            <Button
              asLink
              to="/users/edit/$userId"
              params={{ userId }}
              variant="secondary"
              icon={<EditIcon />}
            >
              {t('button.edit')}
            </Button>
          ) : null}
        </div>
        <div className={styles.wrapper}>
          <DataBlock
            header={t('header.basic-information')}
            infoArray={basicInformationInfo}
          />

          <DataBlock header={t('header.academics')} infoArray={academicInfo} />

          <DataBlock
            header={t('header.contact-details')}
            infoArray={contactDetailsInfo}
          />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.header}>
          <p className={styles.details}>{t('header.availability')}</p>
          <div className={styles.availabilityFilters}>
            <Label id="semester-select" label={t('label.semester')} hidden>
              <SemesterSelect
                id="semester-select"
                hideSearch
                className={styles.semesterSelect}
                options={semesters?.options || []}
                value={semesterId}
                onChange={handleChangeSemester}
                loading={isSemesterLoading}
                semesterList={semesters?.list || []}
              />
            </Label>
            {canEditAvailability ? (
              <Button
                icon={<PlusIcon />}
                variant="secondary"
                onClick={() => setIsModalOpen(true)}
              >
                {t('button.set-availability')}
              </Button>
            ) : null}
          </div>
        </div>

        <DataBlock infoArray={scheduleInfo} />
      </div>
      {semester ? (
        <AvailabilityModal
          onOpenChange={value => {
            setIsModalOpen(value)

            if (!value && setAvailability) {
              navigate({
                search: previousValue => ({
                  ...previousValue,
                  setAvailability: false
                })
              })
            }
          }}
          open={isModalOpen}
          semester={semester}
          userId={userId}
          userName={user ? user.name : ''}
        />
      ) : null}
    </>
  )
}

export default UserDetailsView
