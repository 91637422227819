import { useQuery } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'
import { arrayOfOptions } from '@/utils/zod'

const useCommentTypeOptions = () =>
  useQuery({
    queryKey: ['panelCommentsOptionsCommentTypeList'],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response =
        await httpService.panel.panelCommentsOptionsCommentTypeList({})

      return arrayOfOptions.parse(response.results)
    }
  })

export default useCommentTypeOptions
