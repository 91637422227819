import { useQuery } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'

type Params = {
  semesterId: string
}

const useAbsencesCount = (params: Params) =>
  useQuery({
    queryKey: ['panelAbsencesListCount', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const absencesList = await httpService.panel.panelAbsencesList({
        semester_id: params.semesterId,
        page_size: 1,
        fetchKeys: {
          id: true
        }
      })

      return absencesList.count
    }
  })

export default useAbsencesCount
