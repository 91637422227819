import i18n from '@/i18n'
import type { UserStatus } from '@/types/user-status'

export const getUserStatusTooltip = (value: UserStatus): string => {
  switch (value) {
    case 'active':
      return i18n.t('help.user-active', { ns: 'users' })
    case 'inactive':
      return i18n.t('help.user-inactive', { ns: 'users' })
    case 'blocked':
      return i18n.t('help.user-blocked', { ns: 'users' })
    case 'archived':
      return i18n.t('help.user-archived', { ns: 'users' })
  }
}
