import { getRouteApi } from '@tanstack/react-router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@/assets/icons/add.svg?react'
import DeleteIcon from '@/assets/icons/delete.svg?react'
import EditIcon from '@/assets/icons/edit.svg?react'
import Button from '@/components/Button/Button'
import LinkText from '@/components/LinkText/LinkText'
import Table, { type TableAction, type Column } from '@/components/Table/Table'
import { TABLE_CELL_WIDTH } from '@/components/Table/table-cell-width'
import CommentListExpandedRow from '@/components/common/CommentListExpandedRow/CommentListExpandedRow'
import CommentModal from '@/components/common/CommentModal/CommentModal'
import Filters, { type Filter } from '@/components/common/Filters/Filters'
import { RemoveCommentModal } from '@/components/common/RemoveCommentModal'
import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'
import useCommonCommentFilters from '@/hooks/useCommonCommentFilters'
import useCommonCommentsColumn from '@/hooks/useCommonCommentsColumn'
import { useScreenResolution } from '@/hooks/useScreenResolution'
import useComments, { type Comment } from '@/queries/useComments'
import useCommentsCount from '@/queries/useCommentsCount'
import useSemesters from '@/queries/useSemesters'
import useStudentCoursesOptions from '@/queries/useStudentCoursesOptions'
import useStudentsOptions from '@/queries/useStudentOptions'
import useAuthStore from '@/store/useAuthStore'
import { checkIfFilterSelected } from '@/utils/check-if-filters-selected'
import { getFilterValue } from '@/utils/get-filter-value'

import { type CommentFiltersKeys } from '../constants/comment-filters'

const routeApi = getRouteApi(
  '/_auth/students-and-classes/classes/$classId/comments'
)

const CommentsView = () => {
  const { t } = useTranslation(['classes', 'common'])
  const { classId } = routeApi.useParams()
  const search = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const { data: semesters, isLoading: isSemesterLoading } = useSemesters({
    classId
  })
  const user = useAuthStore(store => store.user)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isOpenRemoveCommentModal, setIsOpenRemoveCommentModal] =
    useState(false)

  const { isMobile } = useScreenResolution()

  const {
    data: comments,
    isLoading: isCommentsLoading,
    refetch: refetchComments
  } = useComments({
    classId,
    ...search
  })

  const {
    data: commentsCount,
    isLoading: isCommentsCountLoading,
    refetch: refetchCommentsCount
  } = useCommentsCount({
    semester: search.semester,
    classId
  })

  const refetchAllComments = () => {
    refetchComments()
    refetchCommentsCount()
    refetchAddedByOptions()
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setCurrentComment(null)
  }

  const { data: courses, isLoading: isCoursesLoading } =
    useStudentCoursesOptions({
      classId,
      semester: search.semester
    })

  const { data: students, isLoading: isStudentsLoading } = useStudentsOptions({
    classId: [classId],
    semester: search.semester
  })

  const handleChangePage = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: value
      })
    })
  }

  const handleChangePageSize = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        pageSize: value
      })
    })
  }

  const handleSemesterChange = (value: string) => {
    navigate({
      search: {
        semester: value,
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE
      }
    })
  }

  const { commonCommentsColumns } = useCommonCommentsColumn()

  const tableColumns: Column<Comment>[] = [
    {
      key: 'student',
      title: t('header.student'),
      width: isMobile ? TABLE_CELL_WIDTH.XL : undefined,
      render: (value: Comment) => (
        <LinkText
          to="/students-and-classes/students/$studentId/details"
          params={{
            studentId: value.student.id
          }}
        >
          {value.student.name}
        </LinkText>
      )
    },
    {
      key: 'course',
      title: t('header.source'),
      width: isMobile ? TABLE_CELL_WIDTH.LG : undefined,
      render: (value: Comment) => value.group?.course.name ?? t('text.other')
    },
    ...commonCommentsColumns
  ]

  const handleChangeFilter = (
    key: CommentFiltersKeys,
    value?: string | string[] | Date
  ) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: 1,
        [key]: getFilterValue(value)
      })
    })
  }

  const handleClearAll = () => {
    navigate({
      search: {
        semester: semesters?.currentSemester.id || '',
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE
      }
    })
  }

  const { commonCommentsFilters, refetchAddedByOptions } =
    useCommonCommentFilters({
      ...search,
      semester: semesters?.getSemester(search.semester),
      classId,
      onChangeFilter: handleChangeFilter
    })

  const filters: Filter[] = [
    {
      label: t('label.semester'),
      variant: 'semester',
      size: 'wide',
      filterProps: {
        id: 'semester',
        loading: isSemesterLoading,
        options: semesters?.options || [],
        semesterList: semesters?.list || [],
        value: search.semester,
        onChange: handleSemesterChange
      }
    },
    {
      label: t('label.student'),
      variant: 'multiselect',
      size: 'wide',
      filterProps: {
        id: 'student',
        multiple: true,
        loading: isStudentsLoading,
        options: students || [],
        value: search.studentsId,
        placeholder: t('label.student'),
        onChange: value => handleChangeFilter('studentsId', value)
      }
    },
    {
      label: t('label.course'),
      variant: 'multiselect',
      filterProps: {
        id: 'course',
        multiple: true,
        loading: isCoursesLoading,
        options: courses?.options || [],
        value: search.coursesId,
        placeholder: t('label.course'),
        onChange: value => handleChangeFilter('coursesId', value)
      }
    },
    ...commonCommentsFilters
  ]

  const isSomeFilterSelected = checkIfFilterSelected(filters)

  const [currentComment, setCurrentComment] = useState<Comment | null>(null)

  const actions: (row: Comment) => TableAction<Comment>[] = row =>
    [
      {
        icon: <EditIcon />,
        text: t('button.edit'),
        hidden: row.addedBy.id !== user?.id && !user?.isSuperAdmin,
        onClick: (item: Comment) => {
          setCurrentComment(item)
          setIsModalOpen(true)
        }
      },
      {
        icon: <DeleteIcon />,
        text: t('button.remove'),
        variantAction: 'danger' as const,
        hidden: row.addedBy.id !== user?.id && !user?.isSuperAdmin,
        onClick: (item: Comment) => {
          setCurrentComment(item)
          setIsOpenRemoveCommentModal(true)
        }
      }
    ].filter(item => !item.hidden)

  return (
    <>
      <Filters
        filters={filters}
        onClearAll={handleClearAll}
        disabledClearAllButton={!isSomeFilterSelected}
        actionsRender={
          <CommentModal
            semester={search.semester}
            open={isModalOpen}
            onOpenChange={value => {
              if (value) setIsModalOpen(value)
              else closeModal()
            }}
            classId={classId}
            onUpdate={refetchAllComments}
            commentId={currentComment?.id}
            trigger={
              <Button icon={<AddIcon />}>
                {t('common:button.add-comment')}
              </Button>
            }
          />
        }
      />
      <Table
        id="comments-table"
        data={comments?.list || []}
        isLoading={isCommentsLoading || isCommentsCountLoading}
        columns={tableColumns}
        count={comments?.count}
        totalCount={commentsCount}
        pagination={{
          pageSize: search.pageSize,
          page: search.page
        }}
        actions={actions}
        onChangePageSize={handleChangePageSize}
        onChangePage={handleChangePage}
        expandedRowRender={record => (
          <CommentListExpandedRow comment={record} />
        )}
      />

      {currentComment ? (
        <RemoveCommentModal
          open={isOpenRemoveCommentModal}
          onOpenChange={setIsOpenRemoveCommentModal}
          commenId={currentComment.id}
          onSuccess={refetchAllComments}
        />
      ) : null}
    </>
  )
}

export default CommentsView
