import { useTranslation } from 'react-i18next'

import styles from './TableTopBarCount.module.scss'

type TableTopBarCount = {
  count: number
  totalCount?: number
  selectedItemsLength?: number
}

const TableTopBarCount = (props: TableTopBarCount) => {
  const { t } = useTranslation('common')

  const countText =
    props.totalCount && props.totalCount !== props.count
      ? t('help.rows-count-filtered', {
          count: props.count,
          TOTAL_COUNT: props.totalCount
        })
      : t('help.rows-count', {
          count: props.count
        })

  return (
    <div className={styles.topBarTextContainer}>
      <span className={styles.topBarText}>{countText}</span>
      {props.selectedItemsLength ? (
        <span className={styles.topBarText}>
          {t('help.selected-text', {
            count: props.selectedItemsLength
          })}
        </span>
      ) : null}
    </div>
  )
}

export default TableTopBarCount
