import { useTranslation } from 'react-i18next'

import type { PanelBulkUserArchiveResponse } from '@/api/generated-api.gen'
import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'
import { toast } from '@/hooks/useToast'

const useBulkArchiveUsersMutation = ({
  onSuccess,
  onError
}: {
  onSuccess: (data: PanelBulkUserArchiveResponse) => void
  onError: () => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: { ids: string[] }) =>
      httpService.panel.panelUsersBulkArchiveCreate({
        users_ids: data.ids
      }),
    onSuccess,
    onError
  })

const useBulkArchiveUsers = ({
  onSuccess,
  onError
}: {
  onSuccess: () => void
  onError: () => void
}) => {
  const { t } = useTranslation('users')

  return useBulkArchiveUsersMutation({
    onSuccess: ({ successfully_archived, unsuccessfully_archived }) => {
      if (successfully_archived > 0) {
        toast({
          variant: 'success',
          title: t('toast.users-archived', {
            count: successfully_archived
          })
        })
      }
      if (unsuccessfully_archived > 0) {
        toast({
          variant: 'error',
          title: t('toast.users-archived-failed', {
            count: unsuccessfully_archived
          })
        })
      }
      onSuccess()
    },
    onError
  })
}

export default useBulkArchiveUsers
