import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'

const useBulkArchiveStudents = ({
  onSuccess,
  onError
}: {
  onSuccess: (data: {
    successfully_archived: number
    unsuccessfully_archived: number
  }) => void
  onError: () => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: { ids: string[] }) =>
      httpService.panel.panelStudentsBulkArchiveCreate({
        students_ids: data.ids
      }),
    onSuccess,
    onError
  })

export default useBulkArchiveStudents
