import { useQuery } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'

type Params = {
  isCovered?: boolean
  isCancelled?: boolean
  semesterId?: string
}
const useLessonsCount = (params: Params) =>
  useQuery({
    queryKey: ['panelLessonsListCount', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const lessons = await httpService.panel.panelLessonsList({
        is_covered: params.isCovered,
        is_cancelled: params.isCancelled,
        semester_id: params.semesterId,
        page_size: 1,
        fetchKeys: {
          id: true
        }
      })

      return lessons.count
    }
  })

export default useLessonsCount
