import * as Popover from '@radix-ui/react-popover'
import useMask from '@react-input/mask/useMask'
import clsx from 'clsx'
import { endOfISOWeek, isBefore, startOfISOWeek } from 'date-fns'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CalendarIcon from '@/assets/icons/calendar.svg?react'
import ErrorCircleFilledIcon from '@/assets/icons/error-circle-filled.svg?react'
import { DATE_PLACEHOLDER } from '@/constants/date-format'
import { MAX_DATE } from '@/constants/max-date'
import type { FormFieldType } from '@/types/form-field-type'
import { formatDate } from '@/utils/format-date'
import { parseDate } from '@/utils/parse-date'

import styles from './WeekPicker.module.scss'
import ButtonIcon from '../ButtonIcon/ButtonIcon'
import Calendar from '../Calendar/Calendar'
import Input from '../Input/Input'

type WeekPickerProps = {
  className?: string
  disabled?: boolean
  clearable?: boolean
  placeholder?: string
  disabledDate?: (date: Date) => boolean
  onClear?: () => void
} & FormFieldType<Date | undefined>

const WeekPicker = (props: WeekPickerProps) => {
  const [refreshKey, setRefreshKey] = useState(0)

  const formatedValue = formatDate(props.value)

  const [open, setOpen] = useState(false)
  const [dateInputValue, setDateInputValue] = useState(formatedValue)

  useEffect(() => {
    setDateInputValue(formatDate(props.value))
    // force input rerender, because by useMask despite changing the value,
    // when removing the value some problems occur
    // and show the value is different from the one in html or state
    setRefreshKey(prev => prev + 1)
    setIsBlured(true)
  }, [props.value])

  const [isBlured, setIsBlured] = useState(true)

  const dateInput = useMask({
    mask: isBlured
      ? `${DATE_PLACEHOLDER} - ${DATE_PLACEHOLDER}`
      : DATE_PLACEHOLDER,
    showMask: true,
    replacement: { M: /\d/, D: /\d/, Y: /\d/ }
  })

  const handleOnDateChange = (dateValue?: Date) => {
    setDateInputValue(formatDate(dateValue))
    if (!dateValue) props.onClear?.()
    else {
      props.onChange?.(dateValue)
      setOpen(false)
      dateInput?.current?.focus()
    }
  }

  const setPreviousValidValue = () => {
    setDateInputValue(formatDate(props.value))
    props.onChange?.(props.value)
    props.onBlur?.(props.value)
  }

  const handleOnInputBlur = (value?: string) => {
    setIsBlured(true)
    if (!value || value === DATE_PLACEHOLDER) {
      setDateInputValue('')
      props.onClear?.()
      props.onBlur?.(undefined)
      return
    }

    const date = parseDate(value)

    if (!date || isBefore(MAX_DATE, date)) {
      setPreviousValidValue()
    } else {
      props.onChange?.(date)
      props.onBlur?.(date)
    }
  }

  const { t } = useTranslation('common')

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <div className={clsx(styles.inputContainer, props.className)}>
        <Popover.Anchor>
          <Input
            className={styles.input}
            ref={dateInput}
            key={refreshKey}
            id={props.id}
            disabled={props.disabled}
            value={isBlured ? formatValue(props.value) : dateInputValue}
            onBlur={handleOnInputBlur}
            onChange={setDateInputValue}
            placeholder={props.placeholder || DATE_PLACEHOLDER}
            onFocus={() => setIsBlured(false)}
            describedby={props.describedby}
            invalid={props.invalid}
            required={props.required}
          />
        </Popover.Anchor>

        {props.value && props.clearable ? (
          <ButtonIcon
            onClick={() => {
              setDateInputValue('')
              props.onClear?.()
            }}
            variant="tertiary"
            size="extra-small"
            ariaLabel={t('label.clear-icon')}
            className={styles.clearIcon}
          >
            <ErrorCircleFilledIcon />
          </ButtonIcon>
        ) : null}

        <Popover.Trigger asChild>
          <ButtonIcon
            disabled={props.disabled}
            size="small"
            variant="tertiary"
            className={styles.calendarButton}
            ariaLabel={t('button.choose-week')}
          >
            <CalendarIcon />
          </ButtonIcon>
        </Popover.Trigger>
      </div>
      <Popover.Portal>
        <Popover.Content className={styles.calendarPopover}>
          <Calendar
            id={props.id}
            mode="week"
            value={props.value}
            disabledDates={props.disabledDate}
            onChange={handleOnDateChange}
            autoFocus
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

const formatValue = (value?: Date) => {
  if (!value) return ''
  const startWeek = startOfISOWeek(value)
  const endWeek = endOfISOWeek(value)

  return `${formatDate(startWeek)} - ${formatDate(endWeek)}`
}
export default WeekPicker
