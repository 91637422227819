import { getRouteApi } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import type { SelectOption } from '@/components/Select/Select'
import type { Filter } from '@/components/common/Filters/Filters'
import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'
import { userProfiles } from '@/constants/user-profiles'
import useSemesters from '@/queries/useSemesters'
import { checkIfFilterSelected } from '@/utils/check-if-filters-selected'
import { getFilterValue } from '@/utils/get-filter-value'

import type { AvailabilityManagementFiltersKey } from '../constants/availability-management-filters'
import { availabilityUsersQueryOptions } from '../queries/availabilityUsersQueryOptions'

const routeApi = getRouteApi('/_auth/availability-management/')

const useAvailabilityMangementFilters = () => {
  const { t } = useTranslation('availabilityManagement')
  const navigate = routeApi.useNavigate()
  const search = routeApi.useSearch()

  const { semester, name, role } = routeApi.useSearch()

  const { data: semesters, isLoading: isSemestersLoading } = useSemesters()

  const handleChangeFilter = (
    key: AvailabilityManagementFiltersKey,
    value?: string[] | SelectOption<string>[]
  ) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: 1,
        [key]: getFilterValue(value)
      })
    })
  }

  const handleClearAll = () => {
    navigate({
      search: previousValue => ({
        day: previousValue.day,
        semester: semesters?.currentSemester.id || search.semester,
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE
      })
    })
  }

  const handleChangeSemester = (semesterId: string) => {
    navigate({
      search: previousValue => ({
        day: previousValue.day,
        semester: semesterId,
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE
      })
    })
  }

  const filters: Filter[] = [
    {
      label: t('label.semester'),
      variant: 'semester',
      size: 'wide',
      filterProps: {
        id: 'semesterId',
        dataTestId: 'semester-filter',
        loading: isSemestersLoading,
        options: semesters?.options || [],
        value: semester,
        onChange: handleChangeSemester,
        semesterList: semesters?.list || []
      }
    },
    {
      label: t('label.user-name'),
      variant: 'async-multiselect',
      filterProps: {
        id: 'user-name',
        dataTestId: 'user-name-filter',
        multiple: true,
        queryOptions: usernameSearch =>
          availabilityUsersQueryOptions({
            search: usernameSearch,
            semesterId: semester
          }),
        value: name,
        placeholder: t('label.user-name'),
        onChange: value => handleChangeFilter('name', value)
      }
    },
    {
      label: t('label.user-role'),
      variant: 'multiselect',
      filterProps: {
        id: 'roleId',
        dataTestId: 'user-role-filter',
        multiple: true,
        options: userProfiles,
        value: role,
        placeholder: t('label.user-role'),
        onChange: value => handleChangeFilter('role', value)
      }
    }
  ]

  const isSomeFilterSelected = checkIfFilterSelected(filters, ['semesterId'])

  return { filters, handleClearAll, isSomeFilterSelected }
}

export default useAvailabilityMangementFilters
