import { isAfter } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Button/Button'
import Modal from '@/components/Modal/Modal'
import useSemesters from '@/queries/useSemesters'

import styles from './SendAvailabilityRequestModal.module.scss'
import SemesterSelect from '../SemesterSelect/SemesterSelect'

type Props = {
  open: boolean
  onOpenChange: (open: boolean) => void
  selectedSemester: string
  description: string
  onSendRequest: (semesterId: string) => void
  sendRequestLoading: boolean
}

const SendAvailabilityRequestModal = (props: Props) => {
  const { data: semestersData, isLoading: semestersLoading } = useSemesters()
  const [semesterId, setSemesterId] = useState(props.selectedSemester)
  const { t } = useTranslation('common')

  const currentAndFutureSemesters = useMemo(() => {
    const today = new Date()

    return semestersData?.list
      .filter(({ endDate }) => isAfter(endDate, today))
      .sort((a, b) => a.startDate.getTime() - b.startDate.getTime())
      .map(({ id, name }) => ({ label: name, value: id }))
  }, [semestersData])

  useEffect(() => {
    if (props.open) {
      setSemesterId(props.selectedSemester)
    }
  }, [props.selectedSemester, props.open])

  return (
    <Modal
      id="send-avaialability-request-modal"
      title={t('header.send-email')}
      size="sm"
      open={props.open}
      onOpenChange={props.onOpenChange}
      hideCloseIcon
      footer={
        <div className={styles.buttons}>
          <Button variant="tertiary" onClick={() => props.onOpenChange(false)}>
            {t('button.cancel')}
          </Button>
          <Button
            onClick={() => props.onSendRequest(semesterId)}
            loading={props.sendRequestLoading}
          >
            {t('button.send')}
          </Button>
        </div>
      }
    >
      <div className={styles.content}>
        <p className={styles.description}>{props.description}</p>
        <SemesterSelect
          id="semester"
          options={currentAndFutureSemesters || []}
          value={semesterId}
          onChange={setSemesterId}
          semesterList={semestersData?.list || []}
          loading={semestersLoading}
        />
      </div>
    </Modal>
  )
}

export default SendAvailabilityRequestModal
