import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import type { IdAndName } from '@/types/id-and-name'
import { attendanceStatus, requiredString } from '@/utils/zod'

import { type LessonAttendanceEnum } from '../types/absences-status'

const responseSchema = z.object({
  id: requiredString(),
  start_date: z.coerce.date(),
  end_date: z.coerce.date(),
  comment: z.string(),
  attachments: z.array(
    z.object({
      id: requiredString(),
      file_name: requiredString(),
      file_size: z.number()
    })
  ),
  attendances: z.array(
    z.object({
      id: requiredString(),
      presence: attendanceStatus,
      lesson: z.object({
        id: requiredString(),
        date: z.coerce.date(),
        course: z.object({
          id: requiredString(),
          name: requiredString()
        }),
        group: z.object({
          id: requiredString(),
          name: requiredString()
        })
      })
    })
  )
})

export type Attendance = {
  id: string
  presence: LessonAttendanceEnum
  lesson: {
    id: string
    date: Date
    course: IdAndName
    group: IdAndName
  }
}

export type Absence = {
  id: string
  startDate: Date
  endDate: Date
  comment: string
  attachment: {
    id: string
    fileName: string
    fileSize: number
  }[]
  attendances: Attendance[]
}

const parseResponse = (response: z.infer<typeof responseSchema>): Absence => ({
  id: response.id,
  startDate: response.start_date,
  endDate: response.end_date,
  comment: response.comment,
  attachment: response.attachments.map(attachment => ({
    id: attachment.id,
    fileName: attachment.file_name,
    fileSize: attachment.file_size
  })),
  attendances: response.attendances
})

const useAbsence = ({ id, enabled }: { id?: string; enabled: boolean }) =>
  useQuery({
    queryKey: ['panelAbsencesRetrieve', id],
    staleTime: 60 * 100,
    queryFn: async () => {
      if (!id) return

      const absence = await httpService.panel.panelAbsencesRetrieve(id, {
        fetchKeys: {
          id: true,
          start_date: true,
          end_date: true,
          comment: true,
          attachments: true,
          attendances: true
        }
      })

      return parseResponse(responseSchema.parse(absence))
    },
    enabled,
    refetchOnWindowFocus: false
  })

export default useAbsence
