import { useTranslation } from 'react-i18next'

import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling, {
  type ClientErrorsMapper,
  type MutationError
} from '@/hooks/useMutationWithErrorsHandling'
import { toast } from '@/hooks/useToast'
import type { CustomError } from '@/types/app-errors'

type CustomErrors = CustomError & {
  code: 'scheduled_lessons'
}

const clientErrorsMapper: ClientErrorsMapper<never, CustomErrors> = {
  scheduled_lessons: () => [
    {
      name: 'CustomError',
      code: 'scheduled_lessons',
      message: 'Scheduled lessons'
    }
  ]
}

const useArchiveUserMutation = ({
  onSuccess,
  onError
}: {
  onSuccess: () => void
  onError: (error: MutationError<{ id: string }, CustomErrors>) => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: { id: string }) =>
      httpService.panel.panelUsersArchiveCreate(data.id),
    onSuccess,
    onError: error => onError(error),
    clientErrorsMapper
  })

const useArchiveUser = ({
  username,
  setOpenArchiveConfirmModal,
  refetch
}: {
  username: string | undefined
  setOpenArchiveConfirmModal: (open: boolean) => void
  refetch: () => void
}) => {
  const { t } = useTranslation('users')

  return useArchiveUserMutation({
    onSuccess: () => {
      toast({
        variant: 'success',
        title: t('toast.user-archived', {
          NAME: username
        })
      })
      setOpenArchiveConfirmModal(false)
      refetch()
    },
    onError: errors => {
      if (
        errors.find(
          error =>
            error.name === 'CustomError' && error.code === 'scheduled_lessons'
        )
      ) {
        toast({
          variant: 'error',
          title: t('notification.cannot-archive-user', {
            NAME: username
          }),
          description: t('notification.user-has-active-lessons')
        })
      } else {
        toast({
          variant: 'error',
          title: t('toast.user-archived-failed', {
            NAME: username
          })
        })
      }
    }
  })
}

export default useArchiveUser
