import { z } from 'zod'

import { httpService } from '@/api/http.service'

import { requiredString, userProfiles } from './zod'

const userSchema = z.object({
  id: requiredString(),
  first_name: z.string(),
  last_name: z.string(),
  name: z.string(),
  email: z.string(),
  is_teacher: z.boolean(),
  is_superuser: z.boolean(),
  is_generator_admin: z.boolean(),
  app_language: z.union([z.literal('en'), z.literal('de'), z.literal('fr')]),
  profiles: userProfiles,
  tutor: z
    .object({
      classes: z.array(
        z.object({
          id: requiredString(),
          display_name: requiredString()
        })
      )
    })
    .nullable(),
  timetable_preview_seen_at: z.string().nullable()
})

export const fetchUser = async () => {
  const user = await httpService.panel.panelUsersMeRetrieve()
  const parsed = userSchema.parse(user)

  return {
    id: parsed.id,
    firstName: parsed.first_name,
    lastName: parsed.last_name,
    fullName: parsed.name,
    email: parsed.email,
    isTeacher: parsed.is_teacher,
    isSuperAdmin: parsed.is_superuser,
    isGeneratorAdmin: parsed.is_generator_admin,
    profiles: parsed.profiles,
    appLanguage: parsed.app_language,
    tutorClasses: parsed.tutor
      ? parsed.tutor.classes.map(tutorClass => ({
          id: tutorClass.id,
          name: tutorClass.display_name
        }))
      : undefined,
    hasSeenTimetablePreview: !!parsed.timetable_preview_seen_at
  }
}
