import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Checkbox from '@/components/Checkbox/Checkbox'
import FormField from '@/components/FormField'
import ModalForm from '@/components/Modal/ModalForm'
import RadioGroup from '@/components/Radio/RadioGroup'
import { toast } from '@/hooks/useToast'

import useSendActivationLink, {
  type ActivateLinkFormPaylod,
  activateLinkFormSchema,
  type ActivateLinkResponse
} from '../mutations/useSendActivationLink'

export type ActivationLinkModalVariant = 'single' | 'bulk'

type ActivationLinkModalProps = {
  variant: ActivationLinkModalVariant
  ids?: string[]
  open: boolean
  studentName?: string
  onSuccess: () => void
  onOpenChange: (open: boolean) => void
}

const ActivationLinkModal = (props: ActivationLinkModalProps) => {
  const { t } = useTranslation(['students'])
  const isSingleVariant = props.variant === 'single'

  const { isPending, mutate: sendActivationLink } = useSendActivationLink({
    onSuccess: (data?: ActivateLinkResponse) => {
      if (data?.successfullySent) {
        toast({
          variant: 'success',
          title: isSingleVariant
            ? t('toast.successfully-send-activation-link')
            : t('toast.successfully-send-activation-links', {
                count: data.successfullySent
              })
        })
      }
      if (data?.unsuccessfullySent && !isSingleVariant) {
        toast({
          variant: 'error',
          title: t('toast.failed-send-activation-links', {
            NUMBER: data.unsuccessfullySent
          })
        })
      }
      props.onSuccess()
      handleClose()
    }
  })

  const form = useForm<ActivateLinkFormPaylod>({
    resolver: zodResolver(activateLinkFormSchema),
    mode: 'all',
    defaultValues: {
      ids: [],
      sendToParents: false,
      sendToStudents: false,
      sendMode: 'studentsAndParents'
    }
  })

  const handleClose = () => {
    form.reset()
    props.onOpenChange(false)
  }

  const handleOnSubmit = (value: ActivateLinkFormPaylod) => {
    if (!props.ids) return

    if (isSingleVariant) {
      const payload = {
        ids: props.ids,
        sendToStudents: true,
        sendToParents: value.sendToParents
      }

      sendActivationLink(payload)
    } else {
      const payload = {
        ids: props.ids,
        sendToStudents:
          value.sendMode === 'students' ||
          value.sendMode === 'studentsAndParents',
        sendToParents:
          value.sendMode === 'parents' ||
          value.sendMode === 'studentsAndParents'
      }

      sendActivationLink(payload)
    }
  }

  const radioOptions = [
    {
      label: t('label.students-and-quardiands'),
      value: 'studentsAndParents'
    },
    { label: t('label.students-only'), value: 'students' },
    { label: t('label.quardiands-only'), value: 'parents' }
  ]
  return (
    <ModalForm
      id="send-activation-link-modal"
      title={t('header.send-activation-link')}
      form={form}
      open={props.open}
      onOpenChange={props.onOpenChange}
      submitText={t('button.send')}
      onSubmit={handleOnSubmit}
      onCancel={handleClose}
      loading={isPending}
      onClose={handleClose}
      size="xs"
    >
      <p>
        {isSingleVariant
          ? t('help.send-activation-link-single', {
              NAME: props.studentName
            })
          : t('help.send-activation-link-bulk')}
      </p>

      {isSingleVariant ? (
        <FormField
          control={form.control}
          id="send-to-guardians"
          label={t('label.send-to-quardiands')}
          hideLabel
          name="sendToParents"
          render={({ inputProps }) => (
            <Checkbox label={t('label.send-to-quardiands')} {...inputProps} />
          )}
        />
      ) : (
        <FormField
          control={form.control}
          id="send-mode"
          label={t('label.send-to')}
          hideLabel
          required={!isSingleVariant}
          name="sendMode"
          render={({ inputProps }) => (
            <RadioGroup {...inputProps} options={radioOptions} />
          )}
        />
      )}
    </ModalForm>
  )
}

export default ActivationLinkModal
