import clsx from 'clsx'
import { type UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { type OptionContext } from 'react-select'

import FormField from '@/components/FormField'
import Select, { type SelectOption } from '@/components/Select/Select'
import { FormSection } from '@/components/common/FormGrid/FormGrid'
import useAcademicLevels from '@/queries/useAcademicLevels'
import useClassesOptions, { type Class } from '@/queries/useClassesOptions'
import { getGroupedClassesOptions } from '@/utils/get-grouped-classes-options'
import { typedOrderBy } from '@/utils/order-by'

import styles from './AcademicInformation.module.scss'
import type { StudentForm } from '../constants/student-payload'

type AcademicInformationProps = {
  form: UseFormReturn<StudentForm>
}

const AcademicInformation = (props: AcademicInformationProps) => {
  const { t } = useTranslation('students')

  const { data: academicLevels, isLoading: isAcademicLevelsLoading } =
    useAcademicLevels()
  const { data: classes, isLoading: isClassesLoading } = useClassesOptions()

  const selectedClass = props.form.watch('class')
  const selectedAcademicLevel = props.form.watch('academicLevel')

  const filteredClasses = typedOrderBy(
    (selectedAcademicLevel
      ? classes?.filter(
          item => item.academicLevel?.id === selectedAcademicLevel
        )
      : classes) || [],
    'tutor',
    'desc'
  )

  const handleClassChange = (option: SelectOption<string>) => {
    const academicLevel = classes?.find(
      item => item.id === option.value
    )?.academicLevel
    if (!academicLevel) return

    props.form.setValue('academicLevel', academicLevel.id)
    props.form.trigger('academicLevel')
  }

  const handleChangeAcademicLevel = () => {
    props.form.setValue('class', null)
  }

  return (
    <FormSection header={t('header.academic-information')}>
      <FormField
        control={props.form.control}
        id="academic-level"
        label={t('label.academic-level')}
        name="academicLevel"
        required
        render={({ inputProps }) => (
          <Select
            {...inputProps}
            placeholder={t('placeholder.select-academic-level')}
            options={academicLevels?.options || []}
            loading={isAcademicLevelsLoading}
            onChange={value => {
              inputProps.onChange?.(value)
              handleChangeAcademicLevel()
            }}
          />
        )}
      />
      <FormField
        control={props.form.control}
        id="class"
        label={t('label.class')}
        name="class"
        render={({ inputProps }) => (
          <Select<string>
            {...inputProps}
            placeholder={t('placeholder.select-class')}
            clearable
            mode="detailed"
            value={inputProps.value || undefined}
            options={getGroupedClassesOptions(filteredClasses)}
            loading={isClassesLoading}
            onClear={() => props.form.setValue('class', null)}
            onChange={value => {
              inputProps.onChange?.(value)
              handleClassChange(value)
            }}
            formatOption={(option, context) => (
              <ClassOption
                option={option}
                classes={filteredClasses}
                selectedClass={selectedClass || undefined}
                optionContext={context}
              />
            )}
          />
        )}
      />
    </FormSection>
  )
}

type ClassOptionProps = {
  option: SelectOption<string>
  classes: Class[]
  selectedClass?: SelectOption<string>
  optionContext: OptionContext
}

const ClassOption = (props: ClassOptionProps) => {
  const classOption = props.classes?.find(
    item => item.id === props.option.value
  )

  return (
    <div
      className={clsx(
        styles.classOption,
        props.selectedClass?.value === props.option.value &&
          props.optionContext === 'menu' &&
          styles.classOptionSelected
      )}
    >
      <span>{props.option.label}</span>
      <span className={styles.tutor}>{classOption?.tutor?.fullName}</span>
    </div>
  )
}

export default AcademicInformation
