import i18n from '@/i18n'
import { type UserStatus } from '@/types/user-status'

export const getStudentStatusTootlipText = (value: UserStatus) => {
  switch (value) {
    case 'active':
      return i18n.t('help.active-student-status', { ns: 'students' })
    case 'inactive':
      return i18n.t('help.inactive-student-status', { ns: 'students' })
    case 'blocked':
      return i18n.t('help.blocked-student-status', { ns: 'students' })
    case 'archived':
      return i18n.t('help.archived-student-status', { ns: 'students' })
    default:
      return ''
  }
}

export const getParentStatusTootlipText = (value: UserStatus) => {
  switch (value) {
    case 'active':
      return i18n.t('help.active-parent-status', { ns: 'students' })
    case 'inactive':
      return i18n.t('help.inactive-parent-status', { ns: 'students' })
    case 'blocked':
      return i18n.t('help.blocked-parent-status', { ns: 'students' })
    case 'archived':
      return i18n.t('help.archived-parent-status', { ns: 'students' })
    default:
      return ''
  }
}
