import * as Popover from '@radix-ui/react-popover'
import { useMask } from '@react-input/mask'
import clsx from 'clsx'
import { isAfter, isBefore } from 'date-fns'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CalendarIcon from '@/assets/icons/calendar.svg?react'
import ErrorCircleFilledIcon from '@/assets/icons/error-circle-filled.svg?react'
import { DATE_PLACEHOLDER } from '@/constants/date-format'
import { MAX_DATE } from '@/constants/max-date'
import type { FormFieldType } from '@/types/form-field-type'
import { formatDate } from '@/utils/format-date'
import { parseDate } from '@/utils/parse-date'

import styles from './DateRangePicker.module.scss'
import ButtonIcon from '../ButtonIcon/ButtonIcon'
import Calendar, {
  type DateRange as DateRangeCalendar
} from '../Calendar/Calendar'
import Input from '../Input/Input'
import Label from '../Label'

export type DateRange = Partial<DateRangeCalendar>

export type DatePickerProps = FormFieldType<DateRange | undefined> & {
  disabled?: boolean
  clearable?: boolean
  placeholder?: [string, string]
  onClear?: () => void
  disabledDate?: (date: Date) => boolean
  defaultMonth?: Date
}

const DateRangePicker = (props: DatePickerProps) => {
  const { t } = useTranslation('common')

  const [open, setOpen] = useState(false)

  const isFirstSelection =
    (!!props.value?.from && !!props.value?.to) ||
    (!props.value?.from && !props.value?.to)

  const dateFromInput = useMask({
    mask: DATE_PLACEHOLDER,
    showMask: true,
    replacement: { M: /\d/, D: /\d/, Y: /\d/ }
  })

  const dateToInput = useMask({
    mask: DATE_PLACEHOLDER,
    showMask: true,
    replacement: { M: /\d/, D: /\d/, Y: /\d/ }
  })

  const [dateFromInputValue, setDateFromInputValue] = useState(
    formatDate(props.value?.from)
  )

  const [dateToInputValue, setDateToInputValue] = useState(
    formatDate(props.value?.to)
  )

  const [refreshKey, setRefreshKey] = useState(0)

  useEffect(() => {
    setDateFromInputValue(formatDate(props.value?.from))
    setDateToInputValue(formatDate(props.value?.to))

    // force input rerender, because by useMask despite changing the value,
    // when removing the value some problems occur
    // and show the value is different from the one in html or state
    setRefreshKey(prev => prev + 1)
  }, [props.value])

  const handleOnDateChange = (dateValue?: DateRangeCalendar) => {
    if (dateValue?.from && dateValue.to) {
      setOpen(false)
    }

    if (!dateValue?.from && !dateValue?.to) props.onClear?.()
    setDateFromInputValue(formatDate(dateValue?.from))
    setDateToInputValue(formatDate(dateValue?.to))
    props.onChange?.(dateValue)
  }

  const handleOnDateInputFromBlur = (value?: string) => {
    if (!value || value === DATE_PLACEHOLDER) {
      setDateToInputValue('')
      props.onChange?.({ from: undefined, to: props.value?.to })
      return
    }

    const newFromDate = parseDate(value)
    const toDate = props.value?.to

    if (newFromDate && isBefore(newFromDate, MAX_DATE)) {
      if (toDate && isBefore(newFromDate, toDate))
        props.onChange?.({ from: newFromDate, to: toDate })
      else props.onChange?.({ from: newFromDate })
    } else {
      setDateFromInputValue(formatDate(props.value?.from))
      props.onChange?.(props.value)
    }
  }

  const handleOnDateInputToBlur = (value?: string) => {
    if (!value || value === DATE_PLACEHOLDER) {
      setDateToInputValue('')
      props.onChange?.({ ...props.value, to: undefined })
      return
    }

    const newToDate = parseDate(value)
    const fromDate = props.value?.from

    const areDatesValid = !!newToDate && !!fromDate

    if (
      areDatesValid &&
      isBefore(newToDate, MAX_DATE) &&
      !isAfter(fromDate, newToDate)
    ) {
      if (props.value?.from) props.onChange?.({ from: fromDate, to: newToDate })
    } else {
      setDateToInputValue(formatDate(props.value?.to))
      props.onChange?.(props.value)
    }
  }

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <div
        className={clsx(
          styles.inputContainer,
          props.invalid && styles.invalid,
          props.disabled && styles.disabled
        )}
      >
        <Popover.Anchor>
          <div className={styles.inputRangeContainer} id={props.id}>
            <Label
              id={`${props.id}-from`}
              hidden
              label={t('label.from')}
              className={styles.inputFrom}
            >
              <Input
                ref={dateFromInput}
                key={refreshKey}
                borderless
                required={props.required}
                id={`${props.id}-from`}
                dataTestId="date-from"
                disabled={props.disabled}
                value={dateFromInputValue}
                onBlur={handleOnDateInputFromBlur}
                onChange={setDateFromInputValue}
                describedby={props.describedby}
                invalid={props.invalid}
                placeholder={props.placeholder?.[0] || t('label.start-date')}
                className={styles.input}
              />
            </Label>
            <span className={styles.divider}>-</span>
            <Label
              id={`${props.id}-to`}
              hidden
              label={t('label.to')}
              className={styles.inputTo}
            >
              <Input
                ref={dateToInput}
                borderless
                key={refreshKey}
                id={`${props.id}-to`}
                dataTestId="date-to"
                required={props.required}
                describedby={props.describedby}
                disabled={props.disabled}
                invalid={props.invalid}
                value={dateToInputValue}
                onBlur={handleOnDateInputToBlur}
                onChange={setDateToInputValue}
                placeholder={props.placeholder?.[1] || t('label.end-date')}
                className={styles.input}
              />
            </Label>
          </div>
        </Popover.Anchor>

        {(props.value?.from || props.value?.to) && props.clearable ? (
          <ButtonIcon
            onClick={() => {
              setDateFromInputValue('')
              setDateToInputValue('')
              props.onClear?.()
            }}
            variant="tertiary"
            size="extra-small"
            ariaLabel={t('label.clear-icon')}
            className={styles.clearIcon}
          >
            <ErrorCircleFilledIcon />
          </ButtonIcon>
        ) : null}

        <Popover.Trigger asChild>
          <ButtonIcon
            disabled={props.disabled}
            size="small"
            variant="tertiary"
            className={styles.calendarButton}
            ariaLabel={t('button.choose-date-range')}
          >
            <CalendarIcon />
          </ButtonIcon>
        </Popover.Trigger>
      </div>
      <Popover.Portal>
        <Popover.Content className={styles.calendarPopover}>
          <Calendar
            id={props.id}
            mode="range"
            isFirstSelection={isFirstSelection}
            disabledDates={props.disabledDate}
            value={
              props.value?.from
                ? { from: props.value.from, to: props.value.to }
                : undefined
            }
            onChange={handleOnDateChange}
            autoFocus
            defaultMonth={props.defaultMonth}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

export default DateRangePicker
