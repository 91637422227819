import { zodResolver } from '@hookform/resolvers/zod'
import { useQueries } from '@tanstack/react-query'
import { getRouteApi } from '@tanstack/react-router'
import { useState } from 'react'
import { useFieldArray, useForm, type UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDebounceValue } from 'usehooks-ts'

import PlusIcon from '@/assets/icons/add.svg?react'
import DeleteIcon from '@/assets/icons/delete.svg?react'
import Alert from '@/components/Alert/Alert'
import Button from '@/components/Button/Button'
import ButtonIcon from '@/components/ButtonIcon/ButtonIcon'
import FormField from '@/components/FormField'
import Input from '@/components/Input/Input'
import InputPhoneNumber from '@/components/InputPhoneNumber/InputPhoneNumber'
import Label from '@/components/Label'
import Select from '@/components/Select/Select'
import Table, { type Column } from '@/components/Table/Table'
import SendActivationLinkFormField from '@/components/common/ActivationLinkFormField/SendActivationLinkFormField'
import {
  FormButtons,
  FormLayout,
  FormSection,
  FormSectionHeader
} from '@/components/common/FormGrid/FormGrid'
import { toast } from '@/hooks/useToast'
import { useStudentsEmailAndName } from '@/queries/useStudentsEmailAndName'
import { validateEmailUniqueness } from '@/utils/validate-email-uniqueness'

import { EmailAndNameOption } from './EmailAndNameOption'
import styles from './ParentForm.module.scss'
import {
  parentFormSchema,
  type ParentForm as ParentFormType
} from '../constants/parent-payload'
import useEditParent from '../mutations/useEditParent'
import { type Parent } from '../queries/useParent'
import { studentQueryOptions, type Student } from '../queries/useStudent'

type ParentFormProps = {
  parent: Parent
}

const routeApi = getRouteApi(
  '/_auth/students-and-classes/students/parents/$parentId/edit'
)

export const ParentForm = (props: ParentFormProps) => {
  const { t } = useTranslation('students')
  const navigate = routeApi.useNavigate()
  const { parentId } = routeApi.useParams()

  const form = useForm<ParentFormType>({
    resolver: zodResolver(parentFormSchema),
    mode: 'onBlur',
    defaultValues: {
      email: props.parent.email,
      firstName: props.parent.firstName,
      lastName: props.parent.lastName,
      phoneNumber: props.parent.phoneNumber,
      sendActivationLink: false,
      students: props.parent.students
    }
  })

  const { mutate: editParent } = useEditParent({
    id: props.parent.id,
    onSuccess: () => {
      toast({ variant: 'success', title: t('toast.guardian-updated') })
      navigate({
        to: '/students-and-classes/students/parents/$parentId/details',
        params: {
          parentId
        }
      })
    },
    onError: () =>
      toast({ variant: 'error', title: t('toast.failed-guardian-update') })
  })

  return (
    <FormLayout
      form={form}
      onSubmit={async data => {
        const valid = await validateEmailUniqueness({
          field: 'email',
          form
        })

        if (!valid) return
        editParent(data)
      }}
    >
      <FormSection header={t('header.details')}>
        <FormField
          control={form.control}
          id="first-name"
          required
          label={t('label.first-name')}
          name="firstName"
          render={({ inputProps }) => (
            <Input
              {...inputProps}
              placeholder={t('placeholder.type-first-name')}
            />
          )}
        />
        <FormField
          control={form.control}
          id="last-name"
          required
          label={t('label.last-name')}
          name="lastName"
          render={({ inputProps }) => (
            <Input
              {...inputProps}
              placeholder={t('placeholder.type-last-name')}
            />
          )}
        />
        <FormField
          control={form.control}
          id="email"
          required
          label={t('label.email')}
          name="email"
          render={({ inputProps }) => (
            <Input
              {...inputProps}
              placeholder={t('placeholder.type-email')}
              onBlur={() => {
                inputProps.onBlur?.()
                setTimeout(
                  () =>
                    validateEmailUniqueness({
                      field: 'email',
                      form,
                      initialValue: props.parent.email
                    }),
                  0
                )
              }}
            />
          )}
        />
        <FormField
          control={form.control}
          id="phone-number"
          required
          label={t('label.phone-number')}
          name="phoneNumber"
          render={({ inputProps }) => <InputPhoneNumber {...inputProps} />}
        />
        <SendActivationLinkFormField
          control={form.control}
          name="sendActivationLink"
          id="send-activation-link"
          userStatus={props.parent.status}
        />
      </FormSection>
      <ParentStudentsForm form={form} />
      <Alert
        message={t('help.changing-guardian-data')}
        variant="info"
        className={styles.alert}
      />
      <FormButtons />
    </FormLayout>
  )
}

type ParentStudentsFormProps = {
  form: UseFormReturn<ParentFormType>
}

const ParentStudentsForm = (props: ParentStudentsFormProps) => {
  const { t } = useTranslation('students')

  const [isStudentSelectVisible, setIsStudentSelectVisible] = useState(false)
  const { fields, append, remove } = useFieldArray({
    control: props.form.control,
    name: 'students',
    keyName: '_id'
  })

  return (
    <div className={styles.parentStudents}>
      <FormSectionHeader>{t('header.students')}</FormSectionHeader>
      <ParentStudentsList onRemove={remove} students={fields} />
      {isStudentSelectVisible ? (
        <AddStudent
          onAdd={id => append({ id })}
          selectedIds={fields.map(({ id }) => id)}
        />
      ) : (
        <Button
          variant="secondary"
          icon={<PlusIcon />}
          className={styles.addStudentButton}
          onClick={() => setIsStudentSelectVisible(true)}
        >
          {t('button.add-student')}
        </Button>
      )}
    </div>
  )
}

type ParentStudentsListProps = {
  students: { id: string }[]
  onRemove: (index: number) => void
}

const ParentStudentsList = (props: ParentStudentsListProps) => {
  const { t } = useTranslation('students')

  const studentQueries = useQueries({
    queries: props.students.map(({ id }) => studentQueryOptions(id))
  })

  const areStudentsLoading = studentQueries.some(({ isLoading }) => isLoading)
  const students = studentQueries.flatMap(({ data }) => (data ? data : []))

  const columns: Column<Student>[] = [
    {
      key: 'student-name',
      width: 200,
      title: t('header.name'),
      dataIndex: 'fullName'
    },
    {
      key: 'student-email',
      width: 260,
      title: t('header.email'),
      dataIndex: 'email'
    },
    {
      key: 'phone-number',
      width: 140,
      title: t('header.phone-number'),
      dataIndex: 'phoneNumber'
    },
    {
      key: 'remove-student',
      width: 40,
      render: (_, __, index) => (
        <ButtonIcon
          size="medium"
          variant="tertiary"
          ariaLabel={t('button.remove-student')}
          onClick={() => props.onRemove(index)}
        >
          <DeleteIcon />
        </ButtonIcon>
      )
    }
  ]

  return (
    <Table
      id="parent-students"
      data={students}
      isLoading={areStudentsLoading}
      columns={columns}
      showTopBar={false}
    />
  )
}

type AddStudentProps = {
  onAdd: (id: string) => void
  selectedIds: string[]
}

const AddStudent = (props: AddStudentProps) => {
  const { t } = useTranslation('students')

  const [search, setSearch] = useDebounceValue('', 300)
  const { data, isLoading, fetchNextPage } = useStudentsEmailAndName({ search })
  const students = data?.pages.flatMap(({ results }) => results)

  return (
    <div className={styles.addParentStudent}>
      <Label id="parent-student" label={t('label.add-student')} />
      <Select
        id="parent-student"
        loading={isLoading}
        onChange={props.onAdd}
        options={
          students
            ?.filter(({ id }) => !props.selectedIds.includes(id))
            .map(({ id, fullName, email }) => ({
              value: id,
              label: `${email} ${fullName}`
            })) ?? []
        }
        formatOption={(option, context) => {
          const student = students?.find(({ id }) => id === option.value)
          return (
            <EmailAndNameOption
              optionContext={context}
              name={student?.fullName ?? ''}
              email={student?.email ?? ''}
            />
          )
        }}
        onInputChange={setSearch}
        onMenuScrollToBottom={() => fetchNextPage()}
      />
    </div>
  )
}

export default ParentForm
