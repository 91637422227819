import { useMutation } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'

const useMarkTimetablePreviewAsSeen = () =>
  useMutation({
    mutationFn: () => httpService.panel.panelTimetablePreviewMarkAsSeenCreate()
  })

export default useMarkTimetablePreviewAsSeen
