import { getRouteApi, useBlocker, useRouter } from '@tanstack/react-router'
import { groupBy, isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Button/Button'
import Collapse from '@/components/Collapse/Collapse'
import ConfirmModal from '@/components/ConfirmModal'
import DateRangePicker from '@/components/DateRangePicker/DateRangePicker'
import Drawer from '@/components/Drawer/Drawer'
import Label from '@/components/Label'
import Modal from '@/components/Modal/Modal'
import Select from '@/components/Select/Select'
import Textarea from '@/components/Textarea/Textarea'
import BasicLayout from '@/components/common/BasicLayout/BasicLayout'
import DirtyModal from '@/components/common/DirtyModal'
import { useScreenResolution } from '@/hooks/useScreenResolution'
import { toast } from '@/hooks/useToast'
import useTeachers from '@/queries/useTeachers'
import type { IdAndName } from '@/types/id-and-name'
import { type CoverCancelLesson } from '@/types/lesson'
import { formatDate, formatApiDate } from '@/utils/format-date'

import styles from './AddCoverCancelLessonView.module.scss'
import Lesson, { type LessonStatus } from '../components/Lesson'
import Summary from '../components/Summary'
import useConflicts from '../hooks/useConflicts'
import useBulkCoverAndCancel, {
  type BulkCoverAndCancelLeson
} from '../mutations/useBulkCoverAndCancel'
import useLessonsAddCoverCancel from '../queries/useLessonsAddCoverCancel'

const routeApi = getRouteApi(
  '/_auth/covered-and-cancelled-lessons/add-cover-cancel'
)

const AddCoverCancelLessonView = () => {
  const { t } = useTranslation(['coveredCancelledLessons', 'common'])
  const router = useRouter()

  const { teacherId, dateAfter, dateBefore } = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const [comment, setComment] = useState('')
  const { data: teachers, isLoading: isTeachersLoading } = useTeachers()

  const { data: lessons } = useLessonsAddCoverCancel({
    teacherId,
    dateAfter,
    dateBefore,
    enabled: !!dateAfter && !!dateBefore && !!teacherId
  })

  const { mutate } = useBulkCoverAndCancel({
    onSuccess: () => {
      toast({
        variant: 'success',
        title: t('toast.successfully-saved-changes')
      })
      router.history.back()
    }
  })

  const handleSave = (newLessons: BulkCoverAndCancelLeson[]) => {
    mutate({
      comment: comment || undefined,
      lessons: newLessons
    })
  }

  return (
    <BasicLayout
      header={t('header.cover-cancel-lesson')}
      contentClassName={styles.wrapper}
      moduleName={t('common:header.academics')}
    >
      <div className={styles.headerWrapper}>
        <div className={styles.filters}>
          <div>
            <Label id="teacher-select" label={t('label.teacher')} required />
            <Select
              id="teacher-select"
              loading={isTeachersLoading}
              options={teachers?.options || []}
              value={teacherId}
              placeholder={t('text.select-teacher')}
              onChange={value => {
                navigate({
                  replace: true,
                  search: previousValue => ({
                    ...previousValue,
                    teacherId: value
                  })
                })
              }}
            />
          </div>
          <div>
            <Label id="range-picker" label={t('label.date')} required />
            <DateRangePicker
              id="range-picker"
              value={{
                from: dateAfter ? new Date(dateAfter) : undefined,
                to: dateBefore ? new Date(dateBefore) : undefined
              }}
              onChange={value => {
                navigate({
                  replace: true,
                  search: previousValue => ({
                    ...previousValue,
                    dateAfter: formatApiDate(value?.from),
                    dateBefore: formatApiDate(value?.to)
                  })
                })
              }}
            />
          </div>
        </div>

        <div className={styles.commentWrapper}>
          <Label id="comment" label={t('label.comment')} />
          <Textarea
            id="comment"
            value={comment}
            onChange={setComment}
            placeholder={t('text.type-comment')}
            numberOfLines={6}
          />
        </div>
      </div>
      <AddCoverCancelContent
        lessons={lessons?.list}
        onSave={handleSave}
        teacherName={teachers?.getTeacherName(teacherId)}
      />
    </BasicLayout>
  )
}

type AddCoverCancelContentProps = {
  teacherName?: string
  lessons?: CoverCancelLesson[]
  onSave: (lessons: BulkCoverAndCancelLeson[]) => void
}

const AddCoverCancelContent = (props: AddCoverCancelContentProps) => {
  const { t } = useTranslation('coveredCancelledLessons')
  const router = useRouter()

  const { isTablet, isMobile, isDesktop } = useScreenResolution()
  const { dateAfter, dateBefore } = routeApi.useSearch()
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const [isSummaryOpen, setIsSummaryOpen] = useState(false)
  const [isFormValidated, setIsFormValidated] = useState(false)
  const [isFormSave, setIsFormSave] = useState(false)
  const [lessons, setLessons] = useState(props.lessons)

  useEffect(() => {
    setLessons(props.lessons)
  }, [props.lessons])

  const formIsDirty = !isEqual(props.lessons, lessons) && !isFormSave

  const {
    proceed,
    reset,
    status: blockerStatus
  } = useBlocker({
    condition: formIsDirty
  })

  const groupedLessons = groupBy(
    lessons?.map(lesson => ({
      ...lesson,
      date: formatDate(lesson.startDate)
    })),
    'date'
  )

  const handleLessonStatusChange = (status: LessonStatus, id: string) => {
    setIsFormValidated(false)

    const newData =
      status === 'cancel'
        ? {
            isCancelled: true,
            isCovered: false,
            teacherCover: undefined,
            coTeacherCover: undefined
          }
        : status === 'cover'
          ? {
              isCovered: true,
              isCancelled: false
            }
          : {
              isCancelled: false,
              isCovered: false,
              teacherCover: undefined,
              coTeacherCover: undefined
            }
    setLessons(prevItems =>
      prevItems?.map(item => (item.id === id ? { ...item, ...newData } : item))
    )
  }
  const handleTeacherChange = ({
    teacher,
    teacherType,
    lessonId
  }: {
    teacher?: IdAndName
    teacherType: 'teacherCover' | 'coTeacherCover'
    lessonId: string
  }) => {
    setIsFormValidated(true)
    setLessons(prevItems =>
      prevItems?.map(item =>
        item.id === lessonId ? { ...item, [teacherType]: teacher } : item
      )
    )
  }

  const { conflictsCount } = useConflicts({
    lessons,
    previousLessons: props.lessons
  })

  const confirmModalDescription =
    dateAfter && dateBefore
      ? `${props.teacherName}, ${formatDate(new Date(dateAfter))} - ${formatDate(new Date(dateBefore))}`
      : ''

  const handleCancel = () => {
    router.history.back()
  }

  const handleSave = () => {
    if (!props.teacherName || !dateAfter || !dateBefore) router.history.back()

    setIsFormValidated(true)

    const isValidForm = lessons?.every(lesson =>
      lesson.isCovered ? !!lesson.coTeacherCover || !!lesson.teacherCover : true
    )

    if (!isValidForm) return

    if (conflictsCount) setIsConfirmOpen(true)
    else saveForm()
  }

  const saveForm = () => {
    const newLessons =
      lessons?.map(lesson => ({
        id: lesson.id,
        is_cancelled: lesson.isCancelled,
        teacher_cover_id: lesson.teacherCover?.id || null,
        co_teacher_1_cover_id: lesson.coTeacherCover?.id || null
      })) || []
    setIsFormSave(true)
    props.onSave(newLessons)
    setIsConfirmOpen(false)
  }

  return (
    <div className={styles.container}>
      <div className={styles.lessonsContainer}>
        <p className={styles.lessonsHeader}>{t('header.lessons')}</p>
        <div className={styles.lessons}>
          {props.lessons ? (
            Object.entries(groupedLessons).map(([date, lessonsList]) => (
              <Collapse
                key={date}
                header={date}
                variant="secondary"
                defaultOpen
              >
                <div className={styles.lessonsWrapper}>
                  {lessonsList.map(lesson => (
                    <Lesson
                      key={lesson.id}
                      lesson={lesson}
                      onStatusChange={value =>
                        handleLessonStatusChange(value, lesson.id)
                      }
                      isFormValidated={isFormValidated}
                      onTeacherChange={value =>
                        handleTeacherChange({ ...value, lessonId: lesson.id })
                      }
                    />
                  ))}
                </div>
              </Collapse>
            ))
          ) : (
            <span className={styles.noData}>
              {t('text.select-teacher-and-dates')}
            </span>
          )}
        </div>
      </div>

      <div className={styles.summaryWrapper}>
        {isDesktop ? (
          <div className={styles.summary}>
            <Summary lessons={lessons} previousLessons={props.lessons} />
          </div>
        ) : null}

        <div className={styles.footer}>
          <div>
            {isTablet && !isMobile ? (
              <Drawer
                title={t('header.summary')}
                open={isSummaryOpen}
                onOpenChange={setIsSummaryOpen}
                trigger={
                  <Button variant="secondary">{t('button.summary')}</Button>
                }
                direction="right"
              >
                <Summary
                  lessons={lessons}
                  previousLessons={props.lessons}
                  hideHeader
                />
              </Drawer>
            ) : null}

            {isMobile ? (
              <Modal
                id="summary"
                title={t('header.summary')}
                open={isSummaryOpen}
                onOpenChange={setIsSummaryOpen}
                trigger={
                  <Button variant="secondary">{t('button.summary')}</Button>
                }
              >
                <Summary
                  lessons={lessons}
                  previousLessons={props.lessons}
                  hideHeader
                />
              </Modal>
            ) : null}
          </div>

          <div className={styles.buttonWrapper}>
            <Button variant="tertiary" onClick={handleCancel}>
              {t('button.cancel')}
            </Button>
            <Button onClick={handleSave}>{t('button.save')}</Button>
          </div>
        </div>
      </div>

      {blockerStatus === 'blocked' && (
        <DirtyModal isOpen onConfirm={proceed} onCancel={reset} />
      )}

      <ConfirmModal
        id="confirm-cover-cancel-lesson"
        header={t('header.save-changes')}
        description={confirmModalDescription}
        open={isConfirmOpen}
        onOpenChange={setIsConfirmOpen}
        alertText={t('text.conflicts-will-occur', { COUNT: conflictsCount })}
        confirmButton={
          <Button variant="danger" onClick={saveForm}>
            {t('button.confirm')}
          </Button>
        }
      />
    </div>
  )
}

export default AddCoverCancelLessonView
