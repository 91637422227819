import { useFeatureFlagEnabled } from 'posthog-js/react'

export type FeatureFlag = 'ARCHIVE_STUDENTS' | 'ARCHIVE_USERS'

export const useFeatureFlag = (featureName: FeatureFlag) => {
  const featureFlagFromPosthog = useFeatureFlagEnabled(featureName)

  return (
    (process.env.NODE_ENV === 'development' &&
      !import.meta.env.VITE_POSTHOG_KEY) ||
    featureFlagFromPosthog
  )
}
