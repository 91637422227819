import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { type UserProfile } from '@/types/user-profile'
import { type UserStatus } from '@/types/user-status'
import { getUserProfiles } from '@/utils/get-user-profiles'
import { requiredString, userProfile, userStatus } from '@/utils/zod'

const responseSchema = z.array(
  z.object({
    id: requiredString(),
    name: requiredString(),
    email: requiredString(),
    phone_number: z.string().optional(),
    profiles: z.array(userProfile),
    is_superuser: z.boolean(),
    is_support_staff: z.boolean(),
    status: userStatus.optional(),
    can_be_archived: z.boolean()
  })
)

export type User = {
  id: string
  name: string
  email: string
  phoneNumber?: string
  profiles: UserProfile[]
  isSuperUser: boolean
  isSupportStaff: boolean
  status?: UserStatus
  canBeArchived: boolean
}

const parseResponse = (response: z.infer<typeof responseSchema>): User[] =>
  response.map(item => ({
    id: item.id,
    name: item.name,
    email: item.email,
    phoneNumber: item.phone_number,
    profiles: getUserProfiles({
      isSuperUser: item.is_superuser,
      isSupportStaff: item.is_support_staff,
      profiles: item.profiles
    }),
    isSuperUser: item.is_superuser,
    isSupportStaff: item.is_support_staff,
    status: item.status,
    canBeArchived: item.can_be_archived
  }))

type Params = {
  id?: string[]
  profile?: UserProfile[]
  email?: string[]
  phoneNumber?: string[]
  status?: UserStatus[]
  pageSize?: number | 'max'
  page?: number
}

const useUsers = (params: Params = {}) =>
  useQuery({
    queryKey: ['userList', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const userList = await httpService.panel.panelUsersList({
        page: params.page,
        page_size: params.pageSize,
        id: params.id,
        email: params.email,
        phone_number: params.phoneNumber,
        profile: params.profile,
        status: params.status,
        fetchKeys: {
          id: true,
          name: true,
          profiles: true,
          email: true,
          phone_number: true,
          status: true,
          is_superuser: true,
          is_support_staff: true,
          can_be_archived: true
        }
      })

      const parsedUserList = parseResponse(
        responseSchema.parse(userList.results)
      )
      return {
        list: parsedUserList,
        count: userList.count
      }
    }
  })

export default useUsers
