import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import WarningCircleIcon from '@/assets/icons/warning-circle.svg?react'
import Alert from '@/components/Alert/Alert'
import Button from '@/components/Button/Button'
import Input from '@/components/Input/Input'
import { BaseModal } from '@/components/Modal/Modal'
import Pagination from '@/components/Pagination/Pagination'
import Table, { type Column } from '@/components/Table/Table'
import DirtyModal from '@/components/common/DirtyModal'
import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'
import { StickyProvider } from '@/contexts/StickyContext'
import useRowSelection from '@/hooks/useRowSelection'

import styles from './AddStudentModal.module.scss'
import useStudentsClassForm from '../../queries/useStudentsClassForm'
import useStudentsClassFormCount from '../../queries/useStudentsClassFormCount'

type Payload = {
  studentsIds: string[]
}

type AddStudentModalProps = {
  open: boolean
  classId?: string
  onOpenChange: (open: boolean) => void
  selectedStudentsIds: string[]
  academicLevelId: string
  academicLevelName: string
  onSubmit: (data: Payload) => void
}

const AddStudentModal = (props: AddStudentModalProps) => {
  const { t } = useTranslation('classes')

  const [studentsSearch, setStudentsSearch] = useState('')
  const [page, setPage] = useState(1)
  const [studentsValidationError, setStudentsValidationError] = useState(false)
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)

  const { data: students, isLoading } = useStudentsClassForm({
    academicLevelId: [props.academicLevelId],
    classId: props.classId ? ['unassigned', props.classId] : ['unassigned'],
    pageSize: DEFAULT_PAGE_SIZE,
    page,
    search: studentsSearch
  })

  const { data: studentsCount } = useStudentsClassFormCount({
    academicLevelId: [props.academicLevelId],
    classId: props.classId ? ['unassigned', props.classId] : ['unassigned'],
    search: studentsSearch
  })

  const count = studentsCount?.count || 0

  const selection = useRowSelection({
    count,
    itemsToSelect: studentsCount?.ids || []
  })

  useEffect(() => {
    if (props.open) selection.setSelection(props.selectedStudentsIds)
    // Can't add selection do deps array because it breaks selection logic
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedStudentsIds, props.open])

  const columns: Column<NonNullable<typeof students>[number]>[] = [
    {
      title: t('header.name'),
      key: 'fullName',
      dataIndex: 'fullName'
    },
    {
      key: 'ahvNumber',
      title: t('header.ahv-number'),
      dataIndex: 'ahvNumber'
    }
  ]

  const handleClose = () => {
    props.onOpenChange(false)
    selection.clearSelection()
    setStudentsValidationError(false)
    setPage(1)
    setStudentsSearch('')
  }

  const handleSearchInputChange = (value: string) => {
    setPage(1)
    setStudentsSearch(value)
  }

  const validateForm = ({ studentsIds }: Payload) => {
    if (studentsIds.length === 0) {
      setStudentsValidationError(true)
      return false
    }

    return true
  }

  const onSubmit = () => {
    const data = selection.selectedItems
    const isValid = validateForm({ studentsIds: data })
    if (!isValid) return

    props.onSubmit({ studentsIds: data })
    handleClose()
  }

  const handleOnCancel = () => {
    isEqual(selection.selectedItems, props.selectedStudentsIds)
      ? handleClose()
      : setIsOpenConfirmModal(true)
  }

  return (
    <StickyProvider id="add-student-modal">
      <BaseModal
        id="add-student-modal"
        contentClassName={styles.modal}
        title={t('header.add-students')}
        description={t('header.academic-level-name', {
          LEVEL: props.academicLevelName
        })}
        open={props.open}
        onClose={handleOnCancel}
      >
        <div className={styles.content}>
          <Alert
            message={t('text.only-students-not-assigned-displayed')}
            withoutBackground
          />
          <Input
            id="student-search-input"
            value={studentsSearch}
            onChange={handleSearchInputChange}
            placeholder={t('placeholder.type-student-name')}
            type="search"
          />
          <div className={styles.table}>
            <Table
              id="add-students-table"
              columns={columns}
              isLoading={isLoading}
              data={students || []}
              count={count}
              rightTopBar={
                <div className={styles.topBar}>
                  {studentsValidationError ? (
                    <div className={styles.errorContainer}>
                      <WarningCircleIcon className={styles.errorIcon} />
                      {t('error.select-at-least-one-student')}
                    </div>
                  ) : null}
                </div>
              }
              rowSelection={{
                ...selection,
                count,
                allCheckboxValue: selection.getSelectAllCheckboxValue(),
                labelHeader: t('label.select-all-students'),
                labelItem: row =>
                  t('label.select-student', { STUDENT: row.fullName })
              }}
            />
          </div>
          <div className={styles.pagination}>
            <Pagination
              count={count}
              id="add-students-table-pagination"
              page={page}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={setPage}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <Button variant="tertiary" onClick={handleOnCancel}>
            {t('button.cancel', { ns: 'common' })}
          </Button>
          <Button onClick={onSubmit}>
            {t('button.save', { ns: 'common' })}
          </Button>
        </div>
      </BaseModal>
      <DirtyModal
        isOpen={isOpenConfirmModal}
        onOpenChange={setIsOpenConfirmModal}
        onConfirm={() => {
          props.onOpenChange(false)
          setIsOpenConfirmModal(false)
        }}
      />
    </StickyProvider>
  )
}

export default AddStudentModal
