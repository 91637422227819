import { z } from 'zod'

import { labelAndValue } from '@/utils/zod'

export const timetableFiltersSchema = z.object({
  dateFrom: z.string().catch(''),
  dateTo: z.string().catch(''),
  student: labelAndValue().optional(),
  teacher: labelAndValue().optional(),
  course: labelAndValue().optional(),
  classId: labelAndValue().optional(),
  room: labelAndValue().optional()
})

export type TimetableFiltersKey = keyof z.infer<typeof timetableFiltersSchema>
