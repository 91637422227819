// copied from StudentGuarianDetailsForm.tsx because field paths are different
import type { ReactNode } from 'react'
import { type UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import FormField from '@/components/FormField'
import Input from '@/components/Input/Input'
import InputPhoneNumber from '@/components/InputPhoneNumber/InputPhoneNumber'
import Select from '@/components/Select/Select'
import SendActivationLinkFormField from '@/components/common/ActivationLinkFormField/SendActivationLinkFormField'
import { type FormFieldType } from '@/types/form-field-type'
import { validateEmailUniqueness } from '@/utils/validate-email-uniqueness'

import { ParentEmailOption } from './ParentEmailOption'
import styles from './StudentGuardianDetailsForm.module.scss'
import type { GuardianForm } from '../constants/student-payload'
import useStudentParents from '../queries/useStudentParents'

type GuardianFormProps = {
  id: string
  form: UseFormReturn<GuardianForm>
  children?: ReactNode
  emailsToExclude?: string[]
}

export const GuardianDetailsForm = (props: GuardianFormProps) => {
  const { t } = useTranslation('students')
  const { data: parents, isLoading: isParentsLoading } = useStudentParents()

  const setParent = (parent: {
    id?: string
    email: string
    phoneNumber?: string
    firstName: string
    lastName: string
  }) => {
    props.form.resetField('email')
    props.form.resetField('firstName')
    props.form.resetField('lastName')
    props.form.setValue('email', parent.email, { shouldDirty: true })
    props.form.setValue('firstName', parent.firstName)
    props.form.setValue('lastName', parent.lastName)
    props.form.setValue('phoneNumber', parent.phoneNumber)
    props.form.setValue('id', parent.id)
  }

  const disabledForm = !!props.form.getValues('id')

  const handleChangeParentEmail = (email: string) => {
    const selectedParent = parents?.list.find(parent => parent.email === email)

    if (selectedParent) {
      setParent(selectedParent)
    } else {
      setParent({
        email,
        id: '',
        firstName: '',
        lastName: '',
        phoneNumber: ''
      })
    }
  }

  const options = (() => {
    if (!parents?.options) return []
    if (!props.emailsToExclude) return parents.options
    return parents.options.filter(
      ({ value }) => !props.emailsToExclude?.includes(value)
    )
  })()

  const handleBlurEmail = (inputProps: FormFieldType<string>) => {
    inputProps.onBlur?.()

    setTimeout(() => {
      if (props.emailsToExclude?.includes(props.form.getValues('email'))) {
        props.form.setError('email', {
          message: t('help.email-taken', { ns: 'common' })
        })

        return
      } else if (!props.form.getValues('id')) {
        validateEmailUniqueness({
          field: 'email',
          form: props.form
        })
      }
    }, 0)
  }

  return (
    <>
      <FormField
        control={props.form.control}
        id={`guardian-email-${props.id}`}
        required
        label={t('label.email')}
        name="email"
        render={({ inputProps }) => (
          <Select
            {...inputProps}
            creatable
            placeholder={t('placeholder.type-email')}
            loading={isParentsLoading}
            options={options}
            clearable
            onClear={() => {
              props.form.resetField('email')
            }}
            onBlur={() => handleBlurEmail(inputProps)}
            onChange={handleChangeParentEmail}
            formatOption={(option, context) => (
              <ParentEmailOption
                option={option}
                parents={parents?.list || []}
                optionContext={context}
              />
            )}
          />
        )}
      />
      <FormField
        control={props.form.control}
        id={`guardian-phone-${props.id}`}
        label={t('label.phone-number')}
        name="phoneNumber"
        render={({ inputProps }) => (
          <InputPhoneNumber {...inputProps} disabled={disabledForm} />
        )}
      />
      <FormField
        control={props.form.control}
        id={`guardian-first-name-${props.id}`}
        required
        label={t('label.first-name')}
        name="firstName"
        render={({ inputProps }) => (
          <Input
            {...inputProps}
            placeholder={t('placeholder.type-first-name')}
            disabled={disabledForm}
          />
        )}
      />
      <FormField
        control={props.form.control}
        id={`guardian-last-name-${props.id}`}
        required
        label={t('label.last-name')}
        name="lastName"
        render={({ inputProps }) => (
          <Input
            {...inputProps}
            placeholder={t('placeholder.type-last-name')}
            disabled={disabledForm}
          />
        )}
      />
      <div className={styles.guardianActions}>
        <SendActivationLinkFormField
          control={props.form.control}
          name="sendActivationLink"
          id={`guardian-send-activation-link-${props.id}`}
        />
        {props.children}
      </div>
    </>
  )
}

export default GuardianDetailsForm
