import LinkText from '@/components/LinkText/LinkText'
import { type Column } from '@/components/Table/Table'
import { TABLE_CELL_WIDTH } from '@/components/Table/table-cell-width'
import Tag from '@/components/Tag/Tag'
import { useScreenResolution } from '@/hooks/useScreenResolution'
import i18n from '@/i18n'
import { type Comment } from '@/queries/useComments'
import type { CommentType } from '@/types/comment-type'
import { formatDate } from '@/utils/format-date'
import { getColumnWidth } from '@/utils/get-column-width'
import { getCommentTypeTag } from '@/utils/get-comment-type-tag'

const useCommonCommentsColumn = () => {
  const { isMobile, isExtraLarge } = useScreenResolution()

  const commonCommentsColumns: Column<Comment>[] = [
    {
      key: 'added-on',
      dataIndex: 'addedOn',
      title: i18n.t('header.date', { ns: 'common' }),
      width: getColumnWidth(isExtraLarge, TABLE_CELL_WIDTH.MD),
      render: (value: Date) => formatDate(value)
    },
    {
      key: 'added-by',
      title: i18n.t('header.added-by', { ns: 'common' }),
      width: isMobile ? TABLE_CELL_WIDTH.XL : undefined,
      render: (value: Comment) => (
        <LinkText
          to="/users/$userId/details"
          params={{
            userId: value.addedBy.id
          }}
        >
          {value.addedBy.name}
        </LinkText>
      )
    },
    {
      key: 'type',
      title: i18n.t('header.type', { ns: 'common' }),
      dataIndex: 'commentType',
      width: 100,
      render: (value: CommentType) => <Tag {...getCommentTypeTag(value)} />
    }
  ]

  return { commonCommentsColumns }
}
export default useCommonCommentsColumn
