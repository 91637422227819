import type { UseFormReturn } from 'react-hook-form'

import { httpService } from '@/api/http.service'
import i18n from '@/i18n'

import { type ClassFormPayload } from '../constants/class-payload'

export const validateNameUniqueness = async ({
  form,
  initialValue,
  classId
}: {
  form: UseFormReturn<ClassFormPayload>
  initialValue?: string
  classId?: string
}) => {
  form.trigger('name')
  const name = form.getValues('name')
  const academicLevel = form.getValues('academicLevel')

  if (!name || name === initialValue || !academicLevel) return true

  const response = await httpService.panel.panelClassesCheckNameTakenCreate({
    academic_level: academicLevel,
    name,
    excluded_class: classId
  })

  if (response.is_taken) {
    form.setError('name', {
      message: i18n.t('help.name-taken', { ns: 'common' })
    })
    return false
  }

  return true
}
