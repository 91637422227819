import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { type CommentType } from '@/types/comment-type'
import { requiredString, date, commentType } from '@/utils/zod'

import type { IdAndName } from '../types/id-and-name'
import { formatApiDate } from '../utils/format-date'

const responseSchema = z.array(
  z.object({
    id: requiredString(),
    student: z.object({
      id: requiredString(),
      name: requiredString()
    }),
    date: date(),
    added_by: z.object({
      id: requiredString(),
      name: requiredString()
    }),
    comment_type: commentType,
    content: requiredString(),
    group: z
      .object({
        id: requiredString(),
        name: requiredString(),
        course: z.object({
          id: requiredString(),
          name: requiredString()
        })
      })
      .nullable()
  })
)

export type Comment = {
  id: string
  student: IdAndName
  addedOn: Date
  addedBy: IdAndName
  commentType: CommentType
  content: string
  group?: {
    id: string
    name: string
    course: IdAndName
  }
}

const parseResponse = (response: z.infer<typeof responseSchema>): Comment[] =>
  response.map(comment => ({
    id: comment.id,
    student: comment.student,
    addedOn: comment.date,
    addedBy: comment.added_by,
    commentType: comment.comment_type,
    content: comment.content,
    group: comment.group ?? undefined
  }))

type Params = {
  semester: string
  groupId?: string
  classId?: string
  addedById?: string[]
  coursesId?: string[]
  commentType?: CommentType[]
  studentsId?: string[]
  dateAfter?: string
  dateBefore?: string
  pageSize?: number
  page?: number
}

const useComments = (params: Params) =>
  useQuery({
    queryKey: ['panelCommentsList', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const commentsResponse = await httpService.panel.panelCommentsList({
        added_by_id: params.addedById,
        comment_type: params.commentType,
        date_after: params.dateAfter
          ? formatApiDate(new Date(params.dateAfter))
          : undefined,
        date_before: params.dateBefore
          ? formatApiDate(new Date(params.dateBefore))
          : undefined,
        group_id: params.groupId,
        class_id: params.classId,
        course_id: params.coursesId,
        student_id: params.studentsId,
        semester_id: params.semester,
        page_size: params.pageSize,
        page: params.page,
        fetchKeys: {
          id: true,
          student: true,
          date: true,
          added_by: true,
          comment_type: true,
          content: true,
          group: true
        }
      })

      const parsedComments = parseResponse(
        responseSchema.parse(commentsResponse.results)
      )

      return {
        list: parsedComments,
        count: commentsResponse.count
      }
    }
  })

export default useComments
