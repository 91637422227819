import { useNavigate, useLocation } from '@tanstack/react-router'
import { changeLanguage } from 'i18next'
import { usePostHog } from 'posthog-js/react'
import { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CheckIcon from '@/assets/icons/check.svg?react'
import LeftArrowIcon from '@/assets/icons/chevron-small-left.svg?react'
import RightArrowIcon from '@/assets/icons/chevron-small-right.svg?react'
import LogoutIcon from '@/assets/icons/logout.svg?react'
import Dropdown, { DropdownMenuItem } from '@/components/Dropdown/Dropdown'
import { useScreenResolution } from '@/hooks/useScreenResolution'
import { toast } from '@/hooks/useToast'
import i18n from '@/i18n'
import useChangeLanguage from '@/mutations/useChangeLanguage'
import queryClient from '@/queryClient'
import useAuthStore from '@/store/useAuthStore'
import type { Language } from '@/types/lanuage'
import {
  getLanguageName,
  setLocalStorageLanguage
} from '@/utils/change-language'
import { language } from '@/utils/zod'

import styles from './NavigationSettings.module.scss'

const languageOptions = [
  {
    name: i18n.t('label.german', { ns: 'common' }),
    value: 'de'
  },
  {
    name: i18n.t('label.english', { ns: 'common' }),
    value: 'en'
  }
] as const

type NavigationSettingsProps = {
  trigger: React.ReactNode
}

const getLanguageFromLocalStorage = () =>
  language.parse(window.localStorage.getItem('language'))

const NavigationSettings = forwardRef<HTMLDivElement, NavigationSettingsProps>(
  (props, ref) => {
    const { user, logOut } = useAuthStore()
    const { isDesktop } = useScreenResolution()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { t } = useTranslation('common')

    const posthog = usePostHog()

    const [activeLanguage, setActiveLanguage] = useState(
      getLanguageFromLocalStorage
    )

    const [languagePickerOpen, setLanguagePickerOpen] = useState(false)

    const { mutate: changeLanguageMutation } = useChangeLanguage({
      onSuccess: data => {
        toast({
          variant: 'success',
          title: t('notification.successfully-changed-language', {
            LANGUAGE: getLanguageName(data.language)
          })
        })

        setLocalStorageLanguage(data.language)
        revalidateQuery()
      }
    })

    const revalidateQuery = () => {
      queryClient.invalidateQueries({
        queryKey: ['panelAbsencesOptionsStatusList']
      })
      queryClient.invalidateQueries({
        queryKey: ['panelStudentsOptionsStatusesList']
      })
      queryClient.invalidateQueries({
        queryKey: ['panelUsersOptionsStatusesList']
      })
      queryClient.invalidateQueries({
        queryKey: ['panelCommentsOptionsCommentTypeList']
      })
    }

    const handleLanguageChange = (lang: Language) => {
      changeLanguage(lang)
      changeLanguageMutation({ language: lang })

      setActiveLanguage(lang)
      setLanguagePickerOpen(false)
    }

    const handleLogout = () => {
      logOut()
      posthog.reset()
      navigate({
        to: '/login',
        search: {
          redirect: pathname
        }
      })
    }

    const handleBackClick = (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault()
      setLanguagePickerOpen(false)
    }

    const handleOpenLanguagePicker = (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault()
      setLanguagePickerOpen(true)
    }

    const sideOffsetForScreen = isDesktop ? 10 : 12

    return (
      <Dropdown
        sideOffset={sideOffsetForScreen}
        align="center"
        variant="inverted"
        className={styles.userProfileDropdown}
        onOpenChange={() => setLanguagePickerOpen(false)}
        trigger={props.trigger}
      >
        <div ref={ref}>
          {languagePickerOpen ? (
            <UserProfileLanguageDropdown
              activeLanguage={activeLanguage}
              handleLanguageChange={handleLanguageChange}
              handleBackClick={handleBackClick}
            />
          ) : (
            <UserProfileSettings
              email={user?.email}
              fullName={user?.fullName}
              handleLogout={handleLogout}
              handleOpenLanguagePicker={handleOpenLanguagePicker}
            />
          )}
        </div>
      </Dropdown>
    )
  }
)

NavigationSettings.displayName = 'NavigationSettings'

type UserProfileSettings = {
  fullName: string | undefined
  email: string | undefined
  handleOpenLanguagePicker: (e: React.MouseEvent<HTMLDivElement>) => void
  handleLogout: () => void
}
const UserProfileSettings = ({
  fullName,
  email,
  handleLogout,
  handleOpenLanguagePicker
}: UserProfileSettings) => {
  const { t } = useTranslation('common')

  return (
    <>
      <div className={styles.userInfo}>
        <p className={styles.name}>{fullName}</p>
        <p className={styles.email}>{email}</p>
      </div>
      <DropdownMenuItem
        onClick={handleOpenLanguagePicker}
        className={styles.userInfoItem}
      >
        {t('label.language')}
        <RightArrowIcon className={styles.icon} />
      </DropdownMenuItem>
      <DropdownMenuItem onClick={handleLogout} className={styles.userInfoItem}>
        {t('label.logout')}
        <LogoutIcon className={styles.icon} />
      </DropdownMenuItem>
    </>
  )
}

type UserProfileLanguageDropdownProps = {
  activeLanguage: Language | null
  handleLanguageChange: (lang: Language) => void
  handleBackClick: (e: React.MouseEvent<HTMLDivElement>) => void
}

const UserProfileLanguageDropdown = ({
  activeLanguage,
  handleLanguageChange,
  handleBackClick
}: UserProfileLanguageDropdownProps) => {
  const { t } = useTranslation('common')

  const isActiveLanguage = (lang: Language) => lang === activeLanguage

  return (
    <>
      <DropdownMenuItem
        onClick={handleBackClick}
        className={styles.languageBackButton}
      >
        <LeftArrowIcon className={styles.icon} />
        {t('label.language')}
      </DropdownMenuItem>
      {languageOptions.map(lang => (
        <DropdownMenuItem
          key={lang.value}
          onClick={() => handleLanguageChange(lang.value)}
          variant={isActiveLanguage(lang.value) ? 'active' : 'neutral'}
        >
          {lang.name}
          {isActiveLanguage(lang.value) ? (
            <CheckIcon className={styles.icon} />
          ) : null}
        </DropdownMenuItem>
      ))}
    </>
  )
}

export default NavigationSettings
