import { getRouteApi, useNavigate } from '@tanstack/react-router'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import DeleteIcon from '@/assets/icons/delete.svg?react'
import EditIcon from '@/assets/icons/edit.svg?react'
import { type Route } from '@/components/Tabs/Tabs'
import type { Action } from '@/components/common/BasicLayout/Actions'
import BasicLayout from '@/components/common/BasicLayout/BasicLayout'
import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'
import { toast } from '@/hooks/useToast'
import useSemesters from '@/queries/useSemesters'
import useAuthStore from '@/store/useAuthStore'
import type { RouteWithHasPermission } from '@/types/route'

import RemoveClassModal from '../components/RemoveClassModal'
import useClass from '../queries/useClass'

const routeApi = getRouteApi('/_auth/students-and-classes/classes/$classId')

const ClassView = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation(['classes'])
  const navigate = useNavigate()

  const { classId } = routeApi.useParams()

  const { data: semesters } = useSemesters()

  const { data: classData } = useClass(classId)

  const { user } = useAuthStore()

  const [openDeleteClassConfirmModal, setOpenDeleteClassConfirmModal] =
    useState(false)

  const isAdminOrClassTutor =
    !!user?.isSuperAdmin || user?.id === classData?.tutor?.id

  const routes: RouteWithHasPermission[] = [
    {
      title: t('navigation.details'),
      link: {
        to: '/students-and-classes/classes/$classId/details'
      },
      hasPermission: true
    },
    {
      title: t('navigation.courses-and-groups'),
      link: {
        to: '/students-and-classes/classes/$classId/courses-and-groups',
        search: {
          semester: semesters?.currentSemester?.id || semesters?.list[0].id
        }
      },
      hasPermission: isAdminOrClassTutor
    },
    {
      title: t('navigation.grades'),
      link: {
        to: '/students-and-classes/classes/$classId/grades',
        search: {
          semesterId: semesters?.currentSemester?.id || semesters?.list[0].id
        }
      },
      hasPermission: isAdminOrClassTutor
    },
    {
      title: t('navigation.comments'),
      link: {
        to: '/students-and-classes/classes/$classId/comments',
        search: {
          semester: semesters?.currentSemester?.id || semesters?.list[0].id
        }
      },
      hasPermission: true
    }
  ]

  const routesWithPermission: Route[] = routes
    .filter(route => route.hasPermission)
    .map(route => ({
      title: route.title,
      link: route.link
    }))

  const actions: Action[] = [
    ...(user?.isSuperAdmin
      ? [
          {
            text: t('button.edit'),
            icon: <EditIcon />,
            onClick: () =>
              navigate({
                to: '/students-and-classes/classes/edit/$classId',
                params: {
                  classId
                }
              })
          },
          {
            text: t('button.remove-class'),
            variantAction: 'danger' as const,
            icon: <DeleteIcon />,
            onClick: () => setOpenDeleteClassConfirmModal(true)
          }
        ]
      : [])
  ]

  const handleRemoveSuccess = () => {
    toast({
      variant: 'success',
      title: t('toast.class-remove-success', {
        CLASS: classData?.displayName
      })
    })
    navigate({
      to: '/students-and-classes/classes',
      search: {
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE
      }
    })
  }

  const handleRemoveError = () =>
    toast({
      variant: 'error',
      title: t('toast.class-remove-error', {
        CLASS: classData?.displayName
      })
    })

  return (
    <BasicLayout
      header={classData?.displayName}
      subheader={t('header.class-details')}
      routes={routesWithPermission}
      moduleName={t('header.academics', { ns: 'common' })}
      actions={actions}
      actionsAsDropdown
    >
      <>
        {children}
        <RemoveClassModal
          open={openDeleteClassConfirmModal}
          onOpenChange={setOpenDeleteClassConfirmModal}
          classId={classId}
          onRemoveSuccess={handleRemoveSuccess}
          onRemoveError={handleRemoveError}
        />
      </>
    </BasicLayout>
  )
}
export default ClassView
