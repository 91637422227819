import { useRouterState, type LinkProps } from '@tanstack/react-router'
import clsx from 'clsx'
import React from 'react'

import Breadcrumbs, {
  type BreadcrumbItem
} from '@/components/Breadcrumbs/Breadcrumbs'
import Tabs, { type Route } from '@/components/Tabs/Tabs'
import Header, { type HeaderVariant } from '@/components/common/Header/Header'
import { StickyProvider } from '@/contexts/StickyContext'
import type { routeTree } from '@/routeTree.gen'

import Actions, { type Action } from './Actions'
import styles from './BasicLayout.module.scss'

export type HeaderAction = Action

type BasicLayoutProps = {
  routes?: Route[]
  actions?: HeaderAction[]
  moduleName?: string
  actionsAsDropdown?: boolean
  header: React.ReactNode
  subheader?: React.ReactNode
  children: React.ReactNode
  contentClassName?: string
  headerVariant?: HeaderVariant
}

const BasicLayout = (props: BasicLayoutProps) => {
  const breadcrumbs: BreadcrumbItem[] = useRouterState({
    select: state =>
      state.matches
        .map(match => ({
          title:
            match.meta?.find(metaElement => metaElement.title)?.title || '',
          to: match.pathname as LinkProps<typeof routeTree>['to'],
          params: match.params
        }))
        .filter(breadcrumb => breadcrumb.to !== '/' && breadcrumb.title)
  })

  return (
    <div className={styles.page}>
      <StickyProvider id="main">
        <Header
          variant={props.headerVariant}
          navbar={
            <div className={styles.navbar}>
              <Breadcrumbs items={breadcrumbs} className={styles.breadcrumbs} />
              <span className={styles.moduleName}>{props.moduleName}</span>
            </div>
          }
          tabs={props.routes ? <Tabs routes={props.routes} /> : null}
        >
          <div className={styles.headerWrapper}>
            {props.subheader ? (
              <p className={styles.subheader}>{props.subheader}</p>
            ) : null}
            {typeof props.header === 'string' ? (
              <h1 className={styles.header}>{props.header}</h1>
            ) : (
              props.header
            )}

            <Actions
              actions={props.actions?.filter(action => !action.hidden)}
              actionsAsDropdown={props.actionsAsDropdown}
            />
          </div>
        </Header>

        <main className={clsx(styles.content, props.contentClassName)}>
          {props.children}
        </main>
      </StickyProvider>
    </div>
  )
}

export default BasicLayout
