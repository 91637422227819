import { createContext, useContext } from 'react'

export const StickyContext = createContext<string | null>(null)

export const useStickyContext = () => {
  const context = useContext(StickyContext)
  if (context === null) {
    throw new Error('useStickyContext must be used within a StickyProvider')
  }
  return context
}

export const StickyProvider = ({
  children,
  id
}: {
  children: React.ReactNode
  id: string
}) => <StickyContext.Provider value={id}>{children}</StickyContext.Provider>
