import { useEffect, useRef, useState } from 'react'

import type { ToastProps } from '@/components/Toast/Toast'

type State = {
  toasts: ToastProps[]
}

type Toast = Omit<ToastProps, 'id'>

const TOAST_LIMIT = 3
let count = 0

function generateId() {
  count = (count + 1) % Number.MAX_SAFE_INTEGER
  return count.toString()
}

const listeners: ((state: State) => void)[] = []
let memoryState: State = { toasts: [] }

function toast(props: Toast) {
  const toastObject: ToastProps = {
    ...props,
    id: generateId()
  }

  memoryState = {
    ...memoryState,
    toasts: [toastObject, ...memoryState.toasts].slice(0, TOAST_LIMIT)
  }

  listeners.forEach(listener => {
    listener(memoryState)
  })

  return () => {
    memoryState = {
      toasts: memoryState.toasts.filter(({ id }) => id !== toastObject.id)
    }

    listeners.forEach(listener => {
      listener(memoryState)
    })
  }
}

function useToasts() {
  const [state, setState] = useState<State>(memoryState)

  useEffect(() => {
    listeners.push(setState)

    return () => {
      const index = listeners.indexOf(setState)
      if (index > -1) {
        listeners.splice(index, 1)
      }
    }
  }, [state])

  return {
    ...state,
    toast
  }
}

function useToast(props: Omit<Toast, 'duration' | 'id' | 'withClose'>) {
  const closeFn = useRef(() => {})
  const isOpen = useRef(false)
  return {
    open: () => {
      if (isOpen.current) return
      isOpen.current = true
      closeFn.current = toast({ ...props, duration: Infinity, withClose: true })
    },
    close: () => {
      isOpen.current = false
      closeFn.current()
    }
  }
}

export { useToasts, toast, useToast }
