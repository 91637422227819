import { type TagColor } from '@/components/Tag/Tag'
import i18n from '@/i18n'
import { type UserStatus } from '@/types/user-status'

export const getUserStatusTag = (
  value: UserStatus
): { color: TagColor; label: string } => {
  switch (value) {
    case 'active':
      return { color: 'green', label: i18n.t('label.active', { ns: 'common' }) }
    case 'inactive':
      return {
        color: 'blue',
        label: i18n.t('label.inactive', { ns: 'common' })
      }
    case 'blocked':
      return { color: 'red', label: i18n.t('label.blocked', { ns: 'common' }) }
    case 'archived':
      return {
        color: 'gray',
        label: i18n.t('label.archived', { ns: 'common' })
      }
  }
}
