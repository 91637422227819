import { takeRight } from 'lodash'
import { useState } from 'react'

import LinkText from '@/components/LinkText/LinkText'
import type { Column } from '@/components/Table/Table'
import { TABLE_CELL_WIDTH } from '@/components/Table/table-cell-width'
import Tag from '@/components/Tag/Tag'
import Tooltip from '@/components/Tooltip/Tooltip'
import TruncateElementsList from '@/components/common/TruncateElementsList'
import type { IdAndName } from '@/types/id-and-name'
import type { CoveredOrCancelledLesson } from '@/types/lesson'

import styles from './TruncateColumn.module.scss'

type TruncateColumnProps = {
  list: IdAndName[]
  renderElement: (value: IdAndName) => React.ReactNode
}

export const TruncateColumn = (props: TruncateColumnProps) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const namesList = props.list.map(item => item.name)

  return (
    <TruncateElementsList
      expanded={isExpanded}
      onExpandedChange={setIsExpanded}
      elipsis={hiddenItemsCount => (
        <Tooltip
          text={takeRight(namesList, hiddenItemsCount).join(', ')}
          trigger={
            <Tag
              color="white"
              variant="with-background"
              label={`+${hiddenItemsCount}`}
              onClick={() => {
                setIsExpanded(true)
              }}
            />
          }
        />
      )}
    >
      {props.list.map(item => props.renderElement(item))}
    </TruncateElementsList>
  )
}

type GetTruncateColumnOptions = {
  getTeachers: (
    lesson: CoveredOrCancelledLesson
  ) => (CoveredOrCancelledLesson['teacher'] | undefined)[]
  header: string
  key: string
  isMobile: boolean
}

export const getTeachersColumn = ({
  getTeachers,
  header,
  key,
  isMobile
}: GetTruncateColumnOptions): Column<CoveredOrCancelledLesson> => ({
  key,
  title: <span className={styles.column}>{header}</span>,
  width: isMobile ? TABLE_CELL_WIDTH.XL : undefined,
  render: lesson => (
    <TruncateColumn
      list={getTeachers(lesson).filter(item => !!item)}
      renderElement={item => (
        <LinkText
          key={item.id}
          to="/users/$userId/details"
          className={styles.truncateElement}
          params={{
            userId: item.id
          }}
          truncateElement
        >
          {item.name}
        </LinkText>
      )}
    />
  )
})
