import { isAfter, isMatch, parse } from 'date-fns'
import { isValidPhoneNumber } from 'libphonenumber-js/max'
import { z, type ZodTypeAny } from 'zod'

import i18n from '@/i18n'

import type { Time } from '../types/time'

export const requiredString = () =>
  z
    .string()
    .min(1, { message: i18n.t('error.required-field', { ns: 'common' }) })

export const requiredStringWithLabel = (label: string) =>
  z
    .string()
    .trim()
    .min(1, {
      message: i18n.t('error.required-field-with-label', {
        ns: 'common',
        LABEL: label
      })
    })

export const requiredArrayWithLabel = (label: string) =>
  z.array(z.string()).min(1, {
    message: i18n.t('error.required-field-with-label', {
      ns: 'common',
      LABEL: label
    })
  })

export const maxLength = (maxLenght: number) =>
  z.string().max(maxLenght, {
    message: i18n.t('error.max-lenght', { ns: 'common', NUMBER: maxLenght })
  })

export const email = (label: string) =>
  requiredStringWithLabel(label).email({
    message: i18n.t('error.incorrect-email-format', { ns: 'common' })
  })

export const date = () => z.coerce.date()

export const gender = () => z.union([z.literal('male'), z.literal('female')])

export const labelAndValue = <
  T extends z.ZodString | z.ZodNumber = z.ZodString
>(
  valueSchema?: T
) =>
  z.object(
    {
      label: z.string(),
      value: (valueSchema || requiredString()) as T
    },
    { message: i18n.t('error.required-field', { ns: 'common' }) }
  )

export const arrayOfOptions = z.array(labelAndValue())

export const userStatus = z.union([
  z.literal('active'),
  z.literal('inactive'),
  z.literal('blocked'),
  z.literal('archived')
])

export const teacherStatus = z.union([
  z.literal('busy'),
  z.literal('available'),
  z.literal('unavailable')
])

export const absenceStatus = z.union([
  z.literal('excused'),
  z.literal('unexcused')
])

export const attendanceStatus = z.union([
  z.literal('present'),
  z.literal('late'),
  z.literal('absent_excused'),
  z.literal('absent_unexcused'),
  z.literal('')
])

export const userProfile = z.union([
  z.literal('Superuser'),
  z.literal('Teacher'),
  z.literal('Tutor'),
  z.literal('Support')
])

export const userProfiles = z.array(userProfile)

export const phone = () =>
  z.string().refine(value => !value || isValidPhoneNumber(value), {
    message: i18n.t('error.incorrect-phone-format', { ns: 'common' })
  })

export const language = z.union([
  z.literal('de'),
  z.literal('en'),
  z.literal('fr')
])

export const commentType = z.union([
  z.literal('neutral'),
  z.literal('positive'),
  z.literal('negative')
])

export const time = () => z.custom<Time>(value => isMatch(value, 'HH:mm'))

export const timeRange = (message: string) =>
  z
    .object({ from: time().optional(), to: time().optional() })
    .refine(({ from, to }) => from && to, { message })
    .refine(
      range => {
        if (!range.from || !range.to) return false
        const from = parse(range.from, 'HH:mm', new Date())
        const to = parse(range.to, 'HH:mm', new Date())
        return isAfter(to, from)
      },
      {
        message: i18n.t('error.end-earlier-than-start', {
          ns: 'common'
        })
      }
    )

export const paginatedResults = <T extends ZodTypeAny>(result: T) =>
  z.object({
    count: z.number(),
    next: z.string().nullable(),
    previous: z.string().nullable(),
    results: z.array(result),
    page_size: z.number(),
    current: z.number()
  })
