import clsx from 'clsx'
import React, { useRef } from 'react'

import useStickyElement, { PRIORITY } from '@/hooks/useStickyElement'

import styles from './Header.module.scss'

export type HeaderVariant = 'default' | 'gray'

type HeaderProps = {
  navbar: React.ReactNode
  children: React.ReactNode
  tabs: React.ReactNode
  variant?: HeaderVariant
}

const Header = (props: HeaderProps) => {
  const navRef = useRef<HTMLDivElement>(null)
  const footerRef = useRef<HTMLDivElement>(null)
  const { variant = 'default' } = props

  const navTop = useStickyElement({
    elementRef: navRef,
    priority: PRIORITY.navbar
  })

  const footerTop = useStickyElement({
    elementRef: footerRef,
    priority: PRIORITY.tabs
  })

  return (
    <>
      <div
        className={clsx(styles.navbar, styles[variant])}
        ref={navRef}
        style={{ top: navTop }}
      >
        {props.navbar}
      </div>
      <div
        className={clsx(
          styles.content,
          props.tabs && styles.contentWithTabs,
          styles[variant]
        )}
      >
        {props.children}
      </div>
      <div
        className={clsx(styles.footer, styles[variant])}
        ref={footerRef}
        style={{ top: footerTop }}
      >
        {props.tabs}
      </div>
    </>
  )
}

export default Header
