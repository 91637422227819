import { useQuery } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'

type Params = {
  semester?: string
  tutorId?: string
  teacherOrCoTeacherId?: string[]
}

const useCoursesGroupsCount = (params: Params) =>
  useQuery({
    queryKey: ['coursesGroupsCount', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelLessonsGroupsList({
        semester_id: params.semester,
        tutor_id: params.tutorId,
        teacher_or_co_teacher_id: params.teacherOrCoTeacherId,
        page_size: 1,
        fetchKeys: {
          id: true
        }
      })

      return response.count
    },
    enabled: !!params.semester
  })

export default useCoursesGroupsCount
