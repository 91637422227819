import { useTranslation } from 'react-i18next'

import Button from '@/components/Button/Button'
import ConfirmModal from '@/components/ConfirmModal'

type ArchiveStudentConfirmModalProps = {
  studentName: string
  onArchive: () => void
  isLoading: boolean
  open: boolean
  onOpenChange: (open: boolean) => void
}
const ArchiveStudentConfirmModal = (props: ArchiveStudentConfirmModalProps) => {
  const { t } = useTranslation('students')
  return (
    <ConfirmModal
      id="archive-student-modal"
      header={t('header.archive-student')}
      subheader={
        <>
          <p>
            {t('help.want-to-archive-student', {
              NAME: props.studentName
            })}
          </p>
          <p>{t('help.archive-student-contact')}</p>
        </>
      }
      confirmButton={
        <Button
          variant="danger"
          onClick={props.onArchive}
          loading={props.isLoading}
        >
          {t('button.archive')}
        </Button>
      }
      open={props.open}
      onOpenChange={props.onOpenChange}
    />
  )
}

export default ArchiveStudentConfirmModal
