import { type z, type ZodTypeAny } from 'zod'

import { labelAndValue, paginatedResults } from './zod'
import type { PaginatedResults } from '../types/paginated-results'

export const parsePaginated = <TSchema extends ZodTypeAny, TParsedItem>({
  schema,
  data,
  parse
}: {
  schema: TSchema
  data: unknown
  parse: (item: z.infer<TSchema>) => TParsedItem
}): PaginatedResults<TParsedItem> => {
  const parsed = paginatedResults(schema).parse(data)
  return {
    count: parsed.count,
    current: parsed.current,
    next: parsed.next,
    pageSize: parsed.page_size,
    previous: parsed.previous,
    results: parsed.results.map(parse)
  }
}

export const parsePaginatedOptions = (data: unknown) =>
  parsePaginated({
    data,
    schema: labelAndValue(),
    parse: item => item
  })
