import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'
import { usersQueryOptions } from '@/queries/usersQueryOptions'
import { nextPageParamGetter } from '@/utils/next-page-param-getter'
import { parsePaginatedOptions } from '@/utils/parse-paginated'
import { arrayOfOptions } from '@/utils/zod'

type Params = {
  search: string
}

type UsersFiltersParams = Record<'name' | 'email' | 'phone', Params>

export const useUsersFilters = (params?: UsersFiltersParams) => {
  const nameQuery = useInfiniteQuery(
    usersQueryOptions({ search: params?.name.search })
  )

  const emailQuery = useInfiniteQuery({
    queryKey: ['panelUsersOptionsEmailsList', params?.email],
    staleTime: 60 * 1000,
    queryFn: ({ pageParam }) =>
      httpService.panel
        .panelUsersOptionsEmailsList({
          ...params?.email,
          page: pageParam
        })
        .then(parsePaginatedOptions),
    getNextPageParam: nextPageParamGetter,
    initialPageParam: 1
  })

  const phoneQuery = useInfiniteQuery({
    queryKey: ['panelUsersOptionsPhoneNumbersListList', params?.phone],
    staleTime: 60 * 1000,
    queryFn: ({ pageParam }) =>
      httpService.panel
        .panelUsersOptionsPhoneNumbersList({
          ...params?.phone,
          page: pageParam
        })
        .then(parsePaginatedOptions),
    getNextPageParam: nextPageParamGetter,
    initialPageParam: 1
  })

  const statusQuery = useQuery({
    queryKey: ['panelUsersOptionsStatusesList'],
    staleTime: 60 * 1000,
    queryFn: async () => {
      const filtersResponse =
        await httpService.panel.panelUsersOptionsStatusesList()
      return arrayOfOptions.parse(filtersResponse.results)
    }
  })

  return { nameQuery, emailQuery, phoneQuery, statusQuery }
}
