import { httpService } from '@/api/http.service'
import type { InfiniteQueryOptions } from '@/types/infinite-query'
import type { LabelAndValue } from '@/types/label-and-value'
import { nextPageParamGetter } from '@/utils/next-page-param-getter'
import { parsePaginatedOptions } from '@/utils/parse-paginated'

type Params = {
  classId: string
  semester: string
  search?: string
}

export const coursesQueryOptions = (
  params: Params
): InfiniteQueryOptions<LabelAndValue> => ({
  queryKey: ['panelClassesGroupsOptionsCoursesListPaginated', params],
  staleTime: 60 * 1000,
  queryFn: ({ pageParam }) =>
    httpService.panel
      .panelClassesGroupsOptionsCoursesList(params.classId, params.semester, {
        search: params.search,
        page: pageParam
      })
      .then(parsePaginatedOptions),
  enabled: !!params.semester,
  getNextPageParam: nextPageParamGetter,
  initialPageParam: 1
})
