import { useQuery } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'

type Params = {
  semester: string
  studentsId?: string[]
  groupId?: string
  classId?: string
}

const useCommentsCount = (params: Params) =>
  useQuery({
    queryKey: ['panelCommentsCount', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const commentsResponse = await httpService.panel.panelCommentsList({
        student_id: params.studentsId,
        semester_id: params.semester,
        group_id: params.groupId,
        class_id: params.classId,
        page_size: 1,
        fetchKeys: {
          id: true
        }
      })

      return commentsResponse.count
    }
  })

export default useCommentsCount
