import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { type InfiniteQueryOptions } from '@/types/infinite-query'
import type { LabelAndValue } from '@/types/label-and-value'
import { nextPageParamGetter } from '@/utils/next-page-param-getter'
import { requiredString } from '@/utils/zod'

import type { PaginatedResults } from '../types/paginated-results'
import { parsePaginated } from '../utils/parse-paginated'

type Params = {
  search?: string
  semesterId?: string
  classId?: string
}

const responseSchema = z.object({
  id: requiredString(),
  full_name: requiredString()
})

const parseResponse = (data: unknown): PaginatedResults<LabelAndValue> =>
  parsePaginated({
    data,
    schema: responseSchema,
    parse: ({ id, full_name }) => ({ label: full_name, value: id })
  })

export const studentsQueryOptions = (
  params: Params
): InfiniteQueryOptions<LabelAndValue> => ({
  queryKey: ['panelStudentOptionsListPaginated', params],
  staleTime: 60 * 1000,
  queryFn: ({ pageParam }) =>
    httpService.panel
      .panelStudentsList({
        search: params.search,
        semester_id: params.semesterId,
        page: pageParam,
        class_id: params.classId ? [params.classId] : undefined,
        fetchKeys: {
          id: true,
          full_name: true
        }
      })
      .then(parseResponse),
  getNextPageParam: nextPageParamGetter,
  initialPageParam: 1
})
