import { create } from 'zustand'

import { type Language } from '@/types/lanuage'
import { type UserProfile } from '@/types/user-profile'

import { fetchUser } from '../utils/fetch-user'

const LOCAL_TOKEN_KEY = 'token'

export type User = {
  id: string
  firstName: string
  lastName: string
  fullName: string
  email: string
  isTeacher: boolean
  isSuperAdmin: boolean
  isGeneratorAdmin: boolean
  appLanguage: Language
  profiles: UserProfile[]
  tutorClasses?: { id: string; name: string }[]
  hasSeenTimetablePreview: boolean
}

export type AuthStore = {
  isAuthenticated: boolean
  user: User | null
  isSuperAdmin: boolean
  logIn: (token: string) => void
  logOut: () => void
  setUser: (user: User) => void
  markTimetablePreviewAsSeen: () => void
  refetchUser: () => void
}

const useAuthStore = create<AuthStore>((set, get) => ({
  isAuthenticated: !!localStorage.getItem(LOCAL_TOKEN_KEY),
  user: null,
  isSuperAdmin: false,
  logIn: (token: string) =>
    set(() => {
      localStorage.setItem(LOCAL_TOKEN_KEY, token)
      return { isAuthenticated: true }
    }),
  setUser: (newUser: User) =>
    set(() => ({
      user: newUser,
      isSuperAdmin: newUser.isSuperAdmin,
      isGeneratorAdmin: newUser.isGeneratorAdmin
    })),
  logOut: () =>
    set(() => {
      localStorage.removeItem(LOCAL_TOKEN_KEY)
      return { user: null, isAuthenticated: false }
    }),
  markTimetablePreviewAsSeen: () =>
    set(state => ({
      user: state.user
        ? {
            ...state.user,
            hasSeenTimetablePreview: true
          }
        : null
    })),
  refetchUser: async () => {
    const user = await fetchUser()
    const { setUser } = get()
    setUser(user)
  }
}))

export default useAuthStore
