import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { requiredString } from '@/utils/zod'

import type { IdAndName } from '../types/id-and-name'

const responseSchema = z.array(
  z.object({
    id: requiredString(),
    name: requiredString(),
    course: z.object({
      id: requiredString(),
      name: requiredString()
    })
  })
)

export type Group = {
  id: string
  name: string
  course: IdAndName
}

const parseResponse = (response: z.infer<typeof responseSchema>): Group[] =>
  response.map(item => ({
    id: item.id,
    name: item.name,
    course: item.course
  }))

const useGroups = (params: {
  classIds?: string[]
  studentIds?: string[]
  semester?: string
}) =>
  useQuery({
    queryKey: ['panelLessonsGroupsList', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelLessonsGroupsList({
        page_size: 'max',
        class_id: params.classIds,
        student_id: params.studentIds,
        semester_id: params.semester,
        fetchKeys: {
          id: true,
          name: true,
          course: true
        }
      })
      const parsed = parseResponse(responseSchema.parse(response.results))

      return {
        options: parsed.map(group => ({
          label: group.name,
          value: group.id
        })),
        list: parsed
      }
    }
  })

export default useGroups
