import { useQuery } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'

type Params = {
  semester: string
  classId: string
}

const useClassGroupsCount = (params: Params) =>
  useQuery({
    queryKey: ['panelClassesGroupsListCount', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelClassesGroupsList(
        params.classId,
        params.semester,
        { page_size: 1 }
      )

      return response.count
    }
  })

export default useClassGroupsCount
