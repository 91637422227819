import { endOfDay, isWithinInterval, startOfDay } from 'date-fns'
import { useTranslation } from 'react-i18next'

import type { Filter } from '@/components/common/Filters/Filters'
import { type CommonCommentFilterSchema } from '@/constants/common-comment-filter-schema'
import useAddedByOptions from '@/queries/useAddedByOptions'
import useCommentTypeOptions from '@/queries/useCommentTypeOptions'
import { type CommentType } from '@/types/comment-type'
import { type Semester } from '@/types/semester'

type CommonCommentFiltersProps = {
  semester?: Semester
  dateAfter?: string
  dateBefore?: string
  addedById?: string[]
  groupId?: string
  classId?: string
  studentId?: string
  commentType?: CommentType[]
  onChangeFilter: (
    key: CommonCommentFilterSchema,
    value?: string | string[] | Date
  ) => void
}

const useCommonCommentFilters = (props: CommonCommentFiltersProps) => {
  const { t } = useTranslation('common')

  const {
    data: addedBy,
    isLoading: isAddedByLoading,
    refetch: refetchAddedByOptions
  } = useAddedByOptions({
    semester: props.semester?.id,
    groupId: props.groupId,
    classId: props.classId,
    studentIds: props.studentId ? [props.studentId] : undefined
  })

  const { data: commentType, isLoading: isCommentTypeLoading } =
    useCommentTypeOptions()

  const isDateInsideSelectedSemester = (date: Date) => {
    if (!props.semester) return false
    return isWithinInterval(startOfDay(date), {
      start: startOfDay(props.semester.startDate),
      end: endOfDay(props.semester.endDate)
    })
  }

  const shouldDateBeDisabled = (date: Date) =>
    !isDateInsideSelectedSemester(date)

  const commonCommentsFilters: Filter[] = [
    {
      label: t('label.added-on'),
      variant: 'date-range',
      size: 'wide',
      filterProps: {
        id: 'range',
        value: {
          from: props.dateAfter ? new Date(props.dateAfter) : undefined,
          to: props.dateBefore ? new Date(props.dateBefore) : undefined
        },
        defaultMonth: props.semester?.startDate,
        disabledDate: shouldDateBeDisabled,
        onChange: value => {
          props.onChangeFilter('dateAfter', value?.from)
          props.onChangeFilter('dateBefore', value?.to)
        },
        placeholder: [t('placeholder.date-from'), t('placeholder.date-to')]
      }
    },
    {
      label: t('label.added-by'),
      variant: 'multiselect',
      filterProps: {
        id: 'added-by',
        multiple: true,
        loading: isAddedByLoading,
        options: addedBy || [],
        value: props.addedById,
        placeholder: t('label.added-by'),
        onChange: value => props.onChangeFilter('addedById', value)
      }
    },
    {
      label: t('label.type'),
      variant: 'multiselect',
      filterProps: {
        id: 'comment-type',
        multiple: true,
        loading: isCommentTypeLoading,
        options: commentType || [],
        value: props.commentType,
        dataTestId: 'status-filter',
        placeholder: t('label.type'),
        onChange: value => props.onChangeFilter('commentType', value)
      }
    }
  ]

  return { commonCommentsFilters, refetchAddedByOptions }
}

export default useCommonCommentFilters
