import { createFileRoute, Outlet } from '@tanstack/react-router'

import i18n from '@/i18n'
import useAuthStore from '@/store/useAuthStore'

export const Route = createFileRoute('/_auth/timetable')({
  component: () => <Outlet />,
  meta: () => [
    {
      title: i18n.t('navigation.timetable', { ns: 'common' })
    }
  ],
  onEnter: useAuthStore.getState().refetchUser
})
