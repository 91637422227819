import { getRouteApi } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import Loading from '@/components/Loading'
import Tag from '@/components/Tag/Tag'
import Tooltip from '@/components/Tooltip/Tooltip'
import BasicLayout from '@/components/common/BasicLayout/BasicLayout'
import { getUserStatusTag } from '@/utils/get-user-status-tag'

import styles from './ParentDetailsView.module.scss'
import ParentForm from '../components/ParentForm'
import useParent from '../queries/useParent'
import { getParentStatusTootlipText } from '../utils/statuses'

const routeApi = getRouteApi(
  '/_auth/students-and-classes/students/parents/$parentId/edit'
)

const ParentEditView = () => {
  const { t } = useTranslation('students')
  const { parentId } = routeApi.useParams()

  const { data: parent, isLoading } = useParent(parentId)

  return (
    <BasicLayout
      header={
        <div className={styles.headerWrapper}>
          <h1 className={styles.header}>{parent?.fullName}</h1>
          {parent?.status ? (
            <Tooltip
              trigger={
                <Tag
                  {...getUserStatusTag(parent.status)}
                  dataTestId="status-tag"
                  variant="on-blue"
                />
              }
              text={getParentStatusTootlipText(parent.status)}
            />
          ) : null}
        </div>
      }
      subheader={t('header.edit-guardian')}
      moduleName={t('header.academics', { ns: 'common' })}
    >
      <Loading spinning={isLoading}>
        {parent ? <ParentForm parent={parent} /> : null}
      </Loading>
    </BasicLayout>
  )
}

export default ParentEditView
