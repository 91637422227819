import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import MoreOutlinedIcon from '@/assets/icons/menu-vertical.svg?react'
import Button, {
  type ButtonProps,
  type ButtonVariant
} from '@/components/Button/Button'

import styles from './Actions.module.scss'
import Dropdown, { DropdownMenuItem } from '../../Dropdown/Dropdown'
import ActionsDrawer from '../ActionsDrawer/ActionsDrawer'

export type Action = {
  onClick?: () => void
  variantButton?: ButtonVariant
  variantAction?: 'normal' | 'danger'
  loading?: boolean
  text: string
  hidden?: boolean
} & Omit<ButtonProps, 'children' | 'onClick'>

type ActionsProps = {
  actions?: Action[]
  actionsAsDropdown?: boolean
}

const Actions = (props: ActionsProps) => {
  const { t } = useTranslation('common')

  return props.actions?.length ? (
    <>
      <div className={styles.buttonWrapper}>
        {props.actionsAsDropdown ? (
          <Dropdown
            sideOffset={-10}
            trigger={
              <Button
                dataTestId="more-button"
                variant="secondary"
                icon={<MoreOutlinedIcon />}
              >
                {t('button.more')}
              </Button>
            }
          >
            {props.actions?.map((action, index) => {
              const { icon: _, variantAction: __, ...actionProps } = action
              return (
                <DropdownMenuItem key={index}>
                  <Button
                    {...actionProps}
                    variant="tertiary"
                    className={styles.actionButton}
                    onClick={() => {
                      action.onClick?.()
                    }}
                  >
                    <div
                      className={clsx(
                        styles.action,
                        action.variantAction === 'danger' && styles.actionDanger
                      )}
                    >
                      <span className={styles.actionIcon}>{action.icon}</span>
                      <span>{action.text}</span>
                    </div>
                  </Button>
                </DropdownMenuItem>
              )
            })}
          </Dropdown>
        ) : (
          props.actions?.map((action, index) => {
            const { variantAction: _, ...buttonProps } = action
            return (
              <Button
                {...buttonProps}
                key={index}
                variant={action.variantButton || 'secondary'}
              >
                {action.text}
              </Button>
            )
          })
        )}
      </div>

      <ActionsDrawer
        actions={props.actions.map(action => ({
          ...action,
          isDanger: action.variantAction === 'danger'
        }))}
        onlyMobile
        triggerButtonSize="medium"
      />
    </>
  ) : null
}

export default Actions
