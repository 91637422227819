import { httpService } from '@/api/http.service'
import { type InfiniteQueryOptions } from '@/types/infinite-query'
import { type LabelAndValue } from '@/types/label-and-value'
import { nextPageParamGetter } from '@/utils/next-page-param-getter'
import { parsePaginatedOptions } from '@/utils/parse-paginated'

import { type OptionsParams } from '../types'

export const teachersQueryOptions = (
  params: OptionsParams & { enabled: boolean }
): InfiniteQueryOptions<LabelAndValue> => ({
  queryKey: ['panelLessonsGroupsOptionsTeachersList', params],
  staleTime: 60 * 100,
  queryFn: ({ pageParam }) =>
    httpService.panel
      .panelLessonsGroupsOptionsTeachersList({
        tutor_id: params.tutorId,
        semester_id: params.semester,
        search: params.search,
        page: pageParam
      })
      .then(parsePaginatedOptions),

  enabled: !!params.semester && params.enabled,
  getNextPageParam: nextPageParamGetter,
  initialPageParam: 1
})
