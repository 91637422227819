import { useQuery } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'

const useStudentClassesCount = () =>
  useQuery({
    queryKey: ['panelClassesListCount'],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelClassesList({
        page_size: 1,
        fetchKeys: {
          id: true
        }
      })

      return response.count
    }
  })

export default useStudentClassesCount
