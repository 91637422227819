import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import type { Gender } from '@/constants/gender-options'
import type { IdAndName } from '@/types/id-and-name'
import { type LabelAndValue } from '@/types/label-and-value'
import { type UserStatus } from '@/types/user-status'
import {
  arrayOfOptions,
  date,
  gender,
  labelAndValue,
  requiredString,
  userStatus
} from '@/utils/zod'

const responseSchema = z.object({
  id: requiredString(),
  first_name: z.string(),
  last_name: z.string(),
  full_name: requiredString(),
  academic_level: z
    .object({
      id: requiredString(),
      name: requiredString()
    })
    .nullable(),
  student_class: z
    .object({
      id: z.string().min(1),
      display_name: z.string().min(1)
    })
    .nullable()
    .optional(),
  phone_number: z.string().nullable().optional(),
  email: z.string(),
  tutor: z
    .object({
      id: requiredString(),
      full_name: requiredString(),
      first_name: requiredString(),
      last_name: requiredString()
    })
    .nullable()
    .optional(),
  parents: z
    .array(
      z.object({
        id: requiredString(),
        full_name: requiredString(),
        first_name: requiredString(),
        last_name: requiredString(),
        phone_number: z.string(),
        email: requiredString(),
        status: userStatus
      })
    )
    .optional(),
  status: userStatus.optional(),
  gender: gender().nullable(),
  date_of_birth: date(),
  ahv_number: z.string().optional(),
  nationalities: arrayOfOptions,
  first_language: labelAndValue().nullable(),
  community: labelAndValue().nullable(),
  archived_at: z.string().nullable()
})

export type Parent = {
  id: string
  fullName: string
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  status: UserStatus
}

export type Student = {
  id: string
  firstName: string
  lastName: string
  fullName: string
  status?: UserStatus
  academicLevel?: IdAndName
  studentClass?: IdAndName
  email: string
  phoneNumber?: string
  gender?: Gender
  dateOfBirth: Date
  nationalities: LabelAndValue[]
  firstLanguage?: LabelAndValue
  community?: LabelAndValue
  ahvNumber?: string
  tutor?: {
    id: string
    fullName: string
    firstName: string
    lastName: string
  }
  parents?: Parent[]
  archivedAt?: Date
}

const parseResponse = (response: z.infer<typeof responseSchema>): Student => ({
  id: response.id,
  firstName: response.first_name,
  lastName: response.last_name,
  fullName: response.full_name,
  status: response.status,
  academicLevel: response.academic_level || undefined,
  studentClass: response.student_class
    ? {
        id: response.student_class.id,
        name: response.student_class.display_name
      }
    : undefined,
  email: response.email,
  phoneNumber: response.phone_number ? response.phone_number : undefined,
  gender: response.gender || undefined,
  dateOfBirth: response.date_of_birth,
  nationalities: response.nationalities,
  firstLanguage: response.first_language || undefined,
  ahvNumber: response.ahv_number,
  community: response.community || undefined,
  tutor: response.tutor
    ? {
        id: response.tutor.id,
        fullName: response.tutor.full_name,
        firstName: response.tutor.first_name,
        lastName: response.tutor.last_name
      }
    : undefined,
  parents: response.parents
    ? response.parents.map(parent => ({
        id: parent.id,
        fullName: parent.full_name,
        firstName: parent.first_name,
        lastName: parent.last_name,
        email: parent.email,
        phoneNumber: parent.phone_number,
        status: parent.status
      }))
    : [],
  archivedAt: response.archived_at ? new Date(response.archived_at) : undefined
})

export const studentQueryOptions = (id: string) => ({
  queryKey: ['panelStudentsRetrieve', id],
  queryFn: async () => {
    const studentResponse = await httpService.panel.panelStudentsRetrieve(id, {
      fetchKeys: {
        id: true,
        first_name: true,
        last_name: true,
        full_name: true,
        academic_level: true,
        student_class: true,
        email: true,
        phone_number: true,
        is_active: true,
        date_of_birth: true,
        status: true,
        parents: true,
        tutor: true,
        gender: true,
        nationalities: true,
        first_language: true,
        ahv_number: true,
        community: true,
        archived_at: true
      }
    })
    return parseResponse(responseSchema.parse(studentResponse))
  }
})

const useStudent = (id: string) => useQuery(studentQueryOptions(id))

export default useStudent
