import { getRouteApi } from '@tanstack/react-router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CancelIcon from '@/assets/icons/cancel.svg?react'
import UndoIcon from '@/assets/icons/undo.svg?react'
import UpdateIcon from '@/assets/icons/update.svg?react'
import RestoreLessonModal from '@/components/RestoreLessonModal'
import { type TableAction } from '@/components/Table/Table'
import { useScreenResolution } from '@/hooks/useScreenResolution'
import { type CoveredOrCancelledLesson } from '@/types/lesson'
import { getValue } from '@/utils/get-value'

import CoveredAndCancelledLessonsList from '../components/CoveredAndCancelledLessonsList'
import { getTeachersColumn } from '../components/TruncateColumn'
import useLessons from '../queries/useLessons'
import useLessonsCount from '../queries/useLessonsCount'

const routeApi = getRouteApi('/_auth/covered-and-cancelled-lessons/cancelled')

const CoveredLessonsView = () => {
  const { t } = useTranslation('coveredCancelledLessons')
  const search = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const {
    data: lessons,
    isLoading: isLessonsLoading,
    refetch: refetchLessonList
  } = useLessons({
    ...search,
    isCancelled: true,
    teacherIds: search.teacherIds?.map(getValue),
    classIds: search.classIds?.map(getValue),
    courseIds: search.courseIds?.map(getValue),
    groupIds: search.groupIds?.map(getValue)
  })

  const {
    data: lessonsCount,
    isLoading: isLessonsCountLoading,
    refetch: refetchLessonsCount
  } = useLessonsCount({
    semesterId: search.semesterId,
    isCancelled: true
  })

  const [isRestoreModalOpen, setIsRestoreModalOpen] = useState(false)
  const [currentLesson, setCurrentLesson] = useState<CoveredOrCancelledLesson>()
  const { isMobile } = useScreenResolution()

  const teacherColumn = getTeachersColumn({
    getTeachers: ({ teacher, coTeacher }) => [teacher, coTeacher],
    header: t('header.teacher'),
    key: 'teacher',
    isMobile
  })

  const actions: TableAction<CoveredOrCancelledLesson>[] = [
    {
      icon: <UndoIcon />,
      onClick: lesson => {
        setCurrentLesson(lesson)
        setIsRestoreModalOpen(true)
      },
      text: t('button.restore-lesson')
    }
  ]

  const refetch = () => {
    refetchLessonList()
    refetchLessonsCount()
  }

  const handleChange = () => {
    refetch()
    setCurrentLesson(undefined)
  }

  return (
    <>
      <CoveredAndCancelledLessonsList
        id="covered-lessons-table"
        actions={actions}
        onNavigate={navigate}
        search={search}
        lessons={lessons?.list || []}
        refetchLessonList={refetch}
        count={lessons?.count || 0}
        totalCount={lessonsCount || 0}
        isTableLoading={isLessonsLoading || isLessonsCountLoading}
        coverSettingAction={{
          text: t('button.cover-lesson'),
          icon: <UpdateIcon />
        }}
        additionalColumns={[teacherColumn]}
        coverCancelButton={{
          text: t('button.cancel-lesson'),
          icon: <CancelIcon />
        }}
      />

      {currentLesson ? (
        <RestoreLessonModal
          open={isRestoreModalOpen}
          lesson={currentLesson}
          onOpenChange={setIsRestoreModalOpen}
          onSuccess={handleChange}
        />
      ) : null}
    </>
  )
}

export default CoveredLessonsView
