import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { type InfiniteQueryOptions } from '@/types/infinite-query'
import { type LabelAndValue } from '@/types/label-and-value'
import { nextPageParamGetter } from '@/utils/next-page-param-getter'
import { requiredString } from '@/utils/zod'

import type { PaginatedResults } from '../types/paginated-results'
import { parsePaginated } from '../utils/parse-paginated'

const responseSchema = z.object({
  id: requiredString(),
  name: requiredString()
})

const parseResponse = (data: unknown): PaginatedResults<LabelAndValue> =>
  parsePaginated({
    data,
    schema: responseSchema,
    parse: ({ id, name }) => ({ value: id, label: name })
  })

export const roomsQueryOptions = (
  search?: string
): InfiniteQueryOptions<LabelAndValue> => ({
  queryKey: ['panelRoomsListPaginated', search],
  staleTime: 60 * 100,
  queryFn: ({ pageParam }) =>
    httpService.panel
      .panelRoomsList({
        page: pageParam,
        search,
        fetchKeys: {
          id: true,
          name: true
        }
      })
      .then(parseResponse),
  getNextPageParam: nextPageParamGetter,
  initialPageParam: 1
})
