import { sumBy } from 'lodash'
import { create } from 'zustand'

import type { Priority } from '@/hooks/useStickyElement'

type StickyElement = {
  id: string
  height: number
  priority: Priority
  contextId: string
}

type StickyStore = {
  elements: StickyElement[]
  registerElement: (
    id: string,
    contextId: string,
    priority: Priority,
    height: number
  ) => void
  unregisterElement: (id: string, contextId: string) => void
  updateElementHeight: (id: string, contextId: string, height: number) => void
  calculateTopOffset: (priority: number, contextId: string) => number
}

const useStickyStore = create<StickyStore>((set, get) => ({
  elements: [],
  registerElement: (id, contextId, priority, height) => {
    const { elements } = get()
    const existingElement = elements.find(
      element => element.id === id && element.contextId === contextId
    )

    if (existingElement) return

    set(state => ({
      elements: [...state.elements, { id, contextId, priority, height }]
    }))
  },
  unregisterElement: (id, contextId) => {
    set(state => ({
      elements: state.elements.filter(
        el => !(el.id === id && el.contextId === contextId)
      )
    }))
  },
  updateElementHeight: (id, contextId, height) => {
    set(state => ({
      elements: state.elements.map(el =>
        el.id === id && el.contextId === contextId ? { ...el, height } : el
      )
    }))
  },

  calculateTopOffset: (priority, contextId) => {
    const { elements } = get()
    return sumBy(elements, element =>
      element.contextId === contextId && element.priority < priority
        ? element.height
        : 0
    )
  }
}))

export default useStickyStore
