import clsx from 'clsx'
import { getUnixTime, isSameDay } from 'date-fns'
import { useEffect, useMemo, useRef } from 'react'
import { type DayProps, type WeekProps, useDayPicker } from 'react-day-picker'

import styles from './Calendar.module.scss'

const isDateObject = (value: unknown): value is Date =>
  value instanceof Date && !isNaN(value.getTime())

const CalendarWeek = (props: WeekProps) => {
  const {
    styles: rdpStyles,
    classNames,
    dayPickerProps,
    selected,
    getModifiers,
    goToMonth,
    nextMonth,
    previousMonth
  } = useDayPicker()

  const { onDayClick, showWeekNumber } = dayPickerProps

  const tdRef = useRef<HTMLTableCellElement>(null)

  const focusWeekAfter = () => {
    const nextWeekRow = tdRef.current?.closest('tr')?.nextElementSibling
    const nextWeekCell = nextWeekRow?.querySelector('[role="button"]')

    if (nextWeekCell instanceof HTMLElement) {
      if (nextWeekCell.tabIndex !== 0) return
      nextWeekCell.focus()
    } else if (nextMonth) {
      goToMonth(nextMonth)
      setTimeout(() => {
        const firstWeekCell = document.querySelector(
          'table tr:nth-child(2) [role="button"]'
        )
        if (firstWeekCell instanceof HTMLElement) {
          firstWeekCell.focus()
        }
      }, 10)
    }
  }

  const focusWeekBefore = () => {
    const prevWeekRow = tdRef.current?.closest('tr')?.previousElementSibling
    const prevWeekCell = prevWeekRow?.querySelector('[role="button"]')

    if (prevWeekCell instanceof HTMLElement) {
      if (prevWeekCell.tabIndex !== 0) return
      prevWeekCell.focus()
    } else if (previousMonth) {
      goToMonth(previousMonth)
      setTimeout(() => {
        const rows = document.querySelectorAll('table tr')
        const lastWeekRow = rows[rows.length - 2]
        const lastWeekCell = lastWeekRow?.querySelector('[role="button"]')
        if (lastWeekCell instanceof HTMLElement) {
          lastWeekCell.focus()
        }
      }, 10)
    }
  }

  const activeModifiers = props.week.days.map(day => getModifiers(day))

  const isEveryDayDisabled = activeModifiers.every(item => !!item.disabled)

  const isThisWeekSelected = useMemo(() => {
    if (!isDateObject(selected)) return false
    const selectedDate: Date = selected

    return (
      props.week.days.findIndex(date => isSameDay(date.date, selectedDate)) !==
      -1
    )
  }, [selected, props.week.days])

  const handleOnKeyDown = (
    event: React.KeyboardEvent<HTMLTableCellElement>
  ) => {
    switch (event.key) {
      case 'ArrowDown': {
        focusWeekAfter()
        event.preventDefault()
        break
      }
      case 'ArrowUp': {
        focusWeekBefore()
        event.preventDefault()
        break
      }
      case 'Enter': {
        tdRef.current?.click()
        event.preventDefault()
        break
      }
    }
  }

  const handleOnRowClick = (
    event: React.MouseEvent<HTMLTableCellElement, MouseEvent>
  ) => {
    if (isEveryDayDisabled) return

    onDayClick?.(props.week.days[0].date, activeModifiers[0], event)
  }

  useEffect(() => {
    if (isThisWeekSelected) {
      tdRef.current?.focus()
    }
  }, [isThisWeekSelected])

  return (
    <tr className={classNames.week} style={rdpStyles?.week}>
      {showWeekNumber ? (
        <td
          className={classNames.day}
          style={rdpStyles?.day}
          role="presentation"
        >
          <span className={styles.weekNumber}>{props.week.weekNumber}</span>
        </td>
      ) : null}
      <td
        ref={tdRef}
        colSpan={8}
        role="button"
        onClick={handleOnRowClick}
        tabIndex={isEveryDayDisabled ? -1 : 0}
        className={clsx(
          styles.week,
          styles.row,
          isThisWeekSelected && styles.selectedWeek
        )}
        onKeyDown={handleOnKeyDown}
        aria-disabled={isEveryDayDisabled}
        aria-selected={isThisWeekSelected}
      >
        {props.week.days.map(day => {
          const modifiers = getModifiers(day)

          return (
            <div
              className={clsx(
                styles.weekDay,
                modifiers.today && classNames.today,
                modifiers.selected && classNames.selected,
                modifiers.disabled && classNames.disabled,
                modifiers.outside && classNames.outside,
                classNames.day
              )}
              style={rdpStyles?.day}
              key={getUnixTime(day.date)}
              role="presentation"
            >
              <WeekDay day={day} modifiers={getModifiers(day)} />
            </div>
          )
        })}
      </td>
    </tr>
  )
}

const WeekDay = (props: DayProps) => {
  const { dayPickerProps } = useDayPicker()
  const { classNames } = dayPickerProps

  return (
    <div className={classNames?.day_button} role="presentation">
      {props.day.date.getDate()}
    </div>
  )
}

export default CalendarWeek
