import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { type Semester } from '@/types/semester'
import { requiredString } from '@/utils/zod'

const responseSchema = z.object({
  preview_exists: z.boolean(),
  semester: z
    .object({
      id: requiredString(),
      start_date: requiredString(),
      end_date: requiredString(),
      name: requiredString(),
      is_active: z.boolean()
    })
    .nullable()
})

type PreviewExists = {
  previewExists: boolean
  semester?: Semester
}

const parseResponse = (
  response: z.infer<typeof responseSchema>
): PreviewExists => ({
  previewExists: response.preview_exists,
  semester: response.semester
    ? {
        id: response.semester.id,
        name: response.semester.name,
        startDate: new Date(response.semester.start_date),
        endDate: new Date(response.semester.end_date),
        isActive: response.semester.is_active
      }
    : undefined
})

const usePreviewExists = () =>
  useQuery({
    queryKey: ['panelTimetablePreviewCheckRetrieve'],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response =
        await httpService.panel.panelTimetablePreviewCheckRetrieve()

      return parseResponse(responseSchema.parse(response))
    }
  })

export default usePreviewExists
