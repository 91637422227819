import * as ToggleGroupRadix from '@radix-ui/react-toggle-group'
import clsx from 'clsx'

import styles from './ToggleGroup.module.scss'

export type ToggleGroupOption<T extends string> = {
  label: string
  value: T
  dataTestId?: string
}

type ToggleGroupProps<T extends string> = {
  options: ToggleGroupOption<T>[]
  value: T
  dataTestId?: string
  onChange: (value: T) => void
}

const ToggleGroup = <T extends string>(props: ToggleGroupProps<T>) => (
  <ToggleGroupRadix.Root
    type="single"
    onValueChange={value => {
      if (value) props.onChange(value as T)
    }}
    className={styles.toggleGroup}
  >
    {props.options.map(({ label, value, dataTestId }) => (
      <ToggleGroupRadix.Item
        value={value}
        key={value}
        data-test-id={dataTestId}
        className={clsx(styles.item, value === props.value && styles.active)}
      >
        {label}
      </ToggleGroupRadix.Item>
    ))}
  </ToggleGroupRadix.Root>
)

export default ToggleGroup
