import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { requiredString } from '@/utils/zod'

const responseSchema = z.array(
  z.object({
    id: requiredString(),
    name: requiredString()
  })
)

const useStudentCoursesOptions = (params: {
  studentId?: string
  classId?: string
  semester: string
}) =>
  useQuery({
    queryKey: ['panelCoursesList', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelCoursesList({
        page_size: 'max',
        student_id: params.studentId,
        class_id: params.classId,
        semester_id: params.semester,
        fetchKeys: {
          id: true,
          name: true
        }
      })
      const parsed = responseSchema.parse(response.results)

      return {
        options: parsed.map(course => ({
          label: course.name,
          value: course.id
        }))
      }
    }
  })

export default useStudentCoursesOptions
