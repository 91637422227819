import { useInfiniteQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { type LabelAndValue } from '@/types/label-and-value'
import { nextPageParamGetter } from '@/utils/next-page-param-getter'
import { requiredString } from '@/utils/zod'

import type { PaginatedResults } from '../types/paginated-results'
import { parsePaginated } from '../utils/parse-paginated'

const responseSchema = z.object({
  id: requiredString(),
  full_name: requiredString()
})

const parseResponse = (data: unknown): PaginatedResults<LabelAndValue> =>
  parsePaginated({
    data,
    schema: responseSchema,
    parse: ({ id, full_name }) => ({ label: full_name, value: id })
  })

const useTeacherOptionsPaginated = (params: { search?: string }) => {
  const infiniteQuery = useInfiniteQuery({
    queryKey: ['panelTeachersListPaginated', params],
    staleTime: 60 * 1000,
    queryFn: ({ pageParam }) =>
      httpService.panel
        .panelTeachersList({
          page: pageParam,
          search: params.search,
          fetchKeys: {
            id: true,
            full_name: true
          }
        })
        .then(parseResponse),
    getNextPageParam: nextPageParamGetter,
    initialPageParam: 1
  })

  return {
    ...infiniteQuery,
    data: infiniteQuery.data?.pages.flatMap(({ results }) => results) || []
  }
}

export default useTeacherOptionsPaginated
