import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import type { IdAndName } from '@/types/id-and-name'
import { requiredString } from '@/utils/zod'

const responseSchema = z.array(
  z.object({
    id: requiredString(),
    full_name: requiredString(),
    ahv_number: z.string().optional(),
    academic_level: z
      .object({
        id: requiredString(),
        name: requiredString()
      })
      .nullable()
  })
)

export type Student = {
  id: string
  fullName: string
  ahvNumber?: string
  academicLevel?: IdAndName
}

const parseResponse = (response: z.infer<typeof responseSchema>): Student[] =>
  response.map(item => ({
    id: item.id,
    fullName: item.full_name,
    ahvNumber: item.ahv_number,
    academicLevel: item.academic_level || undefined
  }))

type Params = {
  studentsId?: string[]
  academicLevelId?: string[]
  classId?: string[]
  pageSize?: number | 'max'
  page?: number
  search?: string
}

export const getStudentsList = async (params: Params) => {
  const response = await httpService.panel.panelStudentsList({
    id: params.studentsId,
    academic_level_id: params.academicLevelId,
    class_id: params.classId,
    page_size: params.pageSize,
    page: params.page,
    search: params.search,
    fetchKeys: {
      id: true,
      full_name: true,
      ahv_number: true,
      academic_level: true
    }
  })
  const parsed = parseResponse(responseSchema.parse(response.results))

  return parsed
}

const useStudentsClassForm = (params: Params) =>
  useQuery({
    queryKey: ['panelStudentsListClassForm', params],
    staleTime: 60 * 100,
    queryFn: () => getStudentsList(params),
    enabled: !!params.studentsId?.length || !!params.academicLevelId?.length
  })

export default useStudentsClassForm
