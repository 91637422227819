import { getRouteApi } from '@tanstack/react-router'
import {
  addWeeks,
  endOfISOWeek,
  format,
  isWithinInterval,
  startOfISOWeek
} from 'date-fns'
import { identity, isEmpty, omit, pickBy } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounceValue } from 'usehooks-ts'

import Alert from '@/components/Alert/Alert'
import Badge from '@/components/Badge/Badge'
import Button from '@/components/Button/Button'
import CalendarBox, {
  type CalendarBoxRef
} from '@/components/CalendarBox/CalendarBox'
import { type SelectOption } from '@/components/Select/Select'
import ToggleGroup, {
  type ToggleGroupOption
} from '@/components/ToggleGroup/ToggleGroup'
import BasicLayout from '@/components/common/BasicLayout/BasicLayout'
import Filters, { type Filter } from '@/components/common/Filters/Filters'
import { API_DATE_FORMAT } from '@/constants/date-format'
import { useToast } from '@/hooks/useToast'
import { roomsQueryOptions } from '@/queries/roomsQueryOptions'
import { studentsQueryOptions } from '@/queries/studentsQueryOptions'
import useSemesters from '@/queries/useSemesters'
import useTeacherOptionsPaginated from '@/queries/useTeacherOptionsPaginated'
import useAuthStore from '@/store/useAuthStore'
import { getRoomsText } from '@/utils/get-rooms-text'
import { getSemesterFromWeek } from '@/utils/get-semester-from-week'

import styles from './TimetableView.module.scss'
import LessonEvent, {
  type LessonEventClickType,
  type LessonEventProps
} from '../components/LessonEvent'
import type { TimetableEvent } from '../constants/timetable-events'
import type { TimetableFiltersKey } from '../constants/timetable-filters'
import useMarkTimetablePreviewAsSeen from '../mutations/useMarkTimetablePreviewAsSeen'
import { classesPreviewQueryOptions } from '../queries/classesPreviewQueryOptions'
import { classesQueryOptions } from '../queries/classesQueryOptions'
import { coursesPreviewQueryOptions } from '../queries/coursesPreviewQueryOptions'
import { coursesQueryOptions } from '../queries/coursesQueryOptions'
import usePreviewExists from '../queries/usePreviewExists'
import useTimetableEvents from '../queries/useTimetableEvents'
import useTimetablePreviewEvents from '../queries/useTimetablePreviewEvents'

const routeApi = getRouteApi('/_auth/timetable/')

const TimetableView = () => {
  const { t } = useTranslation(['timetable'])

  const calendarBox = useRef<CalendarBoxRef>(null)

  const search = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const { user, markTimetablePreviewAsSeen } = useAuthStore()
  const isPreviewNew = user ? !user.hasSeenTimetablePreview : false

  const [previewMode, setPreviewMode] = useState<PreviewMode>('active')
  const previewEnabled = previewMode === 'draft'

  const isSomeFilterExist = !isEmpty(
    pickBy(omit(search, ['dateFrom', 'dateTo']), identity)
  )

  const searchParams = {
    ...search,
    student: search.student?.value,
    teacher: search.teacher?.value,
    course: search.course?.value,
    classId: search.classId?.value,
    room: search.room?.value
  }

  const { data: events, isFetching: isTimetableEventsLoading } =
    useTimetableEvents(searchParams, isSomeFilterExist && !previewEnabled)

  const { data: eventsPreview, isFetching: isTimetablePreviewEventsLoading } =
    useTimetablePreviewEvents(searchParams, isSomeFilterExist && previewEnabled)

  const { data: semesters } = useSemesters()
  const { data: preview } = usePreviewExists()

  const currentSemester =
    preview?.previewExists && previewEnabled
      ? preview.semester
      : semesters?.currentSemester

  const [teacherSearch, setTeacherSearch] = useDebounceValue('', 200)

  const {
    data: teacherOptions,
    isLoading: isTeachersLoading,
    fetchNextPage: fetchNextTeacherOptions
  } = useTeacherOptionsPaginated({
    search: teacherSearch
  })

  const isUserTeacher = !!user?.isTeacher

  const sortedTeacherOptions = isUserTeacher
    ? [
        { label: user.fullName, value: user.id, isHighlighted: true },
        ...(teacherOptions || []).filter(option => option.value !== user.id)
      ]
    : teacherOptions || []

  const changeFilter = (
    key: TimetableFiltersKey,
    value?: string | SelectOption<string>
  ) => {
    navigate({
      search: () => ({
        [key]: value,
        dateFrom: searchParams.dateFrom,
        dateTo: searchParams.dateTo
      })
    })
  }

  const canSeeLessonDetails = (event: TimetableEvent) =>
    !!user?.isSuperAdmin ||
    (user?.id
      ? [
          event.teacher.id,
          event.teacherCover?.id,
          event.coTeacherCover?.id,
          event.coTeacher?.id,
          ...event.tutors
        ].includes(user.id)
      : false)

  const handleEventClick = ({
    lessonId,
    courseId,
    groupId,
    isPreview
  }: LessonEventClickType) => {
    if (!isPreview && groupId) {
      navigate({
        to: '/timetable/$courseId/$groupId/lesson-details',
        params: {
          courseId,
          groupId
        },
        search: {
          lessonId
        }
      })
    }
  }

  const parseEventProps = (event: TimetableEvent): LessonEventProps => ({
    id: event.id,
    startDate: event.startDate,
    endDate: event.endDate,
    course: event.course,
    groupId: event.group?.id,
    teacherCoverName: event.teacherCover?.name,
    coTeacherCoverName: event.coTeacherCover?.name,
    teacherName: event.teacher.name,
    coTeacherName: event.coTeacher?.name,
    classNamesText: event.classNames.join(', '),
    roomName: getRoomsText(event),
    isCancelled: event.isCancelled,
    onClick: handleEventClick
  })

  const timetableEvents = previewEnabled
    ? eventsPreview?.map(event => ({
        start: event.startDate,
        end: event.endDate,
        dataTestId: 'lesson-preview-slot',
        component: <LessonEvent {...parseEventProps(event)} isPreview />
      })) || []
    : events?.map(event => ({
        start: event.startDate,
        end: event.endDate,
        dataTestId: 'lesson-slot',
        component: (
          <LessonEvent
            {...parseEventProps(event)}
            clickable={canSeeLessonDetails(event)}
          />
        )
      })) || []

  const handleOnDateChange = (date: Date) => {
    const nextSemester = getSemesterFromWeek(date, semesters?.list)
    const isClassOrCourseFilter = search.classId || search.course?.value
    navigate({
      to: '/timetable',
      search: {
        ...(isClassOrCourseFilter && nextSemester?.id !== currentSemester?.id
          ? {}
          : search),
        dateFrom: format(startOfISOWeek(date), API_DATE_FORMAT),
        dateTo: format(endOfISOWeek(date), API_DATE_FORMAT)
      }
    })
  }

  const filters: Filter[] = [
    {
      label: t('label.teacher'),
      variant: 'select',
      filterProps: {
        id: 'teacher',
        loading: isTeachersLoading,
        options: sortedTeacherOptions,
        mode: 'detailed',
        value: search.teacher,
        placeholder: t('help.select-teacher'),
        onChange: value => changeFilter('teacher', value),
        onInputChange: setTeacherSearch,
        onMenuScrollToBottom: () => fetchNextTeacherOptions()
      }
    },
    {
      label: t('label.student'),
      variant: 'async-select',
      filterProps: {
        id: 'student',
        queryOptions: studentsSearch =>
          studentsQueryOptions({ search: studentsSearch }),
        value: search.student,
        placeholder: t('help.select-student'),
        onChange: value => changeFilter('student', value)
      }
    },
    {
      label: t('label.course'),
      variant: 'async-select',
      filterProps: {
        id: 'course',
        queryOptions: coursesSearch =>
          previewEnabled
            ? coursesPreviewQueryOptions({
                search: coursesSearch
              })
            : coursesQueryOptions({
                search: coursesSearch
              }),
        value: search.course,
        placeholder: t('help.select-course'),
        onChange: value => changeFilter('course', value)
      }
    },
    {
      label: t('label.class'),
      variant: 'async-select',
      filterProps: {
        id: 'group',
        queryOptions: classesSearch =>
          previewEnabled
            ? classesPreviewQueryOptions({
                search: classesSearch
              })
            : classesQueryOptions({
                search: classesSearch
              }),
        value: search.classId,
        placeholder: t('help.select-class'),
        onChange: value => changeFilter('classId', value)
      }
    },
    {
      label: t('label.room'),
      variant: 'async-select',
      filterProps: {
        id: 'room',
        queryOptions: roomsQueryOptions,
        value: search.room,
        placeholder: t('help.select-room'),
        onChange: value => changeFilter('room', value)
      }
    }
  ]

  const disabledArrow = (type: 'next' | 'previous') => {
    const nextWeekStart = addWeeks(
      searchParams.dateFrom,
      type === 'next' ? 1 : -1
    )

    return !getSemesterFromWeek(nextWeekStart, semesters?.list)
  }

  const isDateInsideSelectedSemester = (date: Date) => {
    if (!currentSemester) return
    return isWithinInterval(date, {
      start: currentSemester.startDate,
      end: currentSemester.endDate
    })
  }

  const shouldDateBeDisabled = (date: Date) =>
    !isDateInsideSelectedSemester(date)

  const { mutate: markAsSeen } = useMarkTimetablePreviewAsSeen()
  const hideNewPreviewBadge = () => {
    markAsSeen()
    markTimetablePreviewAsSeen()
  }

  const handleTogglePreviewMode = (value: PreviewMode) => {
    const date =
      value === 'draft' && !preview?.semester?.isActive && preview?.semester
        ? preview.semester.startDate
        : new Date()

    if (value === 'draft' && isPreviewNew) hideNewPreviewBadge()

    // setTimeout prevents "flushSync was called from inside a lifecycle method." warning
    setTimeout(() => {
      calendarBox.current?.handleOnDayChange(date)
    })
    setPreviewMode(value)
  }

  const toggleOptions: ToggleGroupOption<PreviewMode>[] = [
    {
      label: t('button.active'),
      value: 'active',
      dataTestId: 'preview-toggle-active'
    },
    {
      label: t('button.draft'),
      value: 'draft',
      dataTestId: 'preview-toggle-draft'
    }
  ]

  const newPreviewToast = useToast({
    title: t('toast.timetable-ready-for-review'),
    variant: 'info',
    action: {
      render: (
        <Button
          variant="info"
          size="small"
          onClick={() => handleTogglePreviewMode('draft')}
        >
          {t('button.view')}
        </Button>
      ),
      altText: t('button.view-alt')
    },
    onCloseButtonClick: hideNewPreviewBadge
  })

  useEffect(() => {
    if (isPreviewNew) newPreviewToast.open()
    else newPreviewToast.close()
    // close toast on route leave
    return newPreviewToast.close
    // adding newPreviewToast to dependency array causes the effect and cleanup to run on every render
    // this causes flickering when the toast first appears
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreviewNew])

  return (
    <BasicLayout
      moduleName={t('header.academics', { ns: 'common' })}
      header={
        currentSemester?.name ? currentSemester.name : t('header.timetable')
      }
    >
      <Filters filters={filters} hideClearAllButton />

      <CalendarBox
        ref={calendarBox}
        events={timetableEvents}
        loading={isTimetableEventsLoading || isTimetablePreviewEventsLoading}
        initialDate={searchParams.dateFrom}
        onDateChange={handleOnDateChange}
        disabledLeftArrow={disabledArrow('previous')}
        disabledRightArrow={disabledArrow('next')}
        disabledWeekPickerDate={shouldDateBeDisabled}
        height="auto"
        nextToWeekSwitcher={
          preview?.previewExists ? (
            <div className={styles.badgeWrapper}>
              <ToggleGroup
                value={previewMode}
                onChange={handleTogglePreviewMode}
                options={toggleOptions}
              />
              {isPreviewNew ? (
                <Badge color="blue" text={t('text.new')} topRight uppercase />
              ) : null}
            </div>
          ) : null
        }
        belowWeekSwitcher={
          previewEnabled ? (
            <Alert
              className={styles.alert}
              message={t('text.preliminary-overview')}
            />
          ) : null
        }
      />
    </BasicLayout>
  )
}

type PreviewMode = 'active' | 'draft'

export default TimetableView
