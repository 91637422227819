import { type TagColor } from '@/components/Tag/Tag'
import i18n from '@/i18n'

export const getAbsencesStatusTag = (
  value: 'excused' | 'unexcused'
): { color: TagColor; label: string } => {
  switch (value) {
    case 'excused':
      return {
        color: 'green',
        label: i18n.t('label.excused', { ns: 'absences' })
      }
    case 'unexcused':
      return {
        color: 'red',
        label: i18n.t('label.unexcused', { ns: 'absences' })
      }
  }
}
