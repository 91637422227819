import { useTranslation } from 'react-i18next'

import ArrowRightIcon from '@/assets/icons/arrow-right.svg?react'
import Label from '@/components/Label'
import { type SelectOption } from '@/components/Select/Select'
import { type FormFieldType } from '@/types/form-field-type'
import type { IdAndName } from '@/types/id-and-name'
import type { LabelAndValue } from '@/types/label-and-value'
import { type TeacherAvailability } from '@/types/lesson'

import styles from './CoverTeacherField.module.scss'
import TeacherSelect from './TeacherSelect'

type CoverTeacherFieldProps = {
  placeholder: string
  teacher: IdAndName
  inputProps: FormFieldType<LabelAndValue | undefined>
  teacherList: TeacherAvailability[]
  options: SelectOption<string>[]
  isOptionsLoading: boolean
  onClear: () => void
}

const CoverTeacherField = (props: CoverTeacherFieldProps) => {
  const { t } = useTranslation('common')
  const options = props.options.filter(
    teacher => teacher.value !== props.teacher.id
  )

  return (
    <div className={styles.coverFieldWrapper}>
      <div className={styles.coverFieldContainer}>
        <div className={styles.currentTeacher} data-test-id="current-teacher">
          {props.teacher.name}
        </div>

        <ArrowRightIcon className={styles.icon} />
        <Label
          id={props.inputProps.id}
          label={t('label.select-teacher')}
          hidden
        >
          <TeacherSelect
            {...props.inputProps}
            teachersList={props.teacherList}
            isOptionsLoading={props.isOptionsLoading}
            dataTestId="teacher-cover"
            options={options}
            placeholder={props.placeholder}
            onClear={props.onClear}
          />
        </Label>
      </div>
    </div>
  )
}

export default CoverTeacherField
