import { type TagColor } from '@/components/Tag/Tag'
import i18n from '@/i18n'

export const getCommentTypeTag = (
  value: 'negative' | 'positive' | 'neutral'
): {
  color: TagColor
  label: string
} => {
  switch (value) {
    case 'negative':
      return { color: 'red', label: i18n.t('label.negative', { ns: 'common' }) }
    case 'positive':
      return {
        color: 'green',
        label: i18n.t('label.positive', { ns: 'common' })
      }
    case 'neutral':
      return {
        color: 'gray',
        label: i18n.t('label.neutral', { ns: 'common' })
      }
  }
}
