import { getRouteApi } from '@tanstack/react-router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@/assets/icons/add.svg?react'
import DeleteIcon from '@/assets/icons/delete.svg?react'
import EditIcon from '@/assets/icons/edit.svg?react'
import Button from '@/components/Button/Button'
import LinkText from '@/components/LinkText/LinkText'
import { type SelectOption } from '@/components/Select/Select'
import Table, { type Column, type TableAction } from '@/components/Table/Table'
import { TABLE_CELL_WIDTH } from '@/components/Table/table-cell-width'
import Filters, { type Filter } from '@/components/common/Filters/Filters'
import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'
import { useScreenResolution } from '@/hooks/useScreenResolution'
import { toast } from '@/hooks/useToast'
import useAuthStore from '@/store/useAuthStore'
import { getColumnWidth } from '@/utils/get-column-width'
import { getFilterValue } from '@/utils/get-filter-value'
import { getValue } from '@/utils/get-value'

import styles from './ClassesView.module.scss'
import RemoveClassModal from '../components/RemoveClassModal'
import type { ClassesFiltersKey } from '../constants/classes-filters'
import { classesQueryOptions } from '../queries/classListFilters/classesQueryOptions'
import { studentsQueryOptions } from '../queries/classListFilters/studentsQueryOptions'
import { tutorsQueryOptions } from '../queries/classListFilters/tutorsQueryOptions'
import useAcademicLevelsOptions from '../queries/classListFilters/useAcademicLevelsOptions'
import useStudentClasses, { type Class } from '../queries/useStudentClasses'
import useStudentClassesCount from '../queries/useStudentClassesCount'

const routeApi = getRouteApi('/_auth/students-and-classes/classes/')

const ClassesView = () => {
  const { t } = useTranslation(['classes'])
  const { page, pageSize, id, academicLevelId, tutorId, studentId } =
    routeApi.useSearch()
  const navigate = routeApi.useNavigate()
  const { user } = useAuthStore()

  const { isMobile, isExtraLarge } = useScreenResolution()

  const [openDeleteClassConfirmModal, setOpenDeleteClassConfirmModal] =
    useState(false)
  const [selectedClassId, setSelectedClassId] = useState<string | null>(null)

  const {
    data: classes,
    isLoading: isClassesLoading,
    refetch: refetchClassess
  } = useStudentClasses({
    id: id?.map(getValue),
    academicLevelId,
    tutorId: tutorId?.map(getValue),
    studentId: studentId?.map(getValue),
    page,
    pageSize
  })

  const { data: classesCount, isLoading: isClassesCountLoading } =
    useStudentClassesCount()

  const tableColumns: Column<Class>[] = [
    {
      key: 'name',
      width: getColumnWidth(isExtraLarge, TABLE_CELL_WIDTH.SM),
      title: t('header.class'),
      cellDataTestId: 'class',
      render: (value: Class) => (
        <LinkText
          dataTestId="class-link"
          to="/students-and-classes/classes/$classId/details"
          params={{
            classId: value.id
          }}
        >
          {value.name}
        </LinkText>
      )
    },
    {
      dataIndex: ['academicLevel', 'name'],
      key: 'academicLevel',
      cellDataTestId: 'academic-level',
      title: t('header.academic-level'),
      width: isMobile ? TABLE_CELL_WIDTH.LG : undefined
    },

    {
      key: 'tutor',
      cellDataTestId: 'tutor',
      title: t('header.tutor'),
      width: isMobile ? TABLE_CELL_WIDTH.XL : undefined,
      render: (value: Class) =>
        value.tutor ? (
          <LinkText
            to="/users/$userId/details"
            params={{
              userId: value.tutor.id
            }}
          >
            {value.tutor.fullName}
          </LinkText>
        ) : (
          <span className={styles.textUnassigned}>{t('text.unassigned')}</span>
        )
    },
    {
      dataIndex: 'numberOfStudents',
      key: 'numberOfStudents',
      cellDataTestId: 'number-of-students',
      title: t('header.number-of-students'),
      width: isMobile ? TABLE_CELL_WIDTH.LG : undefined
    }
  ]

  const handleChangePage = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: value
      })
    })
  }

  const handleChangePageSize = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        pageSize: value
      })
    })
  }

  const { data: academicLevels, isLoading: isAcademicLevelsLoading } =
    useAcademicLevelsOptions()

  const changeFilter = (
    key: ClassesFiltersKey,
    value?: string | string[] | SelectOption<string>[]
  ) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: 1,
        [key]: getFilterValue(value)
      })
    })
  }

  const handleClearAll = () => {
    navigate({
      search: { page: 1, pageSize: DEFAULT_PAGE_SIZE }
    })
  }

  const filters: Filter[] = [
    {
      label: t('label.class'),
      variant: 'async-multiselect',
      filterProps: {
        id: 'classId',
        dataTestId: 'class-filter',
        multiple: true,
        queryOptions: classesQueryOptions,
        value: id,
        placeholder: t('label.class'),
        onChange: value => changeFilter('id', value)
      }
    },
    {
      label: t('label.academic-level'),
      variant: 'multiselect',
      filterProps: {
        id: 'academic-level',
        dataTestId: 'academic-level-filter',
        multiple: true,
        loading: isAcademicLevelsLoading,
        options: academicLevels || [],
        value: academicLevelId,
        placeholder: t('label.academic-level'),
        onChange: value => changeFilter('academicLevelId', value)
      }
    },
    {
      label: t('label.tutor'),
      variant: 'async-multiselect',
      filterProps: {
        id: 'tutorId',
        dataTestId: 'tutor-filter',
        multiple: true,
        queryOptions: tutorsQueryOptions,
        value: tutorId,
        placeholder: t('label.tutor'),
        onChange: value => changeFilter('tutorId', value)
      }
    },
    {
      label: t('label.student'),
      variant: 'async-multiselect',
      filterProps: {
        id: 'student',
        dataTestId: 'student-filter',
        multiple: true,
        queryOptions: studentsQueryOptions,
        value: studentId,
        placeholder: t('label.student'),
        onChange: value => changeFilter('studentId', value)
      }
    }
  ]
  const actions: TableAction<Class>[] = [
    ...(user?.isSuperAdmin
      ? [
          {
            text: t('button.edit'),
            icon: <EditIcon />,
            onClick: (item: Class) => {
              navigate({
                to: 'edit/$classId',
                params: {
                  classId: item.id
                }
              })
            }
          },
          {
            text: t('button.remove-class'),
            variantAction: 'danger' as const,
            icon: <DeleteIcon />,
            onClick: (item: Class) => {
              setOpenDeleteClassConfirmModal(true)
              setSelectedClassId(item.id)
            }
          }
        ]
      : [])
  ]

  const handleRemoveSuccess = () => {
    refetchClassess()
    toast({
      variant: 'success',
      title: t('toast.class-remove-success', {
        CLASS: classes?.list.find(c => c.id === selectedClassId)?.name
      })
    })
    setSelectedClassId(null)
  }

  const handleRemoveError = () => {
    setSelectedClassId(null)
    toast({
      variant: 'error',
      title: t('toast.class-remove-error', {
        CLASS: classes?.list.find(c => c.id === selectedClassId)?.name
      })
    })
  }

  const isSomeFilterSelected = filters.some(filter => filter.filterProps.value)

  return (
    <>
      <Filters
        filters={filters}
        onClearAll={handleClearAll}
        disabledClearAllButton={!isSomeFilterSelected}
        actionsRender={
          user?.isSuperAdmin ? (
            <Button
              asLink
              to="/students-and-classes/classes/add"
              icon={<AddIcon />}
            >
              {t('button.add-new-class')}
            </Button>
          ) : null
        }
      />
      <Table
        isLoading={isClassesLoading || isClassesCountLoading}
        id="classes-table"
        data={classes?.list || []}
        columns={tableColumns}
        count={classes?.count}
        totalCount={classesCount}
        pagination={{
          pageSize,
          page
        }}
        actions={actions}
        onChangePageSize={handleChangePageSize}
        onChangePage={handleChangePage}
      />
      {selectedClassId ? (
        <RemoveClassModal
          open={openDeleteClassConfirmModal}
          onOpenChange={setOpenDeleteClassConfirmModal}
          classId={selectedClassId}
          onRemoveSuccess={handleRemoveSuccess}
          onRemoveError={handleRemoveError}
        />
      ) : null}
    </>
  )
}

export default ClassesView
