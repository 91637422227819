import {
  endOfDay,
  endOfISOWeek,
  format,
  isBefore,
  isWithinInterval,
  startOfDay,
  startOfISOWeek
} from 'date-fns'

import { API_DATE_FORMAT } from '@/constants/date-format'
import { type Semester } from '@/types/semester'

export const getDefaultDateRangeInTimetable = (currentSemester: Semester) => {
  const today = new Date()

  const isTodayWithinCurrentSemester = currentSemester
    ? isWithinInterval(startOfDay(today), {
        start: startOfDay(currentSemester.startDate),
        end: endOfDay(currentSemester.endDate)
      })
    : false

  if (isTodayWithinCurrentSemester) {
    const start = format(startOfISOWeek(today), API_DATE_FORMAT)
    const end = format(endOfISOWeek(today), API_DATE_FORMAT)

    return { start, end }
  }

  const isTodayBeforeCurrentSemester = isBefore(
    today,
    currentSemester.startDate
  )

  const date = isTodayBeforeCurrentSemester
    ? currentSemester.startDate
    : currentSemester.endDate

  const start = format(startOfISOWeek(date), API_DATE_FORMAT)
  const end = format(endOfISOWeek(date), API_DATE_FORMAT)

  return {
    start,
    end
  }
}
