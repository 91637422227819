import { httpService } from '@/api/http.service'
import { type InfiniteQueryOptions } from '@/types/infinite-query'
import { type LabelAndValue } from '@/types/label-and-value'
import { nextPageParamGetter } from '@/utils/next-page-param-getter'
import { parsePaginatedOptions } from '@/utils/parse-paginated'

type Params = {
  semesterId?: string
  search?: string
}

export const coursesQueryOptions = (
  params: Params
): InfiniteQueryOptions<LabelAndValue> => ({
  queryKey: ['panelLessonsOptionsCoursesList', params],
  staleTime: 60 * 1000,
  queryFn: ({ pageParam }) =>
    httpService.panel
      .panelLessonsOptionsCoursesList({
        search: params.search,
        page: pageParam,
        semester_id: params.semesterId
      })
      .then(parsePaginatedOptions),

  getNextPageParam: nextPageParamGetter,
  initialPageParam: 1
})

export const groupsQueryOptions = (
  params: Params
): InfiniteQueryOptions<LabelAndValue> => ({
  queryKey: ['panelLessonsOptionsGroupsList', params],
  staleTime: 60 * 1000,
  queryFn: ({ pageParam }) =>
    httpService.panel
      .panelLessonsOptionsGroupsList({
        search: params.search,
        page: pageParam,
        semester_id: params.semesterId
      })
      .then(parsePaginatedOptions),
  getNextPageParam: nextPageParamGetter,
  initialPageParam: 1
})

export const teachersQueryOptions = (
  params: Params
): InfiniteQueryOptions<LabelAndValue> => ({
  queryKey: ['panelLessonsOptionsTeachersList', params],
  staleTime: 60 * 1000,
  queryFn: ({ pageParam }) =>
    httpService.panel
      .panelLessonsOptionsTeachersList({
        search: params.search,
        page: pageParam,
        semester_id: params.semesterId
      })
      .then(parsePaginatedOptions),
  getNextPageParam: nextPageParamGetter,
  initialPageParam: 1
})

export const classesQueryOptions = (
  params: Params
): InfiniteQueryOptions<LabelAndValue> => ({
  queryKey: ['panelLessonsOptionsClassesList', params],
  staleTime: 60 * 1000,
  queryFn: ({ pageParam }) =>
    httpService.panel
      .panelLessonsOptionsClassesList({
        search: params.search,
        page: pageParam,
        semester_id: params.semesterId
      })
      .then(parsePaginatedOptions),
  getNextPageParam: nextPageParamGetter,
  initialPageParam: 1
})
