import * as ToastPrimitives from '@radix-ui/react-toast'
import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'

import CheckCircleIcon from '@/assets/icons/check-circle.svg?react'
import CloseIcon from '@/assets/icons/close.svg?react'
import ErrorCircleIcon from '@/assets/icons/error-circle.svg?react'
import InfoCircle from '@/assets/icons/info-circle.svg?react'

import styles from './Toast.module.scss'
import ButtonIcon from '../ButtonIcon/ButtonIcon'

type TastVariant = 'info' | 'warning' | 'error' | 'success'

export type ToastProps = {
  id: string
  title?: React.ReactNode
  duration?: number
  variant: TastVariant
  type?: 'foreground' | 'background'
  description?: React.ReactNode
  action?: {
    render: React.ReactNode
    altText: string
  }
  withClose?: boolean
  onCloseButtonClick?: () => void
}

// Workaround to fix accessibility problems
// https://github.com/radix-ui/primitives/issues/1750
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AccessibleRoot = React.forwardRef<any, any>((props, forwardedRef) => (
  <div {...props} ref={forwardedRef}>
    {props.children}
  </div>
))

AccessibleRoot.displayName = 'AccessibleRoot'

const ToastIcon = ({ variant }: { variant: TastVariant }) => {
  switch (variant) {
    case 'info':
    case 'warning':
      return <InfoCircle className={styles.icon} />
    case 'error':
      return <ErrorCircleIcon className={styles.icon} />
    case 'success':
      return <CheckCircleIcon className={styles.icon} />
  }
}

const Toast = (props: ToastProps) => {
  const { t } = useTranslation('common')
  return (
    <ToastPrimitives.Root
      asChild
      key={props.id}
      type={props.type}
      className={clsx(styles.toastRoot, styles[props.variant])}
      duration={props.duration}
    >
      <AccessibleRoot>
        <ToastIcon variant={props.variant} />
        <ToastPrimitives.Title className={styles.titleWrapper}>
          <span className={styles.title}>{props.title}</span>
          <span className={styles.description}>{props.description}</span>
        </ToastPrimitives.Title>

        <div className={styles.actions}>
          {props.action ? (
            <ToastPrimitives.Action
              className={styles.action}
              asChild
              altText={props.action.altText}
            >
              {props.action.render}
            </ToastPrimitives.Action>
          ) : null}

          {props.withClose ? (
            <ToastPrimitives.Close asChild>
              <ButtonIcon
                size="small"
                variant="tertiary"
                ariaLabel={t('button.close')}
                onClick={props.onCloseButtonClick}
              >
                <CloseIcon />
              </ButtonIcon>
            </ToastPrimitives.Close>
          ) : null}
        </div>
      </AccessibleRoot>
    </ToastPrimitives.Root>
  )
}

export default Toast
