import { httpService } from '@/api/http.service'
import { type InfiniteQueryOptions } from '@/types/infinite-query'
import { type LabelAndValue } from '@/types/label-and-value'
import { nextPageParamGetter } from '@/utils/next-page-param-getter'
import { parsePaginatedOptions } from '@/utils/parse-paginated'

export const tutorsQueryOptions = (
  search?: string
): InfiniteQueryOptions<LabelAndValue> => ({
  queryKey: ['panelClassesOptionsTutorsList', search],
  staleTime: 60 * 100,
  queryFn: ({ pageParam }) =>
    httpService.panel
      .panelClassesOptionsTutorsList({
        search,
        page: pageParam
      })
      .then(parsePaginatedOptions),

  getNextPageParam: nextPageParamGetter,
  initialPageParam: 1
})
