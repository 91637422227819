import { z } from 'zod'

import { labelAndValue } from '@/utils/zod'

export const formSchemaCoverTeacher = () =>
  z
    .object({
      teacher: labelAndValue().optional().nullable(),
      coTeacher: labelAndValue().optional().nullable(),
      comment: z.string()
    })
    .refine(data => data.teacher || data.coTeacher, {
      path: ['teacher'],
      message: ' '
    })
    .refine(data => data.teacher || data.coTeacher, {
      path: ['coTeacher'],
      message: ' '
    })

export type CoverTeacherFormSchema = z.infer<
  ReturnType<typeof formSchemaCoverTeacher>
>
