import type {
  SlotLabelMountArg,
  SlotLabelContentArg,
  DayHeaderContentArg,
  EventHoveringArg,
  EventInput,
  DateInput,
  DayCellContentArg
} from '@fullcalendar/core'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import clsx from 'clsx'
import { addWeeks, format, isWeekend, isSameWeek } from 'date-fns'
import React, {
  useCallback,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'

import Label from '@/components/Label'
import { useScreenResolution } from '@/hooks/useScreenResolution'
import { formatDate, formatShortWeekdayName } from '@/utils/format-date'

import styles from './CalendarBox.module.scss'
import CalendarMobileHeader from './CalendarMobileHeader'
import Button from '../Button/Button'
import Loading from '../Loading'
import SwitchLeftRight from '../SwitchLeftRight/SwichLeftRight'
import WeekPicker from '../WeekPicker/WeekPicker'

type Event = {
  start: Date
  end: Date
  dataTestId?: string
  component: React.ReactNode
}

const Event = (eventInfo: EventInput) => (
  <div
    className={styles.event}
    data-test-id={eventInfo.event._def.extendedProps.dataTestId}
  >
    {eventInfo.event._def.extendedProps.component}
    <VisuallyHidden.Root>
      <p>{`${formatDate(eventInfo.event.start)} ${eventInfo.timeText}`}</p>
    </VisuallyHidden.Root>
  </div>
)

type CalendarBoxProps = {
  events: Event[]
  height?: string
  minTime?: string
  maxTime?: string
  interval?: string
  loading?: boolean
  initialDate?: string
  disabledLeftArrow?: boolean
  disabledRightArrow?: boolean
  disabledWeekPickerDate?: (date: Date) => boolean
  onDateChange?: (date: Date) => void
  nextToWeekSwitcher?: React.ReactNode
  belowWeekSwitcher?: React.ReactNode
}

export type CalendarBoxRef = {
  handleOnDayChange: (date: Date) => void
}

const CalendarBox = React.forwardRef<CalendarBoxRef, CalendarBoxProps>(
  (props: CalendarBoxProps, ref: React.ForwardedRef<CalendarBoxRef>) => {
    const {
      onDateChange,
      height = '80vh',
      minTime = '07:00:00',
      maxTime = '22:00:00',
      interval = '0:30'
    } = props

    const { t } = useTranslation(['common'])

    const { isMobile } = useScreenResolution()

    const calendarRef = useRef<FullCalendar>(null)
    const [currentEventIndex, setCurrentEventIndex] = useState<string>()
    const [initialView, setInitialView] = useState<string>(
      isMobile ? 'timeGridDay' : 'timeGridWeek'
    )

    const [day, setDay] = useState(
      props.initialDate
        ? isMobile && isSameWeek(new Date(), props.initialDate)
          ? new Date()
          : new Date(props.initialDate)
        : new Date()
    )

    const gotoDate = (date: DateInput) => {
      calendarRef.current?.getApi().gotoDate(date)
    }

    const handleSlotLabelMount = (arg: SlotLabelMountArg) => {
      arg.el.style.height = '100px'
      arg.el.style.width = '60px'
    }

    const onEventMouseEnter = (arg: EventHoveringArg) => {
      const index = arg.el.parentElement?.style.zIndex
      setCurrentEventIndex(index)
      const parent = arg.el.parentElement
      if (!parent) return
      parent.style.zIndex = '100'
    }

    const onEventMouseLeave = (arg: EventHoveringArg) => {
      const parent = arg.el.parentElement
      if (!parent) return
      parent.style.zIndex = currentEventIndex || '0'
    }

    const dayHeaderContent = (arg: SlotLabelContentArg) => {
      const isWeekendDay = isWeekend(arg.date)

      const classNameHeaderDate = clsx(
        isWeekendDay && 'headerDateWeekend',
        'timetable-heading-date-default'
      )

      return {
        html: `
        <div class="headerWrapper">
          <span class="${classNameHeaderDate}">${format(arg.date, 'dd.MM')}</span> 
          <span class="timetable-heading-day">${formatShortWeekdayName(arg.date)}</span>
        </div>`
      }
    }
    const slotLabelContent = (arg: SlotLabelContentArg) =>
      arg.date.getHours() +
      ':' +
      arg.date.getMinutes().toString().padStart(2, '0')

    const dayHeaderClass = (arg: DayHeaderContentArg) =>
      clsx(
        styles.dayHeader,
        arg.isToday && styles.dayHeaderToday,
        isWeekend(arg.date) && styles.dayHeaderWeekend
      )

    const dayCellClass = (arg: DayCellContentArg) =>
      clsx(styles.dayCell, arg.isToday && styles.dayCellToday)

    const handleWindowResize = () => {
      const currentInitialView = isMobile ? 'timeGridDay' : 'timeGridWeek'

      if (currentInitialView !== initialView) {
        calendarRef.current?.getApi().changeView(currentInitialView)
        setInitialView(currentInitialView)
      }
    }

    const handleOnDayChange = useCallback(
      (date?: Date) => {
        if (date) {
          setDay(date)
          gotoDate(date)
          onDateChange?.(date)
        }
      },
      [onDateChange]
    )

    const handleChangeWeek = (type: 'next' | 'previous') => {
      const nextWeekStart = addWeeks(day, type === 'next' ? 1 : -1)
      handleOnDayChange(nextWeekStart)
    }

    useImperativeHandle(
      ref,
      () => ({
        handleOnDayChange
      }),
      [handleOnDayChange]
    )

    return (
      <Loading spinning={props.loading}>
        <div className={styles.calendarBox} data-test-id="calendar-view">
          <div className={styles.header}>
            <SwitchLeftRight
              className={styles.switchLeftRight}
              left={{
                ariaLabel: t('button.previous-week'),
                onClick: () => handleChangeWeek('previous'),
                disabled: props.disabledLeftArrow
              }}
              right={{
                ariaLabel: t('button.next-week'),
                onClick: () => handleChangeWeek('next'),
                disabled: props.disabledRightArrow
              }}
              additionalContent={
                <Button
                  variant="secondary"
                  onClick={() => handleOnDayChange(new Date())}
                >
                  {t('button.today')}
                </Button>
              }
            >
              <Label id="week-calendar-box" label={t('label.set-week')} hidden>
                <WeekPicker
                  id="week-calendar-box"
                  value={day}
                  onChange={handleOnDayChange}
                  disabledDate={props.disabledWeekPickerDate}
                  className={styles.weekpicker}
                />
              </Label>
            </SwitchLeftRight>
            {props.nextToWeekSwitcher}
          </div>

          {props.belowWeekSwitcher}

          {isMobile ? (
            <CalendarMobileHeader onDateChange={handleOnDayChange} date={day} />
          ) : null}

          <FullCalendar
            ref={calendarRef}
            plugins={[timeGridPlugin]}
            initialView={initialView}
            height={height}
            firstDay={1}
            nowIndicator
            initialDate={day}
            allDaySlot={false}
            headerToolbar={false}
            slotEventOverlap={false}
            events={props.events}
            eventContent={Event}
            slotMinTime={minTime}
            slotMaxTime={maxTime}
            slotLabelInterval={interval}
            slotLabelFormat={{
              hour: 'numeric',
              minute: '2-digit',
              meridiem: 'short'
            }}
            windowResize={handleWindowResize}
            eventMouseEnter={onEventMouseEnter}
            eventMouseLeave={onEventMouseLeave}
            slotLabelContent={slotLabelContent}
            dayHeaderContent={dayHeaderContent}
            slotLabelDidMount={handleSlotLabelMount}
            eventClassNames={styles.event}
            slotLabelClassNames={styles.slotLabel}
            dayHeaderClassNames={dayHeaderClass}
            dayCellClassNames={dayCellClass}
            eventMinHeight={146}
          />
        </div>
      </Loading>
    )
  }
)

CalendarBox.displayName = 'CalendarBox'

export default CalendarBox
