import { useQuery } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'

const useStudentCoursesCount = ({
  semesterId,
  studentId
}: {
  semesterId: string
  studentId: string
}) =>
  useQuery({
    queryKey: ['panelCoursesSummaryListCount', semesterId, studentId],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelCoursesSummaryList(
        semesterId,
        studentId,

        {
          fetchKeys: {
            id: true
          }
        }
      )

      return response.length
    }
  })

export default useStudentCoursesCount
