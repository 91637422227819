import { createFileRoute, Outlet } from '@tanstack/react-router'

import { ClassView, classQueryOptions } from '@/modules/classes'

export const Route = createFileRoute(
  '/_auth/students-and-classes/classes/$classId'
)({
  component: () => (
    <ClassView>
      <Outlet />
    </ClassView>
  ),
  loader: async ({ context, params, navigate }) => {
    const classDetails = await context.queryClient.ensureQueryData(
      classQueryOptions(params.classId)
    )

    if (
      location.pathname === `/students-and-classes/classes/${params.classId}`
    ) {
      navigate({
        to: '/students-and-classes/classes/$classId/details',
        params,
        replace: true
      })
    }
    return classDetails.displayName
  },
  meta: ({ loaderData }) => [{ title: loaderData }]
})
