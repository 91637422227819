import { useMediaQuery } from 'usehooks-ts'

const useScreenResolution = () => {
  const isMobile = useMediaQuery('(max-width: 768px)')
  const isTablet = useMediaQuery('(max-width: 1170px)')

  const isExtraLarge = useMediaQuery('(min-width: 1920px)')

  const isDesktop = !isMobile && !isTablet

  return { isMobile, isTablet, isDesktop, isExtraLarge }
}

export { useScreenResolution }
