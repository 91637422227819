import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { type UserStatus } from '@/types/user-status'
import { requiredString, userStatus } from '@/utils/zod'

import type { IdAndName } from '../types/id-and-name'

const responseSchema = z.array(
  z.object({
    id: requiredString(),
    full_name: requiredString(),
    email: z.string(),
    academic_level: z
      .object({
        id: requiredString(),
        name: requiredString()
      })
      .nullable(),
    student_class: z
      .object({
        id: requiredString(),
        display_name: requiredString()
      })
      .nullable()
      .optional(),
    tutor: z
      .object({
        id: requiredString(),
        full_name: requiredString(),
        first_name: requiredString(),
        last_name: requiredString()
      })
      .nullable()
      .optional(),
    parents: z
      .array(
        z.object({
          id: requiredString(),
          full_name: requiredString(),
          first_name: requiredString(),
          last_name: requiredString()
        })
      )
      .optional(),
    status: userStatus.optional()
  })
)

export type Student = {
  id: string
  fullName: string
  email: string
  status?: UserStatus
  academicLevel?: IdAndName
  studentClass?: IdAndName
  tutor?: {
    id: string
    fullName: string
    firstName: string
    lastName: string
  }
  parents?: {
    id: string
    fullName: string
    firstName: string
    lastName: string
  }[]
}

const parseResponse = (response: z.infer<typeof responseSchema>): Student[] =>
  response.map(item => ({
    id: item.id,
    fullName: item.full_name,
    email: item.email,
    status: item.status,
    academicLevel: item.academic_level || undefined,
    studentClass: item.student_class
      ? {
          id: item.student_class.id,
          name: item.student_class.display_name
        }
      : undefined,
    tutor: item.tutor
      ? {
          id: item.tutor.id,
          fullName: item.tutor.full_name,
          firstName: item.tutor.first_name,
          lastName: item.tutor.last_name
        }
      : undefined,
    parents: item.parents
      ? item.parents.map(parent => ({
          id: parent.id,
          fullName: parent.full_name,
          firstName: parent.first_name,
          lastName: parent.last_name
        }))
      : []
  }))

type Params = {
  search?: string
  academicLevelId?: string[]
  classId?: string[]
  tutor?: string[]
  parent?: string[]
  status?: UserStatus[]
  groupId?: string
  pageSize?: number
  page?: number
}

const useStudents = (params: Params = {}) =>
  useQuery({
    queryKey: ['panelStudentsList', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelStudentsList({
        search: params.search,
        academic_level_id: params.academicLevelId,
        class_id: params.classId,
        tutor_id: params.tutor,
        parent_id: params.parent,
        status: params.status,
        page_size: params.pageSize,
        group_id: params.groupId,
        page: params.page,
        fetchKeys: {
          id: true,
          full_name: true,
          email: true,
          academic_level: true,
          student_class: true,
          tutor: true,
          parents: true,
          status: true
        }
      })
      const parsed = parseResponse(responseSchema.parse(response.results))
      return {
        list: parsed,
        count: response.count
      }
    },
    placeholderData: previous => previous
  })

export default useStudents
