import { zodResolver } from '@hookform/resolvers/zod'
import clsx from 'clsx'
import { endOfDay, isWithinInterval, startOfDay } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { type OptionContext } from 'react-select'
import { type z } from 'zod'

import DatePicker from '@/components/DatePicker/DatePicker'
import FormField from '@/components/FormField'
import ModalForm from '@/components/Modal/ModalForm'
import RadioGroup from '@/components/Radio/RadioGroup'
import Select, { type SelectOption } from '@/components/Select/Select'
import Tag from '@/components/Tag/Tag'
import Textarea from '@/components/Textarea/Textarea'
import DirtyModal from '@/components/common/DirtyModal'
import { formSchemaComment } from '@/constants/comment-payload'
import { toast } from '@/hooks/useToast'
import useAddComment from '@/mutations/useAddComment'
import useEditComment from '@/mutations/useEditComment'
import useComment from '@/queries/useComment'
import useGroups, { type Group } from '@/queries/useGroups'
import useSemesters from '@/queries/useSemesters'
import useStudentOptions from '@/queries/useStudentOptions'
import { getCommentTypeTag } from '@/utils/get-comment-type-tag'

import styles from './CommentModal.module.scss'
import { RemoveCommentModal } from '../RemoveCommentModal'

type CommentModalProps = {
  semester: string
  groupId?: string
  open: boolean
  classId?: string
  studentId?: string
  commentId?: string
  trigger: React.ReactNode
  onOpenChange: (open: boolean) => void
  onUpdate: () => void
}

type FormSchema = z.infer<ReturnType<typeof formSchemaComment>>

const CommentModal = (props: CommentModalProps) => {
  const { t } = useTranslation('common')
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)

  const { data: comment, isLoading: isCommentLoading } = useComment({
    id: props.commentId,
    enabled: !!props.commentId && props.open
  })

  const { data: semesters } = useSemesters()

  const currentSemester = semesters?.getSemester(props.semester)

  const [isOpenRemoveCommentModal, setIsOpenRemoveCommentModal] =
    useState(false)

  const { data: studentOptions, isLoading: isStudentsLoading } =
    useStudentOptions({
      groupId: props.groupId,
      classId: props.classId ? [props.classId] : undefined,
      semester: props.semester
    })

  const handleClose = () => {
    props.onOpenChange(false)
    if (!comment) form.reset()
  }

  const { mutate: addComment, isPending: isAddCommentLoading } = useAddComment({
    onSuccess: () => {
      handleClose()
      toast({
        variant: 'success',
        title: t('toast.successfully-saved-comment')
      })
      props.onUpdate()
    },
    onError: () => {
      handleClose()
      toast({
        variant: 'error',
        title: t('toast.failed-saved-comment')
      })
    }
  })

  const { mutate: editComment, isPending: isEditCommentLoading } =
    useEditComment({
      onSuccess: () => {
        handleClose()
        toast({
          variant: 'success',
          title: t('toast.successfully-saved-comment')
        })
        props.onUpdate()
      },
      onError: () => {
        handleClose()
        toast({
          variant: 'error',
          title: t('toast.failed-saved-comment')
        })
      }
    })

  const isDateInsideCurrentSemester = useCallback(
    (date: Date) =>
      currentSemester
        ? isWithinInterval(startOfDay(date), {
            start: startOfDay(currentSemester.startDate),
            end: endOfDay(currentSemester.endDate)
          })
        : false,
    [currentSemester]
  )

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchemaComment(isDateInsideCurrentSemester)),
    mode: 'all',
    defaultValues: {
      semester: props.semester,
      course: props.groupId ? props.groupId : 'none',
      comment: '',
      type: undefined,
      date: isDateInsideCurrentSemester(new Date()) ? new Date() : undefined,
      studentIds: props.studentId ? [props.studentId] : []
    }
  })

  const shouldDateBeDisabled = (date: Date) =>
    !isDateInsideCurrentSemester(date)

  useEffect(() => {
    if (!!comment) {
      form.reset({
        semester: comment.semesterId,
        course: comment.group?.course.id || 'none',
        comment: comment.content,
        type: comment.commentType,
        date: new Date(comment.addedOn),
        studentIds: [comment.student.id]
      })
    } else {
      form.reset({
        semester: props.semester,
        course: props.groupId ? props.groupId : 'none',
        comment: '',
        type: undefined,
        date: isDateInsideCurrentSemester(new Date()) ? new Date() : undefined,
        studentIds: props.studentId ? [props.studentId] : []
      })
    }
  }, [
    comment,
    form,
    props.studentId,
    props.groupId,
    props.semester,
    isDateInsideCurrentSemester
  ])

  const { isDirty } = form.formState

  const { data: groups, isLoading: isGroupsLoading } = useGroups({
    semester: props.semester,
    classIds: props.classId ? [props.classId] : undefined,
    studentIds: props.studentId ? [props.studentId] : undefined
  })

  const coursesOption = groups
    ? [{ label: t('text.other'), value: 'none' }, ...groups.options]
    : []

  const radioOptions = [
    {
      label: <Tag {...getCommentTypeTag('neutral')} />,
      value: 'neutral'
    },
    { label: <Tag {...getCommentTypeTag('positive')} />, value: 'positive' },
    { label: <Tag {...getCommentTypeTag('negative')} />, value: 'negative' }
  ]

  const selectedCourse = form.watch('course')

  const handleOnCancel = () => {
    isDirty ? setIsOpenConfirmModal(true) : handleClose()
  }

  const handleRemove = () => {
    setIsOpenRemoveCommentModal(true)
  }

  const handleSubmit = () => {
    if (!props.commentId) addComment({ ...form.getValues() })
    else editComment({ id: props.commentId, data: form.getValues() })
  }

  return (
    <>
      <ModalForm
        id="add-comment"
        description={comment?.student.name}
        form={form}
        open={props.open}
        title={
          props.commentId ? t('header.edit-comment') : t('header.add-comment')
        }
        onOpenChange={props.onOpenChange}
        submitText={t('button.save')}
        loading={
          isAddCommentLoading || isEditCommentLoading || isCommentLoading
        }
        withRemoveButton={!!props.commentId}
        trigger={props.trigger}
        onSubmit={handleSubmit}
        onRemove={handleRemove}
        onCancel={handleOnCancel}
        onClose={handleOnCancel}
      >
        {!props.commentId && !props.groupId ? (
          <FormField
            control={form.control}
            id="course"
            required
            label={t('label.source')}
            name="course"
            render={({ inputProps }) => (
              <Select
                {...inputProps}
                placeholder={t('placeholder.select-course')}
                options={coursesOption}
                loading={isGroupsLoading}
                formatOption={(option, context) => (
                  <CourseOption
                    option={option}
                    courses={groups?.list || []}
                    selectedCourse={selectedCourse}
                    optionContext={context}
                  />
                )}
              />
            )}
          />
        ) : null}

        {!props.commentId && !props.studentId ? (
          <FormField
            control={form.control}
            id="studentIds"
            label={t('label.student(s)')}
            name="studentIds"
            required
            render={({ inputProps }) => (
              <Select
                {...inputProps}
                placeholder={t('placeholder.select-students')}
                multiple
                options={studentOptions || []}
                loading={isStudentsLoading}
              />
            )}
          />
        ) : null}

        <FormField
          control={form.control}
          id="comment"
          label={t('label.comment')}
          name="comment"
          required
          render={({ inputProps }) => (
            <Textarea
              placeholder={t('placeholder.type-comment')}
              numberOfLines={3}
              {...inputProps}
            />
          )}
        />

        <FormField
          control={form.control}
          id="date"
          label={t('label.date')}
          name="date"
          required
          render={({ inputProps }) => (
            <DatePicker
              defaultMonth={currentSemester?.startDate}
              disabledDate={shouldDateBeDisabled}
              defaultValue={inputProps.value}
              className={styles.date}
              {...inputProps}
            />
          )}
        />

        <FormField
          control={form.control}
          id="type"
          label={t('label.type')}
          required
          name="type"
          className={styles.type}
          render={({ inputProps }) => (
            <RadioGroup {...inputProps} options={radioOptions} />
          )}
        />
      </ModalForm>

      {comment ? (
        <RemoveCommentModal
          open={isOpenRemoveCommentModal}
          onOpenChange={setIsOpenRemoveCommentModal}
          commenId={comment.id}
          onSuccess={() => {
            props.onUpdate()
            handleClose()
          }}
        />
      ) : null}

      <DirtyModal
        isOpen={isOpenConfirmModal}
        onOpenChange={setIsOpenConfirmModal}
        onConfirm={() => {
          handleClose()
          setIsOpenConfirmModal(false)
        }}
      />
    </>
  )
}

type CourseOptionProps = {
  option: SelectOption<string>
  courses: Group[]
  selectedCourse?: string
  optionContext: OptionContext
}

const CourseOption = (props: CourseOptionProps) => {
  const courseOption = props.courses?.find(
    item => item.id === props.option.value
  )

  return (
    <div
      className={clsx(
        styles.classOption,
        props.selectedCourse === props.option.value &&
          props.optionContext === 'menu' &&
          styles.classOptionSelected
      )}
    >
      <span>{courseOption?.course.name || props.option.label}</span>

      {courseOption && props.optionContext === 'menu' ? (
        <span className={styles.groupName}>{courseOption?.name}</span>
      ) : null}
    </div>
  )
}

export default CommentModal
