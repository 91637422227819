import { useQuery } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'
import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'

type Params = {
  enabled: boolean
  page: number
  ids: string[]
}
const useBulkUnableArchiveUsers = (params: Params) =>
  useQuery({
    queryKey: ['panelUsersListUnableToArchive', params],
    staleTime: 60 * 100,
    enabled: params.enabled,
    queryFn: async () => {
      const userList = await httpService.panel.panelUsersList({
        page: params.page,
        page_size: DEFAULT_PAGE_SIZE,
        id: params.ids,
        fetchKeys: {
          id: true,
          name: true
        }
      })

      return userList.results
    }
  })

export default useBulkUnableArchiveUsers
